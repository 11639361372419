import { Component, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { AttributToTextService, SharedMapService, LegendService, LegendsConfig, HandleService, EventEmitterService, EventActionModalLegend, FarmerPrevisService } from './../../../_services/index';
import { Farm, LegendGroup, LegendItem, LegendColor, Legend, LegendExtProperty } from '../../../_models/index'
import { isNullOrUndefined } from '../../../_helpers';
import { DuplicatePreconisationComponent } from '../farmerprevis/duplicatepreconisation.component';
import { UpdateFarmerPrevisComponent } from '../farmerprevis/updatefarmerprevis.component';

@Component({
  selector: 'mapside',
  templateUrl: './mapside.component.html',
  styleUrls: ['../../../modules/jarvis-ui/assets/font/style.css', './mapside.component.scss', './mapside.component.responsive.scss']
})
export class MapsideComponent implements OnChanges {

  //css spécifique à attribuer au mapside
  public MapSideCss:string="";
  public showPopupGroup = false;
  // Tableau imbriqué des groupes et attribut de la légende

  @Input()
  set LegendAttrs(legendAttrs: LegendGroup[]) {
    this._legendAttrs = legendAttrs;
  }

  //Event sur changement état ouverture de fenêtre de duplication preconisation technicien
  @Output() duplicateTechPrecoModaleChange = new EventEmitter<EventActionModalLegend>();

  //Event sur changement état ouverture de fenêtre de modification de farmer prévis
  @Output() farmerPrevisUpdtModaleChange = new EventEmitter<EventActionModalLegend>();


  get LegendClassAttrs(): LegendColor[] { return this.mapService.legendColorAttrs ? this.mapService.legendColorAttrs.Class : [] }
  get LegendTitle(): string { return this.mapService.legendTitle; }

  get LegendAttrs(): LegendGroup[] { return this._legendAttrs; }

  private _activeFeatures: any;
  private _activeFarm: Farm[]
  /**Index du groupe de legende selectionné (ex:Modulation fumure ou Diag Ferti) */
  private selectedLegendI: number = 0;
  /**Index  de la legende selectionnée (ex:-Fongicide ou Teneur Cao ) */
  private selectedAttributI: number = 0;
  /**Index  de la Sous legende selectionnée (ex:mon produit ou  null) */
  private selectedSubAttributeI: number = 0;
  private _legendAttrs: LegendGroup[];
  //  private _legendClass: LegendColor[];
  //  private _legendTitle: string;

  private _selectedLegend: LegendGroup;
  /**CheckBox Mode MashVolume visible */
  public WithModeMashVol: boolean = false;

  // Feature dont on tire les attibuts de la légende
  @Input()
  set activeFeatures(features: any) {
    this._activeFeatures = features;
  }
  get activeFeatures(): any { return this._activeFeatures }

  @Input()
  set activeFarm(farm: Farm[]) {
    this._activeFarm = farm;
  }
  get activeFarm(): Farm[] { return this._activeFarm }

  /** Retourne le texte affiché sur le bouton de duplication de preconisation tech */
  get DuplicationFarmerPrecoBtnTitle() {
    return DuplicatePreconisationComponent.ButtonDuplicationTitle;
  }

   /** Retourne le texte affiché sur le bouton de modification farmer previs */
  get ButtonFarmerPrevisUpdateTitle(){
    return UpdateFarmerPrevisComponent.ButtonUpdateTitle;
  }

  @Output()
  selectedLegendItemChange = new EventEmitter<LegendGroup>();

  public blModeMashVolume: boolean = false;

  constructor(private mapService: SharedMapService, public att: AttributToTextService, private _legendService: LegendService, private _eventEmitService:EventEmitterService) {

    if(FarmerPrevisService.ConfigEnableDuplication()){
      //si on gère les farmer previs on elargit le mapside
      this.MapSideCss="mapsidenlarge";
    }
  }

  isChecked(lI: number, aI: number, subaI: number = null) {
    if (subaI)
      return (this.selectedLegendI == lI && this.selectedAttributI == aI && this.selectedSubAttributeI === subaI)
    else
      return (this.selectedLegendI == lI && this.selectedAttributI == aI)
  }


  public groupSelected(event: any, index: number) {

    this._legendService.legend.forEach((legend: LegendGroup) => {
      legend.selected = false;
    });
    if (index >= 0) {/*Passage Potentiel Ferti retourne index à -1 */
      this._legendService.legend[index].selected = !this._legendService.legend[index].selected;
      this.mapService.currentGroup = this._legendService.legend[index].key;
    }

  }

  /**
   * Détection du changement sur activeFeature
   * @param changes
   */

  ngOnChanges(changes: any) {
    if (this._activeFarm == null || this._activeFarm.length === 0 || !this._activeFarm[0].IdCampagne) {
      return;
    }

    var index: number = 0;
    if (this.mapService.currentGroup != null)
      index = this._legendService.legend.findIndex(m => m.key == this.mapService.currentGroup);

    this.groupSelected(null, index);

    // assignation de la legende courante
    if (!changes.LegendTitle) {
      let _legendGroup: LegendGroup = this._legendService.legend[this.selectedLegendI];
      if (_legendGroup != null) {/*Evite plantage si lindex des legends est hors limite */

        if (this.mapService.currentGroup && _legendGroup.key !== this.mapService.currentGroup) {//ce n est plus le meme group de legende selectionné
          this.groupSelected(null, 0);
          this.selectedLegendI = 0;
          this.selectedAttributI = 0;
          this.selectedSubAttributeI = null;
          _legendGroup = this._legendService.legend[this.selectedLegendI];
        }
        if (_legendGroup == null) {
          this.ClearBottomLegRangs();
          return;
        }

        let _legItemSelected: LegendItem = _legendGroup.items[this.selectedAttributI];

        if (!_legItemSelected && _legendGroup.items.length) {//reselectionne le 1e element de la liste
          this.ClearBottomLegRangs();
          this.selectedAttributI = 0;
          _legItemSelected = _legendGroup.items[this.selectedAttributI];
        }
        if (_legItemSelected && _legItemSelected.hasSubMenu) { // on est sur un SOUS menu
          //reselectionne le 1e element sous groupe de la liste
          if (_legItemSelected.items == null || !_legItemSelected.items.length)
            this.selectedSubAttributeI = null;
          else if (!this.selectedSubAttributeI || this.selectedSubAttributeI > _legItemSelected.items.length - 1)
            this.selectedSubAttributeI = 0;

          if (this.selectedSubAttributeI >= 0)
            _legItemSelected = _legItemSelected.items[this.selectedSubAttributeI];
          else
            _legItemSelected = null;
        }

        this.setLegendFocus(_legItemSelected, _legendGroup.key, this.selectedLegendI, this.selectedAttributI, this.selectedSubAttributeI);
      }
    }

    if (changes.activeFeatures) {
      if (changes.activeFeatures.currentValue) {
      }
    }

  }

  /**
   * Application de la sélection des la légende à la cartographie par modifiction du style des élément de la cartographie
   * @param legendItem
   * @param legendGroupName
   * @param legendIndex index de la legende dans son groupe de légende
   * @param attributeIndex index du groupe de la legende dans la liste des legendes
   */
  setLegendFocus(legendItem: LegendItem, legendGroupName: string,
    legendIndex: number, attributeIndex: number,
    subAtrributeIndex: number = null) {

    if (legendItem == null)/*Evite plantage si lindex des legends est hors limite */
      return;

    if (legendItem.name === LegendsConfig.legdModulations && legendItem.hasSubMenu) {// si il a selectionné un group on sort evite plantage
      return;
    }

    let _blMashPreselected: boolean = HandleService.deepCopy(this.blModeMashVolume);
    if (this._selectedLegend && this._selectedLegend.items) {
      this.BindFromFirstBasicStyles(this._selectedLegend.items);
      this.blModeMashVolume = false;
    }

    this.selectedLegendI = legendIndex;
    this.selectedAttributI = attributeIndex;
    this.selectedSubAttributeI = subAtrributeIndex;
    this.mapService.currentGroup = legendGroupName;

    if (subAtrributeIndex) {

      this._selectedLegend = new LegendGroup(legendGroupName, [legendItem]);
    } else {
      this._selectedLegend = new LegendGroup(legendGroupName, [legendItem]);
    }
    this.ManageLegendMashVolume(legendItem);


    this.selectedLegendItemChange.emit(this._selectedLegend);
    this.mapService.setLayer(this._selectedLegend.key, legendItem, true);

    //si préco (inter mode preco pilot Sp Farmer Inter previs ici HORS mmodulation)
    if (LegendService.IsPreconisationLegend(legendItem.name, false)) {
      this.mapService.setStyleModulationFumure(legendItem, legendGroupName);
    } else if (legendItem.name === LegendsConfig.legdModulations) {
      if (!_blMashPreselected)
        this.mapService.setStyleModulationFumure(legendItem, legendGroupName, LegendsConfig.legLibModulationPdtDose);
      else {
        this.blModeMashVolume = true;
        this.ChangeLegendMashVolume(this.blModeMashVolume)
      }
    } else if (legendItem.name === "CultivationPlots") {
      this.mapService.setStyleAssolement(legendItem, legendGroupName);
    } else if (legendItem.name === "Sector") {
      this.mapService.setStyleIlot(legendItem, legendGroupName);
    } else if (legendItem.name === "Conseil fumure") {
      this.mapService.setStyleConseils(legendItem, legendGroupName)
    } else if (legendItem.name == "Potentiel" || legendItem.name == "RU" || legendItem.name == "HCC") {
      this.mapService.setStylePotentiel(legendItem, legendGroupName);
    } else if (legendItem.name === "SO3" || legendItem.name === "N") {
      this.mapService.setStyleConseils(legendItem, legendGroupName)
    } else if (legendGroupName === LegendsConfig.legdAnnaAzote || legendGroupName === LegendsConfig.legdAnnaRend || legendGroupName === LegendsConfig.legdAnnaCarboN) {

      this.mapService.setDiagnosticStyle(legendItem, legendGroupName);
    }
    else if (legendGroupName === LegendsConfig.legdPilotSpLAI) {
      //LAI ou Conseil N les styles sont niveau de Order.Legend dont on fait un mapping de propriétés
      this.mapService.setDiagnosticStyle(legendItem, legendGroupName);
    }
    else if (legendItem.name == LegendsConfig.legFertiMATORG)
      this.mapService.setDiagnosticStyle(legendItem, legendGroupName);
    else {
      this.mapService.setLegendStyle(legendItem, legendGroupName);
    }
  }

  private ManageLegendMashVolume(_iLegSelected: any) {
    let _blShowBtnMash = false;
    if (_iLegSelected && _iLegSelected.name === LegendsConfig.legdModulations) {
      if (_iLegSelected.externeProp && _iLegSelected.externeProp.propertyName && _iLegSelected.externeProp.propertyName == LegendsConfig.legLibMashVolumeValue)
        _blShowBtnMash = true;
    }
    if (_blShowBtnMash != this.WithModeMashVol)
      this.WithModeMashVol = _blShowBtnMash;
  }

  public SwithModeMashVolumeClick(_iblByVol: any) {
    this.ChangeLegendMashVolume(this.blModeMashVolume);
  }

  /**
   * Changement de legende Produit <-> Volume de bouille
   * @param _iblByVol gestion volume de bouillie
   */
  private ChangeLegendMashVolume(_iblByVol: boolean) {

    if (this._selectedLegend && this._selectedLegend.items && this._selectedLegend.items.length)
      if (_iblByVol && this.getExterneProp(this._selectedLegend.items[0], LegendsConfig.legLibMashVolumeValue) !== null) {
        //Contrôle et si il n a pas encore constitué les legendes volumes de bouillie il le fait
        this._selectedLegend.items[0] = this.BuildingMashVolumeLegendeRanges(this._selectedLegend.items[0]);
      }
      else {
        (
          this.BindFromFirstBasicStyles(this._selectedLegend.items))
        this.mapService.setStyleModulationFumure(this._selectedLegend.items[0], this.mapService.currentGroup, LegendsConfig.legLibModulationPdtDose);
      }
  }

  /**
   * Implementation de la legende de volume de bouillie en ramenant la legend de preco en basic
   * et la bouille en style SI NECESSAIRE
   * @param _oLegendModulation legende de modulation à maj au besoin
   * @returns _oLegendModulation
   */
  private BuildingMashVolumeLegendeRanges(_oLegendModulation: any) {
    //contrôle si la legende ne serait pas déja faite
    if (this.IncludeRangesColors(_oLegendModulation.style)) {
      let cnt_ranges = HandleService.LEGEND_COUNT_RANGES;
      _oLegendModulation.Basicstyle = HandleService.deepCopy(_oLegendModulation.style);
      _oLegendModulation["BasicUnit"] = _oLegendModulation.unit;
      _oLegendModulation.unit = _oLegendModulation.externeProp.unit;
      let cntRang = _oLegendModulation.style.Class.length;
      if (cntRang > 0) {
        let _blWithProp = false;
        let _propMash = this.getExterneProp(_oLegendModulation, LegendsConfig.legLibMashVolumeValue);
        if (_propMash) {
          let _mxValue = Number(_oLegendModulation.externeProp.propertyValue);

          if (_mxValue > 0) {
            //si il n avait pas de min value on la force
            let _minValue = isNullOrUndefined(_oLegendModulation.externeProp.propertyMinValue) || _oLegendModulation.externeProp.propertyMinValue == 0 ? _mxValue / cnt_ranges - 1 : Number(_oLegendModulation.externeProp.propertyMinValue);
            _oLegendModulation.style.Class = HandleService.generateScaleWithFirstStepZero(_mxValue, _minValue, cnt_ranges);
          }
          _blWithProp = true;
        }


        this.mapService.setStyleModulationFumure(_oLegendModulation, this.mapService.currentGroup, _blWithProp ? _oLegendModulation.externeProp.propertyName : LegendsConfig.legLibModulationPdtDose);
      }
    }
    return _oLegendModulation;
  }

  private getExterneProp(_ilegendItem: LegendItem, _iPropName: string): LegendExtProperty {
    return _ilegendItem.externeProp && _ilegendItem.externeProp.propertyName == _iPropName ? _ilegendItem.externeProp : null;
  }

  private BindFromFirstBasicStyles(_oLegendItems: LegendItem[]): boolean {
    if (_oLegendItems && _oLegendItems.length) {
      return this.BindFromBasicStyles(_oLegendItems[0]);
    }
    return false;
  }
  private BindFromBasicStyles(_oLegendItem: LegendItem): boolean {
    if (_oLegendItem) {
      if (this.IncludeRangesColors(_oLegendItem.Basicstyle)) {
        _oLegendItem.style = HandleService.deepCopy(_oLegendItem.Basicstyle);
        _oLegendItem.Basicstyle = null;
        if (_oLegendItem["BasicUnit"]) {
          _oLegendItem.unit = _oLegendItem["BasicUnit"];
          _oLegendItem["BasicUnit"] = null;
        }
        return true;
      }
    }
    return false;
  }

  private IncludeRangesColors(_iLegendItem: Legend) {
    return (_iLegendItem && _iLegendItem.Class && _iLegendItem.Class.length > 0)
  }

  /**Nettoyage de la legende du bas selectionnées ( titre et rangs de couleurs) */
  private ClearBottomLegRangs() {
    this.mapService.legendColorAttrs = null;
    this.mapService.legendTitle = null;
  }

  //#region gestion duplication preco en Farmer Previs

  /**
   * event click bouton ouverture fenetre duplication de preconisation technicien
   * @param legend legende de la preco tech selectionnée 
   */
  onButtonOpenPrecoDuplicateDialog(legend: LegendItem) {
    this._eventEmitService.emitDuplicateTechPrecoDialogChange( new EventActionModalLegend(true,legend,false) )
  }

    /**
   * event click bouton ouverture fenetre de modification farmer previs
   * @param legend legende de la farmer prévis selectionnée 
   */
    onButtonOpenFarmerPrevisUpdateDialog(legend: LegendItem) {
      this._eventEmitService.emitUpdateFarmerPrevisDialogChange( new EventActionModalLegend(true,legend,true) )
    }

    //#endregion
}
