import { Observable, of, Subscription } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FarmerPrevisService, EventEmitterService, FarmServices, HandleService, EventActionModalLegend, ModalService } from '../../../_services';
import { Fertilizer, LegendItem, RefMineralFertilizer, RefMineralFertilizers, farmerPrevisSaveChangesResume, ChangesFarmerPlotPrevis, ePrevisChanges, gridFarmerPrevisDatas, farmerPrevisOrdersPrecosDatas, eTechInterType } from '../../../_models';
import { LoadingService } from '../../../framework/auth/components/loading/loading.service';


@Component({
  selector: 'update-farmer-previs',
  templateUrl: './updatefarmerprevis.component.html',
  styleUrls: ['./updatefarmerprevis.component.scss'],
})

// composant de modification d'un previsionnelle farmer (modale)
export class UpdateFarmerPrevisComponent implements OnInit {
 
  public opened: boolean = false;
  //souscription a l'écoute d event ouverture de la modale
  private openModalePrevisSubs: Subscription;
  //la légend préco sélectionnée qui initialise les données
  private duplicatedPrevis: LegendItem;

  private _isLoadingFertilizers: boolean = false;

  private _isLoadingPrevis: boolean = false;

  //pourcentage maximal authorisé
  private _maxPurcent = 50;

  public grdCheckAllChecked: boolean = false;

  //pourcentage minimal authorisé
  private _minPurcent = 5;

  //nombre de caracteres max pour les doses et les quantités encodées
  public qteMaxLength = 7;

  //event fermeture de la modale
  @Output() DialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  //#region text modale

  static ButtonUpdateTitle = "Modifier l'intervention personnalisée";

  private iconCompositionsTltp ="Composition de l'engrais";
  private _emptyRecordText = "Aucune intervention personnalisée présente, votre technicien a peut être entre temps appliqué celle-ci";
  private _loadingRecordText = "Chargement en cours";
  private updateTitlePrefix: string = "Modification de l'intervention personnalisée : ";
  private createTitlePrefix: string = "Création de l'intervention personnalisée : ";
  private PrecoEverDuplicatedTxt: string = "Une préconisation ne peut être dupliquée qu'une seule fois";
  private LoadingPrecoInProgressTxt: string = "Chargement en cours ...";
  private lblCbxFertilizers: string = "Changer l'engrais";
  private lblChangeDate: string = "Changer la date "
  private MorePctTitle: string = "Augmente la dose au % indiqué";
  private ReducePctTitle: string = "Diminuer la dose au % indiqué";
  private BtnAppMassQuantityTxt: string = "Ajuster la quantité";
  private BtnAppMassQuantityTltp: string = "Ajuster la quantité totale de la sélection"
  private PurcentUnit: string = " %";
  private BtnShowingToolsTltp: string = "Modification du pourcentage par défaut";
  private ModaleToolsText: string = "Pourcentage par défaut";
  private labelUserPurcentChoice: string = "Pourcentage : ";
  private BtnConfigText: string = "Configuration";
  private BtnReinitText: string = "Réinitialiser";
  private BtnCancelText: string = "Annuler";
  private BtnSaveText: string = "Enregistrer";
  private PrecoOriginText: string = "Préconisation d'origine ";
  private IconPrevisModulatedTltp: string = "Intervention modulée";
  private ChoiceCompositionTitle: string = "Choisir l'élément fertilisant à moduler";
  //texte tooltip sur icone preco source de la parcelle supprimée
  private IconPrecoLinkBreakTltp:string="Préconisation supprimée ou modifiée";
  //texte affiché en tooltip si la preconisation origine n'est plus présente  le produit origin
  private _cbxOriginPrecoDeleted= "Il n'est pas possible de modifier le produit lorsque la préconisation a été supprimée ou modifiée";
    //texte affiché en tooltip si la preconisation origine multi parcelle est dorénavant sur plusiers produits
  private _cbxOriginPrecoMultiProducts= "Il n'est pas possible de modifier le produit lorsque la préconisation concerne plusieurs produits";
  //texte affiché sur un changement de produit verouillé
  private cbxChangeFertiUnavailable= "";
  private _btnDeleteCssHidden = " BtnHidden ";
  private BtnDeleteCss: string = "";
  private BtnDeleteText: string = "Supprimer";

  //la modale est verouillée durant operation
  private IsLockedModale: boolean = false;

  private _gridEmptyText = this._emptyRecordText;

  //texte formaté avec % negatif
  private get ReducePctText(): string {
    return "-" + `${this.userPurcentChoice}` + "%";
  }

  //texte formaté avec % positif
  private get MoreAddPctText(): string {
    return "+" + `${this.userPurcentChoice}` + "%";
  }

  public get EmptyRecordText() {
    return this._gridEmptyText;
  };

  /**nom produit dose qte de la préconisation origine peut avoir plusieurs*/
  private PrecoOriginProduct: string;

  /**la date de la prévis front*/
  private currentPrevisDate: any;

  //la liste des teneurs du produit chargé de la liste sur format de string
  private FertilizerComposTxt: string = null;
  /**la date de la prévis chargé */
  private _initialPrevisDate: any;
  /**la date minimale authorisée de la prévis */
  private minPrevisDate: any;
  /**la date maximale authorisée de la prévis */
  private maxPrevisDate: any;

  private widthColDose: number = 90;
  /**si on visualise les gestionnaire de config */
  private ShowTools: boolean = false;

  //titl modal de la préco tech sélectionnée
  public title: string = '';
  //#endregion

  /** les données initiales farmer previs chargée*/
  private _memPrevisOrderFarmerPrevis: farmerPrevisOrdersPrecosDatas = null;

  /** les données farmer previs front maj live*/
  private _previsOrderFarmerPrevis: farmerPrevisOrdersPrecosDatas = null;

  /**Verouille les element de la modale */
  private _lockDivsModale: boolean = false;

  /**rend indispo les boutons reinit et enregistrer */
  private _disableChanges: boolean = false;

  ///données du grid de base présente les exploitantion precos
  private _gridFarmerPrevis: gridFarmerPrevisDatas = null;
  //le nombre de preco selectionnés
  private _previsSelectedCounter: number = 0;
    //le nombre de totale precos
    private _totalPrevisCounter: number = 0;
  //si on est sur un multi exploitation
  private _isMultiFarm: boolean = false;

  /** le référentiel minérale complet*/
  private _memRefMinFertilizers: RefMineralFertilizers = null;

  /**le référentiel chargé filtrés des produits de la liste */
  private _PrecoRefMinFertilizer: RefMineralFertilizers = null;

  /**  le fertilisant minérale selectionné liste front*/
  public selectedMinFertilizer: RefMineralFertilizer;
  /** code fertilisant précédement sélectionné */
  private _previousSelFertilizerCode: string;

  /** code fertilisant de la préconisation initiale :si 'false' la preco a été supprimée ou en multi ferti */
  private _precoFertilizerCode: string|false;

  /**
   * Retourne si la préconisation d'origine a été totalement supprimée
   */
  public get IsOriginPrecoDeleted (){
    return this._precoFertilizerCode == false;
  }

  //si présence du produit de la préconisation icone teneurs
  private IconPrecoriginComposition:string=null;

  public selectedFertiComposition: any = null;
  public choiceFertiCompositions: any[] = null;

  /**Valeur de la quantité en application de masse */
  public appMasseQte: number | null;

  /**banner total des doses */
  private GrdTotProductDose: string = "";
  /**banner total des quantités */
  private GrdTotProductQte: string = "";

    /**banner total des surface de micros des prévis selectionnées */
    private GrdTotAreas: string = "";

  public get DisableChanges(): boolean {
    return this._disableChanges;
  }

  public get LockDivsModale() {
    return this._lockDivsModale;
  }

  //indique que le chargement des preco est en cours
  public get IsLoadingInProgress() {
    return this._isLoadingPrevis == true;
  }
  //le % à appliquer
  public get userPurcentChoice(): number {
    return this._farmerPreviService.UserConfigStepPurcent
  }
  /**attibue le % de configuration client */
  public set userPurcentChoice(value: number) {
    this._farmerPreviService.UserConfigStepPurcent = value
  }

  /** les engrais minéraux à afficher dans la liste */
  public get minFerilizers(): RefMineralFertilizer[] | null {
    if (this._PrecoRefMinFertilizer !== null && this._PrecoRefMinFertilizer.Exists) {
      return this._PrecoRefMinFertilizer.minerals;
    }
    return null;
  }
  /**l'unité dose du produit sélectionné */
  public get ProductUnitDose() {
    return this.selectedMinFertilizer?.unit ? (this.selectedMinFertilizer?.unit + "/ha") : "";
  }
  /**l'unité du produit sélectionné */
  public get ProductUnit() {
    return this.selectedMinFertilizer?.unit ?? "";
  }

  /**le nombre de preco selectionnés */
  public get SelectionCounter() {
    return this._previsSelectedCounter;
  }

  public get TotalGridRows() {
    return this._totalPrevisCounter;
  }

  /**Indique si on est sur un multi exploitation */
  public get IsMultiFarm() {
    return this._isMultiFarm;
  }

  ///données du grid de base présente les exploitantion precos
  public get GridFarmerPrevis() {
    return this._gridFarmerPrevis;
  }

  public constructor(private _farmerPreviService: FarmerPrevisService, private _eventEmitterService: EventEmitterService, private farmDataService: FarmServices, private _loadingService: LoadingService, private _modaleService: ModalService) {
  }

  public ngOnInit() {

    if (FarmerPrevisService.ConfigEnableDuplication() == true) {

      this.openModalePrevisSubs = this._eventEmitterService.invokeFarmerPrevisUpdtDialogChange.subscribe((precoOptions: EventActionModalLegend) => {
        if (precoOptions.IsOpen && this._farmerPreviService.IsDuplicationAccess) {

          if (precoOptions.IsSrcPrevis && precoOptions?.LegendSelected) {
            this.IsLockedModale = false;
            this.opened = precoOptions.IsOpen;
            //on est sur une modification on ouvre la modale et on charge
           
            this.LoadingDatasOnUpdatePrevis(precoOptions?.LegendSelected);

          } else if (precoOptions.IsSrcPrevis == false && precoOptions.GetFarmerInitialPreco() != null) {
            //on vient des preco duplication vers creation de previs
            this.opened = precoOptions.IsOpen;
            this.IsLockedModale = false;
            this.LoadingDatasOnCreatePrevis(precoOptions.GetFarmerInitialPreco());

          } else {
            this.ClearAndClose();
          }

        } else {
          //cas d'un changement de campagne il faut effacer et refermer la modale
          this.ClearAndClose();

        }
      });
    } else {
      this.ClearAndClose();
    }
  }

  ngOnDestroy(): void {
    this.openModalePrevisSubs.unsubscribe();
  }

  /**nettoie et ferme la modale */
  private ClearAndClose() {
    this.Clear();
    this.close()
  }

  //nettoyage des données de la préconisation tech farmer previs chargé
  private Clear() {
    
    this._gridFarmerPrevis = null;
    this._previsSelectedCounter = 0;
    this._totalPrevisCounter = 0;
    this._isMultiFarm = false;
    this._memPrevisOrderFarmerPrevis = null;
    this._previsOrderFarmerPrevis = null;
    this._previousSelFertilizerCode = null;
    this._precoFertilizerCode=null;
    this.cbxChangeFertiUnavailable = "";
    this.IconPrecoriginComposition = null;
    this.selectedMinFertilizer = null;
    this.selectedFertiComposition = null;
    this.choiceFertiCompositions = null;
    this._lockDivsModale = false;

    this.appMasseQte = null;
    this.ShowTools = false;
    this.title = "";
    this.BtnDeleteCss = "";
    this._disableChanges = false;
    this.duplicatedPrevis = null;
    this.GrdTotProductDose = "";
    this.GrdTotProductQte = "";
    this.GrdTotAreas = "";
    this.PrecoOriginProduct = "";

    this.IsLockedModale = false;
    this.currentPrevisDate = "";
    this._initialPrevisDate = "";
    this.maxPrevisDate = "";
    this.minPrevisDate = "";
    this._isLoadingFertilizers = false;
    this.grdCheckAllChecked = false;
    this.FertilizerComposTxt = null;
  }

  /**Fermeture de la modale */
  public close() {
    if (this.opened) {
      this.Clear();
      this.opened = false;

      //rappel la fonction onFarmerPrevisUpdateDialogClosed
      this.DialogClosed.emit(this.opened);
    }

  }

  

  /**
   * Chargement des données depuis une sélection de legende previs
   * @param _iPrevisSelected 
   */
  private LoadingDatasOnUpdatePrevis(_iPrevisSelected: LegendItem) {

    this.Clear();
    //la source est une previs depuis un pilot sp
    let _blisSourcePrecoPilotSP = _iPrevisSelected.options !== null && _iPrevisSelected.options == FarmerPrevisService.OPTION_CODE_LEGEND_PRECO_SP;
    let prestaOrderIds = _blisSourcePrecoPilotSP == true ? this.farmDataService.GetOrderIdsOnPilotSPPrestation() : this.farmDataService.GetOrderIdsOnAdvicePrestation();
    this.ChangeStatusLoading(true);
    if (_iPrevisSelected && _iPrevisSelected.uniqueFormatedId && prestaOrderIds?.length) {
      this.duplicatedPrevis = _iPrevisSelected;

      this.title = this.updateTitlePrefix + _iPrevisSelected?.legendName;

      this._farmerPreviService.GetFarmerPrevisDatas(_iPrevisSelected.uniqueFormatedId, prestaOrderIds, false).subscribe(
        (data: any) => {
          if (data && data.Orders?.length) {
            this._memPrevisOrderFarmerPrevis = new farmerPrevisOrdersPrecosDatas(data, true);
            this.BindingPrevisDatasWithFertilizer(this._memPrevisOrderFarmerPrevis, false);
          } else {

            this._previsOrderFarmerPrevis = this._memPrevisOrderFarmerPrevis = null;
            this.BindGridDatas(null);
            this.BindingPrecoFertilizer(null);
            this.BindFrontPrevisDate(null);
            this.SetDisableChanges();
          }
          this.ChangeStatusLoading(false);
          if (this._isLoadingFertilizers) {
            //on remet le loader de produit non chargé
            this.ShowLoaderProductWaiting();
          }
        },
        (error: any) => {

          this.ShowError('Une erreur est survenue accès impossible', true, true);
          this.ChangeStatusLoading(false);
          this.close()
        }
      )
    } else {
      this.ChangeStatusLoading(false);
      this.close();
    }
  }

  /**Chargement des données depuis une creation depuis une preconisation */
  private LoadingDatasOnCreatePrevis(_iPrecoPlotSelected: farmerPrevisOrdersPrecosDatas) {
    this.Clear();
    this.ChangeStatusLoading(true);

    let formatName = " - ";
    if (_iPrecoPlotSelected.SrcProduct) {
      formatName = _iPrecoPlotSelected.SrcProduct.Name + " - " + _iPrecoPlotSelected.SrcDate;
    }
    this.title = this.createTitlePrefix + formatName;
    this._memPrevisOrderFarmerPrevis = _iPrecoPlotSelected;
    //on change les status to update pour celles sans id ainsi il peut les sauver
    this._memPrevisOrderFarmerPrevis.ChangePrevisToCreateStatus();
    this.BindingPrevisDatasWithFertilizer(this._memPrevisOrderFarmerPrevis, true);
  }

  /**
   * Chargement des données de produits fertilisant lié à l'exploitation (si unique) de prévis chargés
   * et remplissage des données de prévis du grid 
   * @param _iFarmerPrevis 
   */
  private BindingPrevisDatasWithFertilizer(_iFarmerPrevis: farmerPrevisOrdersPrecosDatas, _iIsCreation:boolean) {
    this._previsOrderFarmerPrevis = _iFarmerPrevis.Clone();

    let uniqFarmId = _iFarmerPrevis.IsMultiFarms() ? null : _iFarmerPrevis.GetFirstFarmId();
    if(this._farmerPreviService.IncludeReferential==false || this._farmerPreviService.RefFertilizer.IsFarmerReferential(uniqFarmId) == false){
      this._isLoadingFertilizers = true;
      this.ShowLoaderProductWaiting();
    }
    //chargement du référentiel produit (exploitant) si non présent
    this._farmerPreviService.GetFarmerReferentialFertilizer(uniqFarmId).subscribe((referential:RefMineralFertilizers)=>{
      if(referential == null || referential.Exists == false){
        this._lockDivsModale = true;
        this._isLoadingFertilizers = false
        this.ShowError("Impossible de charger la liste des produits, veuillez réessayer dans quelques instants.", true, true);
        this._disableChanges = true;
        setTimeout(() => {
          this.close();
        }, 5000);

      } else {
        this.BindFrontOrdersPrevisExchangeData(this._previsOrderFarmerPrevis);
        this.HidLoader();
        this.ChangeStatusLoading(false);
      }});
      if(_iIsCreation) {
          //on ne peut supprimer
          this.BtnDeleteCss = this._btnDeleteCssHidden;
      }
  }

  /**Constitution et remplissage des données des parcelles de la  Préconisation tech sélectionné */
  private BindFrontOrdersPrevisExchangeData(_iResponse: farmerPrevisOrdersPrecosDatas, _iPreserveSelection:boolean=false) {
   
    this.BindFrontPrevisDate(_iResponse);

    if (_iResponse) {
      let grdFarmerPrevisDatas = _iResponse.Map(null, false);
      this.BindGridDatas(grdFarmerPrevisDatas,_iPreserveSelection);
      this.BindingPrecoFertilizer(grdFarmerPrevisDatas);
      this._isMultiFarm = _iResponse?.IsMultiFarms() ?? false
    } else {
      this.BindGridDatas(new gridFarmerPrevisDatas(null, false), true);
      this.BindingPrecoFertilizer(null);
    }

    //le tech a probablement appliqué les modifs OU il a supprimé les précos source et reinitialisé la|les parcelles ou l'ilot
    if (this.GridFarmerPrevis.Exists() == false) {
      this.BtnDeleteCss = this._btnDeleteCssHidden;
      this._lockDivsModale = true;
      this.ShowInfoAlert(this._emptyRecordText)
    }

    //si au chargement > 1 selection on coche
    if (this._gridFarmerPrevis.GetGridSelection()?.length > 0) {
      this.grdCheckAllChecked = true;
    }

    this._totalPrevisCounter = this.GridFarmerPrevis.Exists()? this.GridFarmerPrevis.Previs.length  : 0;

    this.UpdateSelectionCounter();
  }

  /**MAJ du compteur d'éléments sélectionnés */
  private UpdateSelectionCounter() {
    this._previsSelectedCounter = this._gridFarmerPrevis?.Previs.filter(e => e.selected == true).length ?? 0;

    if(this.GridFarmerPrevis.Exists()){
      this._gridFarmerPrevis.ComputeSelectedPrevisTotals();
      this.GrdTotProductDose = this._gridFarmerPrevis.GetTotalSelectedDoseUnit();
      this.GrdTotProductQte = this._gridFarmerPrevis.GetTotalSelectedQteUnit();
      this.GrdTotAreas = this._gridFarmerPrevis.GetTotalSelectedAreas();
    }
    else {
      this.GrdTotProductDose = "";
      this.GrdTotProductQte = "";
      this.GrdTotAreas= ""
    }
   
  }

  /**Changement du statut de chargement */
  private ChangeStatusLoading(_iIsLoadingProcess: boolean) {

    if (_iIsLoadingProcess == true) {
      this.ShowLoaderWaiting();
    } else {
      this.HidLoader();
    }
    this._isLoadingPrevis = _iIsLoadingProcess == true;

    this._gridEmptyText = _iIsLoadingProcess == true ? this._loadingRecordText : this._emptyRecordText;
  }

  //sur une selection de parcelle preco
  public onFarmPlotPreviSelection(selection: any) {
    selection.selected = !selection.selected;
    this.UpdateSelectionCounter();
  }


  /**
   * Constitution de la liste des fertilisants filtrés selon prestation order et type de previs, et compostion du produit
   * @param _iCompletReferential reférentiel complet 
   * @param _iPreviProduct produit de la farmer previsionnelle
   * @param _iOrgPrecoFertiCode  false: plus de preconisation source ou multi produit(on ne charge que le produit de la prévis), code du fertilisant de la preconisation source (tech)
   */
  private BindingMineralsFertilizer(_iCompletReferential: RefMineralFertilizers, _iPreviProduct: Fertilizer, _iOrgPrecoFertiCode:string|false): boolean {
 
    this._memRefMinFertilizers = _iCompletReferential;
    this.selectedMinFertilizer = null;
    this.FertilizerComposTxt = null;
    this.IconPrecoriginComposition = null;
    this._previousSelFertilizerCode = null;

    let isValid = true;
 
    this._precoFertilizerCode = _iOrgPrecoFertiCode;
    
    //si la preconisation source technicien est supprimée
    let isDeletedOrigin = _iOrgPrecoFertiCode ==false;

    if (_iCompletReferential != null && this._memPrevisOrderFarmerPrevis) {

      //on pourrait avoir un produit 
      let currentRefProduct = _iPreviProduct != null ? _iCompletReferential.GetByFertilizerCode(_iPreviProduct.code) : null;

      //le produit de la preconisation source
      let initialPrecoProduct:RefMineralFertilizer = null;
      if(isDeletedOrigin == false && this._precoFertilizerCode !== null && this._precoFertilizerCode !== "" && this._precoFertilizerCode !== false) {
        initialPrecoProduct = _iCompletReferential.GetByFertilizerCode(this._precoFertilizerCode);
        if(initialPrecoProduct){
          this.IconPrecoriginComposition = initialPrecoProduct.FormatCompositionText();
        }
      }
      let prestaFerti: RefMineralFertilizers;
      if(isDeletedOrigin == false) {
       
        //les preco selectionnées sont spéarés entrer advice et pilotSP
        //#region Filte le produit selon le type de preco
        let isAdvice = this._memPrevisOrderFarmerPrevis.IncludeOrderAdvice();
        if (!isAdvice) {
          if (this._memPrevisOrderFarmerPrevis.IncludeOrderPilotSP()) {
            prestaFerti = _iCompletReferential.GetFilterFertiOnNS();

          } else {
            //ni un conseil ni un Pilot SP on à pas encore géré, pas censé passer
            this.ShowError('Type de prestation indéterminé impossible de continuer.', true, true);
            isValid = false;
          }
        } else {

          let includePrevisN = this._memPrevisOrderFarmerPrevis.IncludePrevisN();
          let includePrevisPK = this._memPrevisOrderFarmerPrevis.IncludePrevisPK();

          if (includePrevisN && includePrevisPK) {
            //donc presence de compo N ET compo PK
            prestaFerti = _iCompletReferential.GetFilterFertiOnNSPK()

          } else if (includePrevisN) {
            //donc presence de compo N 
            prestaFerti = _iCompletReferential.GetFilterFertiOnNS()
          } else if (includePrevisPK) {
            //donc presence de compo PK
            prestaFerti = _iCompletReferential.GetFilterFertiOnPK()
          } else {
            this.ShowError('Type de produit indéterminé impossible de continuer.', true, true);
            isValid = false;
          }
        }  if (initialPrecoProduct != null) {
          //les compos acives du produit initial de la preco technicien
          let filterCompo = [];
          Object.keys(initialPrecoProduct.ACompos).forEach(prop => {
            if (initialPrecoProduct.ACompos[prop] > 0) {
              filterCompo.push(prop);
            }
          })
  
         
          //cas d un produit N en preco PK il a été filtré par les règle au dessus mais on le rajoute
          if (currentRefProduct !== null && prestaFerti.GetByFertilizerCode(currentRefProduct.code) == null) {
            prestaFerti.minerals.push(currentRefProduct);
          }
  
          let onCompo = prestaFerti.GetFilterWithCompositions(filterCompo);
          let foundedSrc = onCompo.minerals.filter(r => r.code == _iPreviProduct.code);
          //on a retrouvé le produit tech dans la liste referentielle
          let currentFerti = foundedSrc?.length ? foundedSrc[0] : null;
          if (currentFerti) {
            this.selectedMinFertilizer = new RefMineralFertilizer(currentFerti, null);
            this._previousSelFertilizerCode = currentFerti.code;
            this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
          } else {
  
            if(this._farmerPreviService.FertilizerWithComposition(_iPreviProduct) == false){
              this.ShowError('Produit ne comporte pas de teneur modulable.', true, true);
            }else {
              this.ShowError('Produit non disponible impossible de continuer.', true, true);
            }
  
            isValid = false;
          }
  
          this.BindFertilizerList(onCompo);
        }
      //#endregion
      } else {
       
        //la preco n'est plus présente on ne met que le produit présent dans la liste
        //plus possible de modifier le produit
        let uniquePrevisProduct : RefMineralFertilizers = new  RefMineralFertilizers(this._memRefMinFertilizers.farmId,this._memRefMinFertilizers.minerals.filter(t=>t.code == _iPreviProduct.code));
        this.BindFertilizerList(uniquePrevisProduct);
        this.selectedMinFertilizer = uniquePrevisProduct.GetByFertilizerCode(_iPreviProduct.code)
        this._previousSelFertilizerCode =  this.selectedMinFertilizer?.code;
        this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
      }
    
    } else {
      isValid = false;
    }
    return isValid;
  }

  /**Remplissage de la liste front des fertilisants */
  private BindFertilizerList(_iMinerals: RefMineralFertilizers) {

    if (_iMinerals?.Exists) {
      _iMinerals.OrderByName();
    }
    this._PrecoRefMinFertilizer = _iMinerals;
  }


  //visualiser le chargmeent loader
  ShowLoaderWaiting() {
    this._loadingService.show("Merci de patienter durant le chargement des interventions personnalisées ...");
  }

  ShowLoaderProductWaiting() {
    this._loadingService.show("Merci de patienter durant le chargement de la liste des produits ...");
  }

  ShowLoaderSaveWaiting() {
    this._loadingService.show("Merci de patienter durant l'enregistrement ...");
  }

  ShowLoaderDeletingWaiting() {
    this._loadingService.show("Merci de patienter durant la suppression ...");
  }
  //dissimuler le loader
  HidLoader() {
    this._loadingService.hide();
  }

  /**modification du statut de visualisation de la configuration */
  public onClickShowingTools() {
    this.ShowTools = !this.ShowTools;

  }

  //#region Event Grid inputs

  /**event changement de checkbox selection toute les previs selectionnables*/
  public onChangeCheckAll() {
    if (this._gridFarmerPrevis.Exists()) {
      let setSelected = this.grdCheckAllChecked == false;
      this._gridFarmerPrevis.Previs.forEach(fPrevis => {
        if (fPrevis.enabled == true) {
          fPrevis.selected = setSelected;
        }
      })
      this.UpdateSelectionCounter();
    }
  }

  /**
   * sur changement de valeur de % configuré selon les limites
   */
  public OnChangePurcentConfig() {
    if (this.userPurcentChoice > this._maxPurcent) {
      this.userPurcentChoice = this._maxPurcent;
    } else if (this.userPurcentChoice < this._minPurcent) {
      this.userPurcentChoice = this._minPurcent
    }
  }

  /**
   * event sur sortie d'input numeric dose ou quantité niveau du grid
   * @param dataItem dataPrevis
   * @param propertie ['PrevisQte','PrevisDose']
   */
  public onPrevisDosingChanged(dataItem: any, propertie: string): void {

    if (dataItem[propertie]) {
      //on arrondit à 3 décimales max l'entrée
      dataItem[propertie] = HandleService.RoundDecimals(dataItem[propertie], 3)
    }

    let _PrevisChanges: ePrevisChanges = ePrevisChanges.NONE;
    if (propertie == 'PrevisQte') {
      _PrevisChanges = ePrevisChanges.PREVIS_QTE;
    } else if (propertie == 'PrevisDose') {

      _PrevisChanges = ePrevisChanges.PREVIS_DOSE;
    }

    this.ApplyChanges(dataItem, _PrevisChanges)
  }
  //#endregion

  //#region Event chanes on Previs

  /**
   * Event sur changement de produit fertilisant
   * @param _iSelectedFerti produit selectionné
   * @returns 
   */
  public onFertilizerValueChanged(_iSelectedFerti: RefMineralFertilizer) {

    this.selectedFertiComposition= null;
    if (_iSelectedFerti !== undefined) {
      let isPilotSP = this._memPrevisOrderFarmerPrevis.IncludeOrderPilotSP()


      if (!isPilotSP && HandleService.CountRecords(_iSelectedFerti.ACompos) > 1) {

        this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();

        let withN = this._memPrevisOrderFarmerPrevis.IncludePrevisN();
        let withPK = this._memPrevisOrderFarmerPrevis.IncludePrevisPK();

        let filters = [];
        if (withN) {
          filters.push(RefMineralFertilizers.GetKeysCompositionsN())
        }
        if (withPK) {
          filters.push(RefMineralFertilizers.GetKeysCompositionsPK())
        }

        this.choiceFertiCompositions = [];

        let initialPreco = this._precoFertilizerCode && this._PrecoRefMinFertilizer.minerals.find(e => e.code == this._precoFertilizerCode)
     
        let mapCompos = [];
        //on ne propose que les teneurs commune avec le produit précédement sélectionné
        Object.keys(_iSelectedFerti.ACompos).forEach((compoKey: string) => {
          if(!initialPreco || initialPreco.GetComposition(compoKey) > 0) {
            mapCompos.push({
              'code': compoKey,
              'value': compoKey + " : " + HandleService.RoundDecimalsView(_iSelectedFerti.ACompos[compoKey], -1),
            })
          }
      
        })
        if (mapCompos.length > 0) {
          if (mapCompos.length == 1) {
            this.ApplyChangeFertilizerByComposition(_iSelectedFerti, mapCompos[0].code);

          } else {
            //ca affiche le choix de composition sur laquelle moduler
            this.choiceFertiCompositions = mapCompos;
            this._lockDivsModale = true;
          }
        } else {
          //on remet le précédent
          if (this._previousSelFertilizerCode && this._PrecoRefMinFertilizer.minerals.findIndex(e => e.code == this._previousSelFertilizerCode) > -1) {
            let previous = this._PrecoRefMinFertilizer.minerals.find(e => e.code == this._previousSelFertilizerCode);
            this.selectedMinFertilizer = new RefMineralFertilizer(previous, null);
            this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
            return false;
          }
        }

      } else {
        let firstCompo = isPilotSP ? "N" : Object.keys(_iSelectedFerti.ACompos)[0];
        this.ApplyChangeFertilizerByComposition(_iSelectedFerti, firstCompo)
        this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
      }
    } else {
      //on remet le précédent
      if (this._previousSelFertilizerCode && this._PrecoRefMinFertilizer.minerals.findIndex(e => e.code == this._previousSelFertilizerCode) > -1) {
        let previous = this._PrecoRefMinFertilizer.minerals.find(e => e.code == this._previousSelFertilizerCode);
        this.selectedMinFertilizer = new RefMineralFertilizer(previous, null);
        this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
        return false;
      }
    }

  }

  /**Event attribution de valeur de dose ou quantité en application de masse */


  /**Application de masse de la quantité pour les parcelles selectionnées */
  public onAppMasseQuantity() {

    if (this._gridFarmerPrevis.GetSelectedCounter() > 0 && Number(this.appMasseQte) > 0) {
      let grdPrevisSelections = this._gridFarmerPrevis.GetGridSelection();
      let firstRow: ChangesFarmerPlotPrevis = new ChangesFarmerPlotPrevis(grdPrevisSelections[0]);
      firstRow.SetAppMassPrevisQte(grdPrevisSelections, Number(this.appMasseQte), this.GridFarmerPrevis.GetTotalSelectedQte());
      this.ApplyChanges(firstRow, ePrevisChanges.APP_MASS_PREVIS_QTE);
    }
    setTimeout(() => {
      this.appMasseQte = null
    }, 1500);

  }

  /** event sur changement de date dans textbox date*/
  public onChangePrevisDate(event: any) {

    let processChange = false;
    if (!this.currentPrevisDate) {
      this.currentPrevisDate = this._initialPrevisDate;
      processChange = true;

    } else {
      //sur encodage il faut verifier les dates
      let inputDate = new Date(this.currentPrevisDate);

      if (!inputDate) {
        this.ShowInfoError("La date : '" + this.currentPrevisDate + "' est invalide.", "Date invalide")
      }

      if (this._previsOrderFarmerPrevis.MinimalRangDate && this._previsOrderFarmerPrevis.MaximalRangDate) {
        //soucis de gmt sur le datepicker
        let maxDate = new Date(this._previsOrderFarmerPrevis.MaximalRangDate)
        maxDate.setDate(maxDate.getDate() + 1);
        if (this._previsOrderFarmerPrevis.MinimalRangDate > inputDate || maxDate <= inputDate) {
          this.ShowInfoError("La date '" + HandleService.dateToString(inputDate) + "' doit être comprise entre le " + this._previsOrderFarmerPrevis.MinimalConfigDate + " et le " + this._previsOrderFarmerPrevis.MaximalConfigDate + ".", "Date invalide")
          this.currentPrevisDate = this._initialPrevisDate;
        } else {
          processChange = true;

        }
      } else {
        processChange = true;
      }

    }
    if (processChange) {
      let op = new ChangesFarmerPlotPrevis(null);
      let inputDate = new Date(this.currentPrevisDate);
      //convertion en 2021-03-10T00:00:00.000Z
      op.PrevisDate = inputDate.toISOString()
      this.ApplyChanges(op, ePrevisChanges.GLOBAL_DATE);
    }
  }
  /**
   * Event sur choix d'une composition de produit fertilizer
   * @param compoSelected 
   */
  public onChangeFertilizerCompos(compoSelected: any) {
    //on est sur un changement de compo PK ou N
    if (compoSelected && compoSelected['code']) {
      this.ApplyChangeFertilizerByComposition(this.selectedMinFertilizer, compoSelected.code);
      this.choiceFertiCompositions = null;
      this._lockDivsModale = false;
    }
  }

  /** Event une application de masse sur un raclaul depuis un % */
  public onAppMasseChangeByPurcent(_iStatus: string, _iPrevis: any) {

    
    if (this._gridFarmerPrevis.GetSelectedCounter() > 0) {
      let isNegative = _iStatus == 'N';
      let changesPrevis = new ChangesFarmerPlotPrevis(null);
      changesPrevis.SetUserConfigPurcentCompute(this.userPurcentChoice, isNegative, this._gridFarmerPrevis.GetGridSelection());
      this.ApplyChanges(changesPrevis, ePrevisChanges.APP_MASS_CHANGE_BY_PURCENT);
    }

  }

  /**
   * Event sur un changement de quantité de la previs depuis click sur le %
   * @param _iStatus  statut['N','P']
   * @param _iPrevis la prévisionnelle sélectionnée
   */
  public onPreviChangeByPurcent(_iStatus: string, _iPrevis: any) {
    let changesPrevis = new ChangesFarmerPlotPrevis(_iPrevis);
    let isNegative = _iStatus == 'N';
    changesPrevis.SetUserConfigPurcentCompute(this.userPurcentChoice, isNegative, null);
    this.ApplyChanges(changesPrevis, ePrevisChanges.PREVIS_CHANGE_BY_PURCENT);
  }

  /**
   * Application du changement de produit
   * @param _iSelectedFerti produit sélectionné
   * @param _iModCompoCode code composition sur laquelle moduler
   */
  private ApplyChangeFertilizerByComposition(_iSelectedFerti: RefMineralFertilizer, _iModCompoCode: string) {
    //on repart des données initiale si le produit a changé
    this._previsOrderFarmerPrevis = new farmerPrevisOrdersPrecosDatas(this._memPrevisOrderFarmerPrevis, false)
    this._previousSelFertilizerCode = this._memPrevisOrderFarmerPrevis.SrcProduct.code;
    let currentPrev = this._memRefMinFertilizers.GetByFertilizerCode(this._previousSelFertilizerCode);
    let firstRow: ChangesFarmerPlotPrevis = new ChangesFarmerPlotPrevis(this._gridFarmerPrevis.Previs[0]);
    firstRow.SetNewFertilizer(_iSelectedFerti, currentPrev, _iModCompoCode)
    this.ApplyChanges(firstRow, ePrevisChanges.GLOBAL_FERTILIZER);
    this._previousSelFertilizerCode = _iSelectedFerti.code;
  }

  /**
   * Application du changement selectionné à l'objet et selon modif
   * rechargement des données du grid
   * @param dataItem 
   * @param propChanged 
   */
  private ApplyChanges(_iPrevisChange: ChangesFarmerPlotPrevis, _iPropChanges: ePrevisChanges) {
    let changes = this._previsOrderFarmerPrevis.ApplyChanges(_iPrevisChange, _iPropChanges);
    if (changes > 0 && _iPropChanges != ePrevisChanges.GLOBAL_DATE) {
      //on recharge les données du grid maj
      this.BindGridDatas(this._previsOrderFarmerPrevis.Map(null, false), true);      
    } else {
      if (_iPropChanges != ePrevisChanges.GLOBAL_DATE) {
        //TO DO nettoyer une fois les testes ok
        console.error('NONE apply changes', _iPropChanges.toString())
      }
    }
  }

  //#endregion

  /**
   * remplissage du grid et maj des totaux
   * @param _iGrdDatas nouveau grid datas
   * @param _iblPreserveSelection conserve la selection
   *  
   */
  private BindGridDatas(_iGrdDatas: gridFarmerPrevisDatas | null, _iblPreserveSelection:boolean=false) {

    if (_iGrdDatas !== null) {
      let preselection:string[] = null;
      let setEmptySelection= false;
      if(_iblPreserveSelection && this._gridFarmerPrevis?.Exists()){
        preselection = this._gridFarmerPrevis.GetGridSelection()?.map(e=>e.UniqueId) ?? [];
        setEmptySelection = preselection.length == 0;
      }
      this._gridFarmerPrevis = _iGrdDatas;
      if(preselection !== null && preselection.length > 0){
        this._gridFarmerPrevis.ChangeSelection(preselection);
      }else if(setEmptySelection){
        this._gridFarmerPrevis.ChangeSelection(null);
      }
      this.UpdateSelectionCounter();
    }
    else {
       //pas de donnée
      this._gridFarmerPrevis = new gridFarmerPrevisDatas(null, false)
      this.GrdTotProductDose = "";
      this.GrdTotProductQte = "";
      this.GrdTotAreas = "";
    }
  }

  /**
   * Constitution des données initialisée du fertilisant de la preconisation originale technicien
   * affichage origin ses teneurs et la liste des produits
   * @param _iGrdDatas 
   */
  private BindingPrecoFertilizer(_iGrdDatas: gridFarmerPrevisDatas | null){
    if(_iGrdDatas !== null && _iGrdDatas.Exists()) {
     
      if (this._gridFarmerPrevis.GetOriginsProducts(false) !== null) {
        //le produit d'origine
        let precoOrigins = this._gridFarmerPrevis.GetOriginsProducts(true);
        this.PrecoOriginProduct = precoOrigins?.join('. ');
        let techPrecoProductCode:string|false = "";
        let techPrecoProductsCodes  =this._gridFarmerPrevis.GetOriginsProductsCodes();
       
        let isMultiProduct = techPrecoProductsCodes.length >1;

          if(!techPrecoProductsCodes.length){
            //la préconisation source fut supprimée le changement de produit est verouillé
            techPrecoProductCode = false;
            this.cbxChangeFertiUnavailable = this._cbxOriginPrecoDeleted;
          
          }else if(isMultiProduct){
           //cas d'une preco (1produit 1 date) multi parcelle devenue farmer previs, MAIS dont des precos source ont leur produit ayant été modifiées apres par tech
           //on verouille la liste
            techPrecoProductCode =false;
            this._precoFertilizerCode = null;
          }else {
            //il n a qu un seul produit
            techPrecoProductCode =techPrecoProductsCodes[0];
          }
        
        if (this._precoFertilizerCode == null && this._farmerPreviService.RefFertilizer?.Exists &&  this._previsOrderFarmerPrevis.SrcProduct){      
            this.BindingMineralsFertilizer(this._farmerPreviService.RefFertilizer,  this._previsOrderFarmerPrevis.SrcProduct, techPrecoProductCode)
        }
        if(isMultiProduct){
          this._precoFertilizerCode = false;
          this.cbxChangeFertiUnavailable = this._cbxOriginPrecoMultiProducts;
        } 
      } else {
        //la préconisation source fut supprimée ou modifiée (produit ou date)
        this.PrecoOriginProduct = "";
        this.cbxChangeFertiUnavailable = this._cbxOriginPrecoDeleted;
        this._precoFertilizerCode = false;
      }

     
    } else {
      this.PrecoOriginProduct = "";
      this._precoFertilizerCode = null;
    }
  }

  /**Remplissage de la date et ses limites */
  private BindFrontPrevisDate(_iResult: farmerPrevisOrdersPrecosDatas | null) {
    if (_iResult != null) {
      this.currentPrevisDate = HandleService.StringToInputDateFormat(_iResult.SrcDate, true);
      this._initialPrevisDate = HandleService.StringToInputDateFormat(_iResult.SrcDate, true);
      this.minPrevisDate = HandleService.DateToInputDateFormat(_iResult.MinimalRangDate, true);
      this.maxPrevisDate = HandleService.DateToInputDateFormat(_iResult.MaximalRangDate, true);


    } else {
      this.currentPrevisDate = "";
      this._initialPrevisDate = "";
      this.minPrevisDate = "";
      this.maxPrevisDate = ""
    }

  }

  /**visualise une modale en alerte avec un message sans confirmation */
  private ShowInfoAlert(_iUserText: string) {

    this._modaleService.alert('Attention', _iUserText)
  }

  /**visualise une modale en erreur avec un message sans confirmation */
  private ShowInfoError(_iUserText: string, _iTitle: string = 'Erreur') {

    this._modaleService.Error(_iTitle, _iUserText);
  }

  /**
   * Affiche le msg erreur et gestion du front 
   * @param _iMessage texte à afficher
   * @param _iLockDiv si != null indique si on verouille la div
   * @param _iSetDisableChanges  si != null indique si on rend indispo des modification(bouton reinit save et config)
   */
  private ShowError(_iMessage: string, _iLockDiv: boolean | null = true, _iSetDisableChanges: boolean | null = true) {
    this.ShowInfoError(_iMessage);
    if (_iLockDiv != null)
      this._lockDivsModale = _iLockDiv;
    if (_iSetDisableChanges != null)
      this._disableChanges = _iSetDisableChanges;

  }

  //#region Event bottom buttons

  /**réinitialise du grid et selection  produit depuis données initiales */
  public onReinitButtonClick(event: any) {

    this._previsOrderFarmerPrevis = new farmerPrevisOrdersPrecosDatas(this._memPrevisOrderFarmerPrevis, false);

    let foundedSrc = this._memRefMinFertilizers.GetByFertilizerCode(this._previsOrderFarmerPrevis.SrcProduct?.code);
    this.selectedMinFertilizer = null;
    this._previousSelFertilizerCode = null;
    this.FertilizerComposTxt = null;
    if (foundedSrc) {
      this.selectedMinFertilizer = new RefMineralFertilizer(foundedSrc, null);;
      this._previousSelFertilizerCode = foundedSrc.code;
      this.FertilizerComposTxt = this.selectedMinFertilizer.FormatCompositionText();
    }
    this.BindFrontOrdersPrevisExchangeData(this._previsOrderFarmerPrevis, true);
  }

  //sur event click suivant
  public onSaveButtonClick(event: any) {
    let emptyDoses = this._previsOrderFarmerPrevis.ExistAllDosesMicrosEmpty();
    
    if (emptyDoses !== null && emptyDoses?.length > 0) {
      let msg = "Vous disposez de " + emptyDoses.length + " intervention(s) personnalisée(s) sans aucune dose dans ses micro-parcelles : "
      msg += " " + emptyDoses.join(', ') + ". "
      msg += " " + "Voulez vous poursuivre ? ";
      this._modaleService.confirm("Interventions personnalisées sans doses", msg, () => {
        this.SavingCurrentPrevisChanges(true);
      }, () => {
        return;
      }
        , { 'ok': 'Enregistrer', 'ko': 'annuler' });
    } else {
      this.SavingCurrentPrevisChanges(true);
    }

  }

  /**
   * Enregistrement en bd pour les prévis du current
   * SI il dispose de modification
   * @param _iIsUpdateMode true:enregistrerment de modification, false:suppression de prévis
   */
  private SavingCurrentPrevisChanges(_iIsUpdateMode: boolean) {
    this.ShowLoaderSaveWaiting();
    if (this._previsOrderFarmerPrevis?.Orders?.length) {
      if ((_iIsUpdateMode && this._previsOrderFarmerPrevis.IncludePrevisStatusToUpdate()) || (!_iIsUpdateMode && this._previsOrderFarmerPrevis.IncludePrevisStatusToDelete())) {
        this.IsLockedModale = true;
        this._farmerPreviService.SaveFarmerPrevisDatas(this._previsOrderFarmerPrevis).subscribe(
          (datas: any) => {
            if (datas && datas.Orders) {
              let updated = new farmerPrevisOrdersPrecosDatas(datas, false);
              let resumes = new farmerPrevisSaveChangesResume(updated, _iIsUpdateMode);
              if (resumes.Exists()) {

                if (resumes.GetFailedPrevis()?.length > 0) {
                  console.error('Failed on resume save results', resumes.GetFailedPrevis(), resumes);
                  if (_iIsUpdateMode) {
                    this.ShowInfoError("Une erreur est survenue à l'enregistrement.");
                  } else {
                    this.ShowInfoError("Une erreur est survenue à la suppression.");
                  }

                } else {

                  this.EmitReloadingMapOrder(resumes);
                }
              }
            }

            this.ChangeStatusLoading(false);
            this.IsLockedModale = false;
          },
          (error: any) => {

            this.ShowInfoError("Une erreur est survenue durant l'enregistrement.");
            console.error('Exception saving previs', error)
            this.ChangeStatusLoading(false);
            this.IsLockedModale = false;

          }
        )
      } else {
        this.ChangeStatusLoading(false);
        this.IsLockedModale = false;
      }
    } else {
      this.ChangeStatusLoading(false);
      this.IsLockedModale = false;
    }
  }

  public onDeleteFarmerPrevisClick(event: any) {
    if (this._memPrevisOrderFarmerPrevis && this._memPrevisOrderFarmerPrevis.GetInterType() == eTechInterType.FarmerInterPrevis) {
      // on est bien sur une modfi de previs existante
      this._modaleService.confirm("Suppression", "Êtes-vous sûr de vouloir supprimer cette intervention personnalisée ?",
        () => {
          //si oui on supprime et on recharge

          this.ShowLoaderDeletingWaiting();
          ///Attributon des status de suppression aux prévis
          let existanteDeleted = this._previsOrderFarmerPrevis.ApplyChanges(new ChangesFarmerPlotPrevis(null), ePrevisChanges.GLOBAL_DELETE_PREVIS);
          if (existanteDeleted > 0) {
            this.SavingCurrentPrevisChanges(false);
            this.HidLoader();
          } else {
            this.HidLoader();
          }
        },
        () => {
          return;
        }
        , { 'ok': 'Supprimer', 'ko': 'annuler' }
      );

    }
  }

  /**
   * Emet le rechargement des donnée
   * @param _iResumes résumé des previs status
   */
  private EmitReloadingMapOrder(_iResumes: farmerPrevisSaveChangesResume) {
    this.Clear();
    this._eventEmitterService.emitReloadingFarmerPrevis(_iResumes);
    this.close();
  }

  //sur event fermeture
  public onCloseButtonClick() {
    this.ClearAndClose();
  }

  /**Les bouton config-reinitialisation et sauvegarde sont rendu indispo */
  private SetDisableChanges() {
    this._disableChanges = true;
  }
  //#endregion
}


