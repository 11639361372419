import { farmerPrevisOrderPreco, farmerPrevisOrderSector, eInterPrevisSavingStatus, ePrevisStatus, InterPrevi, farmerPrevisOrdersPrecosDatas, CultivationPlot } from "../index";;

/**
 * Class resumant les demandes de création modification suppression de farmer prévis
 * avec le status lié d'enregistrement
 */
export class farmerPrevisSaveChangesResume {
    //#region properties
    //id unique autogénéré
    private _uniqueId: number = 0;

    private _results: farmerPrevisSaveChangeResume[] = [];

    /**true on est en mode creation (d'une prévis initialisé) ou modif, false en mode suppression de prévis */
    private _isUpdateMode: boolean = true;

    //identifiants des orders
    private _ordersIds: string[] = [];

    //identifiants des exploitations concernées
    private _farmIds: number[] = [];

    /**retourne les resumés de prévis avec modification */
    public get Changes(): farmerPrevisSaveChangeResume[] {
        return this._results;
    }

    /**retourne les id d'order avec modification */
    public get OrdersidsChanges(): string[] {
        return this._ordersIds;
    }

    /**retourne les id farms avec modif */
    public get FarmsIds(): number[] {
        return this._farmIds;
    }

    /**id unique autogénéré */
    public get UniqueId(): number {
        return this._uniqueId;
    }

    /**Indique si il dispose de demande d'enregistrement modif creation ou suppression */
    public Exists(): boolean {
        return this._results?.length > 0;
    }

    /**Indique si il est en mode enregistrement */
    public IsUpdateMode(): boolean {
        return this._isUpdateMode == true;
    }

    /**Indique si il est en mode suppression */
    public IsDeleteMode(): boolean {
        return this._isUpdateMode == false;
    }
    //#endregion

    /**
     * Initialise la liste de resultat des modif demandées
     * @param _ifarmerPrevisOrders les données
     * @param _iIsModeUpdatePrevis true modif ou creation de previs, false:suppression de previs
     */
    constructor(_ifarmerPrevisOrders: farmerPrevisOrdersPrecosDatas, _iIsModeUpdatePrevis: boolean) {

        this._uniqueId = Date.now() + Math.random()
        this._results = [];
        this._ordersIds = [];
        this._isUpdateMode = _iIsModeUpdatePrevis;
        if (_ifarmerPrevisOrders.Orders?.length) {
            if (_ifarmerPrevisOrders.Orders?.length)
                _ifarmerPrevisOrders.Orders.forEach((fOrder: farmerPrevisOrderPreco) => {
                    if (fOrder.Sectors?.length > 0) {

                        fOrder.Sectors.forEach((fSec: farmerPrevisOrderSector) => {
                            if (fSec.Plots?.length > 0) {
                                fSec.Plots.forEach((fPlot: CultivationPlot) => {
                                    if (fPlot.ExistInterPrevis()) {
                                        fPlot.InterPrevis.forEach((fPrevis: InterPrevi) => {
                                            if (this.AddIfChanges(fPrevis, fPlot, fSec.Id, fOrder.OrderId) == true) {

                                                if (this._ordersIds.includes(fOrder.OrderId) == false) {
                                                    this._ordersIds.push(fOrder.OrderId)
                                                }
                                                if (this._farmIds.includes(fOrder.FarmId) == false) {
                                                    this._farmIds.push(fOrder.FarmId);
                                                }

                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
        }
    }

    /**Ajout la previs statut si une demande de modif y est présente */
    private AddIfChanges(_iPrevisStatus: InterPrevi, _iPlot: CultivationPlot, _iSectorId: number, _iOrderId: string): boolean {
        let oResumes = new farmerPrevisSaveChangeResume(_iPrevisStatus, _iPlot, _iSectorId, _iOrderId);
        if (oResumes.IncludePrevisChangeStatus()) {
            this._results.push(oResumes);
            return true;
        }

        return false;
    }

    /**
     * Retourne la liste des prévis en echec d'enregistrement
     * @returns 
     */
    public GetFailedPrevis(): farmerPrevisSaveChangeResume[] {
        return this._results.filter(r => r.MatchingStatus() == false);
    }

    /**
    * Retourne la liste des prévis parcelle ayant été supprimés
    * @returns 
    */
    public GetDeletedPrevis(): farmerPrevisSaveChangeResume[] {
        return this._results.filter(r => r.MatchOnDeleting() == false);
    }
}

/**
 * Class de résumé d'enregistrerment de farmer previs en back
 */
export class farmerPrevisSaveChangeResume {

    //#region properties
    private _previsId: number;
    //statut de demande de changement sur prévis
    private _reqStatusPrevis: ePrevisStatus;
    //statut de réponse
    private _saveStatusPrevis?: eInterPrevisSavingStatus;
    //nom de la parcelle
    private _plotName: string;
    //dose à la parcelle
    private _plotDose: string;
    //id parcelle
    private _plotId: number;
    //id ilot
    private _sectorId: number;
    //id order
    private _orderId: string;
    //id inter
    private _InterId: number;

    public get OrderId(): string {
        return this._orderId;
    }

    public get SectorId(): number {
        return this._sectorId;
    }

    public get PlotId(): number {
        return this._plotId;
    }

    public get InterId(): number {
        return this._InterId;
    }
    //#endregion

    /**
     * init
     */
    constructor(_interPrevis: InterPrevi, _iPlot: CultivationPlot, _iSectorId: number, _iOrderId: string) {
        this._plotName = _iPlot.NameCultivationPlot + " " + _iPlot.Surface + "ha";
        this._plotId = _iPlot.Id;
        this._previsId = _interPrevis.IdIntervention;
        this._reqStatusPrevis = _interPrevis.Status;
        this._saveStatusPrevis = _interPrevis.SavingStatus;
        this._plotDose = _interPrevis.Fertilizer?.Dose + " " + _interPrevis.Fertilizer?.Unit + "/ha";
        this._sectorId = _iSectorId;
        this._orderId = _iOrderId;
        this._InterId = _interPrevis.IdIntervention;
    }

    /**
    * Indique que le statut de changement sur la prévis est présent (creation modi ou suppression)
    * @returns boolean
    */
    public IncludePrevisChangeStatus(): boolean {
        return this._reqStatusPrevis == ePrevisStatus.Updating || this._reqStatusPrevis == ePrevisStatus.Deleting;
    }


    /**
     * Indique que le statut en réponse à celui demandé correspond
     * @returns boolean
     */
    public MatchingStatus(): boolean {
        return this._saveStatusPrevis !== null &&
            (this.MatchOnUpdating() || this.MatchOnDeleting())
    }

    /**
   * Indique que le statut d'enregistrement (creation ou modification) correspond
   * @returns boolean
   */
    private MatchOnUpdating(): boolean {
        return this._reqStatusPrevis == ePrevisStatus.Updating && (this._saveStatusPrevis == eInterPrevisSavingStatus.Created || this._saveStatusPrevis == eInterPrevisSavingStatus.OverWrited || this._saveStatusPrevis == eInterPrevisSavingStatus.Updated)
    }

    /**
   * Indique que le statut de suppression correspond
   * @returns boolean
   */
    public MatchOnDeleting(): boolean {
        return this._reqStatusPrevis == ePrevisStatus.Deleting && (this._saveStatusPrevis == eInterPrevisSavingStatus.Deleted)
    }


    /**format un texte comparaison statuts */
    public FormatStatus(): string {
        return this._reqStatusPrevis.toString() + " <> " + (this._saveStatusPrevis ? this._saveStatusPrevis.toLocaleString() : "NO Save Status");
    }
}

