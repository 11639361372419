import { RecommendationView } from "../beapi/recommendationView";

export class Productrequest {
    Code: string;
    Date: Date;
    // la clés de l unité vers laquelle convertir l unité du produit
    ChangeUnitCode :string=null;
    //type de la préconisation
    Type:eExportType= null;
  
    /**
     * Init
     * @param _Code code produit
     * @param _iPreco la preconisation
     * @param _changeUnitCode le code de l'unité à exporter
     */
    constructor(_Code:string,_iPreco:RecommendationView, _changeUnitCode:string=null ) {
        this.Code = _Code;
        this.Date= new Date(_iPreco.Date);
        this.ChangeUnitCode = _changeUnitCode;
        this.setExportType(_iPreco);
       
    }

    /**Attribution du type de preco selectionné */
    private setExportType(_iPreco:RecommendationView){

        if(_iPreco){
            if(_iPreco.IsFarmerPrevis == true){
                this.Type = _iPreco.IsPilotSPPreco ? eExportType.PrevisPrecSP : eExportType.PrevisPrecAd
            }else{
                if(_iPreco.IsInterModePreco){
                    this.Type = eExportType.InterMPreco;
                }else if(_iPreco.IsPilotSPPreco){
                    this.Type = eExportType.PilotSP;
                }else if(_iPreco.IsFumur){
                    this.Type = eExportType.Preco;
                }else{
                    this.Type= eExportType.Modulation
                }
            }
        } else {
            this.Type = eExportType.Undefined;
        }
       
    }


}

//type de donnée de l'export produit
export enum eExportType{

    Undefined=0,
    //conseil Preco
    Preco = 1,
    //inter mode preco
    InterMPreco = 2,
    //Preco Pilotage SP
    PilotSP=3,
    //Preco Modulation
    Modulation=4,
    //farmer intervention previsionnelle pilot sp (source preco)
    PrevisPrecSP=5,
    //farmer intervention previsionnelle Conseil Preco (source preco)
    PrevisPrecAd=6,

}