import { FarmerPrevisService } from "../../_services";
import { RefMineralFertilizer } from "../referentials/RefMineralFertilizer";
import { Dictionary } from "../share/Dictionary";
import { grdFarmPlotPreviDatas } from "./grdFarmerPrevisDatas";

/**
 * class étendue de grdFarmPlotPreviDatas
 * avec les modification globales à traiter sur les prévis farmer
 */
export class ChangesFarmerPlotPrevis extends grdFarmPlotPreviDatas {

    // #region Properties
    //séparateur des id unique de parcelle prévis
    private _separator: string = '__';
    //le fertilisant selectionné à attribuer
    private _changedRefMineralFertilizer: RefMineralFertilizer = null;
    //le fertilisant avant sélection
    private _previousRefMineralFertilizer: RefMineralFertilizer = null;
    //sur changement de produit la compo selectionné du fertilisant à attribuer
    private _composCodeSelected: string = null;
    //liste des identifiants de parcelles previs à traiter cas d'une appli de masse
    private _previsSelected: string[] = [];

    //le % de calcul de l'utilisateur
    private _purcentUserCompute: number;

    //en Application de quantité de masse la quantité par parcelle à appliquer au ratio de leurs surfaces
    private _plotApplyQteRatios: Dictionary;

    /**sur un changement de produit retourne ici le produit  */
    public GetRefPrevisFertilizer(): RefMineralFertilizer {
        return this._previousRefMineralFertilizer;
    }
    /**sur un un changement produit avant changement  */
    public GetNewPrevisFertilizer(): RefMineralFertilizer {
        return this._changedRefMineralFertilizer;
    }

    /**sur un un changement produit retourne le code composition pour moduler  */
    public GetNewFertilizerCodeCompo(): string {
        return this._composCodeSelected;
    }

    /**
    * Sur une application de masse retourne la quantité à la parcelle calculé selon le ratio
    * @param _iPrevisIdent identifiant de la parcelle previs
    * @returns number
    */
    public GetApMassQteRatioPlot(_iPrevisIdent: string): number {
        return Number(this._plotApplyQteRatios.GetByKey(_iPrevisIdent)) ?? 0
    }

    /**
    * Pour un recalcul par % , retourne le % configuré et selon si negatif ou non
    * @param _iPurcent 
    */
    public GetUserConfigPurcentCompute() {
        return this._purcentUserCompute;
    }

    /**
     * Si on gère par selection retourne l'id de la prévis SI présent dans la selection
     * @param _iPlotId d parcelle a contrôler sa selection si oui retourne id previs
     * @returns 
     */
    public GetSelectedPlotUniquePrevisId(_iPlotId: number): number | null {
        let selected = this.GetSelectedPlotPrevis(_iPlotId);
        if (selected) {
            return Number(selected.split(this._separator)[1]);
        } else {
            return null;
        }
    }

    /**
     * Indique que l'on dispose de sélection
     * @returns 
     */
    public IncludePrevisSelection(): boolean {
        return this._previsSelected?.length > 0;
    }

    /**indique selon l'id de la parcelle si elle fait partie des selectionnées */
    public IsSelectedPlotPrevis(_iPlotId: number): boolean {
        return this.GetSelectedPlotPrevis(_iPlotId) !== null;
    }

    /**
    * Si on gère par selection retourne l'id de la prévis SI présent dans la selection
    * @param _iPlotId d parcelle a contrôler sa selection si oui retourne id previs
    * @returns UniquePrevisId
    */
    public GetSelectedPlotPrevis(_iPlotId: number): string | null {
        return this._previsSelected.find(e => e.startsWith(_iPlotId + this._separator)) ?? null;
    }

    //#endregion

    public constructor(_iGrdFarmPlotPreviDatas: grdFarmPlotPreviDatas) {
        super(null, null, null, null, false, null, null);
        this._plotApplyQteRatios = new Dictionary();
        if (_iGrdFarmPlotPreviDatas)
            this.Init(_iGrdFarmPlotPreviDatas);
    }

    /**init */
    private Init(_iGrdFarmPlotPreviDatas: grdFarmPlotPreviDatas) {

        this.PrecoProduct = _iGrdFarmPlotPreviDatas.PrecoProduct;
        this.PrecoId = _iGrdFarmPlotPreviDatas.PrecoId;
        this.PrecoProductCode = _iGrdFarmPlotPreviDatas.PrecoProductCode;
        this.PrecoProductUnit = _iGrdFarmPlotPreviDatas.PrecoProductUnit;
        this.CultivationName = _iGrdFarmPlotPreviDatas.CultivationName;

        this.OrderId = _iGrdFarmPlotPreviDatas.OrderId;
        this.PlotArea = _iGrdFarmPlotPreviDatas.PlotArea;
        this.MicrosAreas = _iGrdFarmPlotPreviDatas.MicrosAreas;
        this.IdCultivationPlot = _iGrdFarmPlotPreviDatas.IdCultivationPlot;

        this.PrevisDate = _iGrdFarmPlotPreviDatas.PrevisDate;
        this.PrevisId = _iGrdFarmPlotPreviDatas.PrevisId;
        this.UniquePrevisId = _iGrdFarmPlotPreviDatas.UniquePrevisId;
        this.PrevisProduct = _iGrdFarmPlotPreviDatas.PrevisProduct;
        this.PrevisProductCode = _iGrdFarmPlotPreviDatas.PrevisProductCode;
        this.PrevisProductUnit = _iGrdFarmPlotPreviDatas.PrevisProductUnit;
        this.PrevisQte = _iGrdFarmPlotPreviDatas.PrevisQte;
        this.PrevisDose = _iGrdFarmPlotPreviDatas.PrevisDose;
    }

    /**formatage retourné d'un id, identifiant la parcelle et la prévis */
    private FormatPrevisUniqueId(_iGrdFarmPlotPrevis: grdFarmPlotPreviDatas): string {
        return _iGrdFarmPlotPrevis.IdCultivationPlot + this._separator + _iGrdFarmPlotPrevis.UniquePrevisId;
    }

    /**
 * Pour une application de masse de quantité
 * @param _iPrevisSelected grid previs selectionnées
 * @param _iAppMassQte quantité de masse à appliquer 
 * @param _iSelectedQuantity quantité totale calculée des previs sélectionnées
 */
    public SetAppMassPrevisQte(_iPrevisSelected: grdFarmPlotPreviDatas[], _iAppMassQte: number, _iSelectedQuantity:number) {
       // quantité d’origine de la préco * nouvelle quantité totale saisie / quantité totale de la sélection actuelle
        this._previsSelected = _iPrevisSelected.map(r => this.FormatPrevisUniqueId(r));
        this.PrevisQte = _iAppMassQte;
       
        let multiCoeff = _iSelectedQuantity > 0 ? _iAppMassQte / _iSelectedQuantity : 0;
        this._plotApplyQteRatios = new Dictionary();
        _iPrevisSelected.forEach(fPrevis => {
            this._plotApplyQteRatios.Add(this.FormatPrevisUniqueId(fPrevis), fPrevis.PrevisQte * multiCoeff);
        })
    }

    /**
     * Pour un recalcul par % attribution du pourcentage configuré
     * @param _iPurcent 
     * @param _isNegative 
     * @param _iPrevisSelected si non null (pour le global), traite les previs selectionné utilisateur
     */
    public SetUserConfigPurcentCompute(_iPurcent: number, _isNegative: boolean, _iPrevisSelected: grdFarmPlotPreviDatas[] | null) {

        if (!_iPurcent || _iPurcent == 0) {
            this._purcentUserCompute = FarmerPrevisService.CONFIG_STEP_PRCT_DOSAGE_DEFAUT;
        } else {
            this._purcentUserCompute = _iPurcent;
        }
        if (_isNegative) {
            this._purcentUserCompute *= -1;
        }

        if (_iPrevisSelected !== null && _iPrevisSelected.length > 0) {
            this._previsSelected = _iPrevisSelected.map(r => this.FormatPrevisUniqueId(r));
        }
    }

    /**
    * on remplace le fertilisant
    * pour action de remplacement de produit
    * @param _iNewRefMineralFertilizer le nouveau produit à appliquer
    * @param _iCurrentFertilizer le produit actuel à remplacer
    * @param _iModulateKeyCompos la clés de la composition sélectionnée
    */
    public SetNewFertilizer(_iNewRefMineralFertilizer: RefMineralFertilizer, _iCurrentFertilizer: RefMineralFertilizer, _iModulateKeyCompos: string) {
        this._changedRefMineralFertilizer = _iNewRefMineralFertilizer;
        this._previousRefMineralFertilizer = _iCurrentFertilizer;
        this._composCodeSelected = _iModulateKeyCompos;
    }

    //map vers donnée pour le grid
    public MapGrdFarmPlotPreviDatas(): grdFarmPlotPreviDatas {
        return this;
    }

    /**
     * remplace les donénes de previs
     * @param _oGrdFarmPlotPreviDatas données de grid dont on remplace ses valeur par celle-ci
     * @returns grdFarmPlotPreviDatas
     */
    public ReplacePrevisDatas(_oGrdFarmPlotPreviDatas: grdFarmPlotPreviDatas): grdFarmPlotPreviDatas {
        _oGrdFarmPlotPreviDatas.PrevisDate = this.PrevisDate;
        _oGrdFarmPlotPreviDatas.PrevisProduct = this.PrevisProduct;
        _oGrdFarmPlotPreviDatas.PrevisProductCode = this.PrevisProductCode;
        _oGrdFarmPlotPreviDatas.PrevisProductUnit = this.PrevisProductUnit;
        _oGrdFarmPlotPreviDatas.PrevisQte = this.PrevisQte;
        _oGrdFarmPlotPreviDatas.PrevisDose = this.PrevisDose;
        return _oGrdFarmPlotPreviDatas;
    }


    /**
  * Retourne si la modification est une modification unique à une seul previs
  * @param _iePrevisChanges changement 
  * @returns boolean
  */
    public static IsUniquePrevisChange(_iePrevisChanges: ePrevisChanges): boolean {
        return [ePrevisChanges.PREVIS_QTE, ePrevisChanges.PREVIS_CHANGE_BY_PURCENT, ePrevisChanges.PREVIS_DOSE].includes(_iePrevisChanges);
    }
}


/**
 * Les ype de modifications sur le previsionnelle farmer
 */
export enum ePrevisChanges {
    NONE = 0,

    /**remplacement individuel de la dose de la previs farmer*/
    PREVIS_DOSE = 1,
    /**remplacement individuel de la quantité de la previs farmer*/
    PREVIS_QTE = 2,
    /**remplacement individuel selon un % configuré la quantité*/
    PREVIS_CHANGE_BY_PURCENT = 3,

    /**remplacement, pour les previs sélectionnés, selon un % configuré la quantité*/
    APP_MASS_CHANGE_BY_PURCENT = 6,

    /**remplacement, pour les previs sélectionnés, des quantités parcelle au pro rata de leur surface*/
    APP_MASS_PREVIS_QTE = 8,

    /**remplacement de la date  pour l'ensemble des prévis présentes*/
    GLOBAL_DATE = 9,
    /**remplacement du produit  pour l'ensemble des prévis présentes*/
    GLOBAL_FERTILIZER = 10,
    /**attribution du statut de suppression de previs (si dispose d'un id_intervention) pour l'ensemble des prévis présentes*/
    GLOBAL_DELETE_PREVIS = 11,

    /**attribution du statut update de previs pour l'ensemble des prévis présentes pour l enregistrement de celui ci de par sa creation*/
    CHANGE_TO_CREATE_PREVIS = 12,
}