export class Fertilizer {

    IdFertilizern: number;
    Name: string;
    Dose: number;
    Unit: string;
    N: number;
    P2O5: number;
    K2O: number;
    MgO: number;
    SO3: number;
    CaO: number;
    Na2O: number;
    code : string;

//   IdEdiInputNature: string;
//   CodeEdiInputNature: string;
//   NameEdiInputNature: string;
//   IdReferenceOrganicManure: number;
//   CodeReferenceOrganicManure: string;
//   NormalizedFertilizer: boolean;

    constructor(ferti:Fertilizer){
        if(ferti){
            this.IdFertilizern = ferti.IdFertilizern;
            this.Name = ferti.Name;
            this.Dose = ferti.Dose;
            this.Unit = ferti.Unit;
            this.N = ferti.N;
            this.P2O5 = ferti.P2O5;
            this.K2O = ferti.K2O;
            this.MgO = ferti.MgO;
            this.SO3 = ferti.SO3;
            this.CaO = ferti.CaO;
            this.Na2O = ferti.Na2O;
            this.code = ferti.code;
        }
    }
}