import { SharedMapService } from './../../_services/map.service';
import { LegendsConfig, LegendService } from './../../_services/legend.service';
import { Component, OnInit, OnDestroy, Input, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Login, Farm, User, eUserRole } from '../../_models/index';
import { FarmServices, AuthenticationService, GoogleAnalyticsService, FarmerPrevisService, EventEmitterService, EventActionModalLegend } from '../../_services/index';
import { itemValue, menuItem, menuType } from '../../modules/jarvis-ui/_models';
import { JarvisUiService } from '../../modules/jarvis-ui/jarvis-ui.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../framework/auth/components/loading/loading.service';
import { isNullOrUndefined } from '../../_helpers';
import { farmerPrevisSaveChangeResume, farmerPrevisSaveChangesResume } from '../../_models/farmerPrevis/farmerPrevisSaveChangesResume';

@Component({
    selector: 'smag-dashboard',
    styleUrls: ['./dashboard.component.scss', './dashboard.component.responsive.scss'],
    templateUrl: './dashboard.component.html',
})
//
// @NgModule({
//     schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
// })

export class DashboardComponent implements OnInit, OnDestroy {


    //menu properties for app-jarvis-ui
    public menus: menuItem[] = [];
    public imageUrl = "./../../assets/monbeapi.png";
    public imageConsole = "icon-tractor";
    public imagePDF = "icon-note";

    private menukey_farm = "farm";
    private menukey_campaign = "campaign";
    private menukey_cultivation = "cultivation";
    private menukey_action = "action";
    public currentUser: Login;

    public user: User;
    /*Ensemble des Cultures sélectionnées par le user MasterPage */
    public SelectedCultivations: menuItem;
    //properties for console window
    public consoleDialogOpened: boolean = false;
    public cultivationMenu: menuItem;
    //properties for export window
    public exportDialogOpened: boolean = false;
    ///Ensemble des types de prestations
    public exportPrestationTypes: String[];

    public showSwitchAccountOpened: boolean = false;

    public loadAPI: Promise<any>;
    public CoopName: string;
    private subsFilteredFarms: any;
    private _shownExploitations: { [userId: string]: Farm[]; } = {};

    /**id de la derniere demande de modif de previs */
    private _lastIdPrevisChangeProgress:number=null;

    public get shownExploitations(): { [userId: string]: Farm[]; } {
        if (this._shownExploitations == null)
            this._shownExploitations = {}

        return this._shownExploitations;
    }
    public set shownExploitations(value: { [userId: string]: Farm[]; }) {
        this._shownExploitations = value;
    }


    public get currentFarms(): Farm[] {
        if (this.shownExploitations != null)
            return this.shownExploitations[this.currentUser.userid];
        else
            return null;
    }

    public get currentOrders(): Farm[] {
        return this._farmService.FilteredOrders;
    }

    public get ModPrecosTypes(): any[] {
        return this._legendService.ModPrecosTypes;
    }

    public get IntersModePrecoTypes(): any[] {
        return this._legendService.IntersModePreco;
    }
    /** retourne liste des PDF Diagnostic (Annuels- Pilot LAI) */
    public get DiagnosticPrestTypes(): any[] {
        return this._legendService.DiagnosticPrestTypes;
    }

    /**Liste des modes d'impression supplémentaire formatés */
    public get ExtraPrintPdfModels(): any[] {
        return this._legendService.ExtrasPrintsModels;
    }

    constructor(private _farmService: FarmServices, private _jarvisMenuService: JarvisUiService,
        private _authService: AuthenticationService,
        private router: Router,
        private _loadingService: LoadingService,
        private _legendService: LegendService,
        private _mapService: SharedMapService,
        private googleService: GoogleAnalyticsService,
        private _farmerPreviService: FarmerPrevisService,
        private _eventEmitterService: EventEmitterService
    ) {
        this.menus = this._jarvisMenuService.Menus
    }

    ngOnDestroy(): void {
        this._jarvisMenuService.Clear();
        this.shownExploitations = {};

        this.exportPrestationTypes = [];
    }

    ngOnInit(): void {
        this.CleanSelectedCulturals();
        this.ShowLoaderWaiting()
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.shownExploitations = JSON.parse(localStorage.getItem('farms'));
        const farmMenu: menuItem = this._jarvisMenuService.AddMenu(this.menukey_farm, 'Exploitations', menuType.MultiSelectable);
        const campaignMenu: menuItem = this._jarvisMenuService.AddMenu(this.menukey_campaign, 'Campagnes', menuType.Mono);
        this.cultivationMenu = this._jarvisMenuService.AddMenu(this.menukey_cultivation, 'Cultures', menuType.MultiSelectable);
        const actionMenu: menuItem = this._jarvisMenuService.AddMenu(this.menukey_action, 'Export', menuType.Action);
        this._jarvisMenuService.AddItem(this.menukey_action, null, "", "PDF", "PDF", false, this.imagePDF, "PDF");
        this._jarvisMenuService.AddItem(this.menukey_action, null, "", "CONSOLE", "Console", false, this.imageConsole, "Modulation");

        this._farmService.getuserinfo().subscribe(user => {
            this.user = user;

            this.googleService.InitService(this.user);
        })



        let farmers = this.currentFarms;
        farmers = null;
        if (farmers == null) {

            this._farmService.getFarms(true).subscribe(farmers => {

                this.Initialize(farmers);
                this.LogOnAnalytics();
                if (isNullOrUndefined(farmers) || farmers.length == 0) {
                    this.NoOrderLoaded();
                    this.HidLoader();
                }
            },
                error => {
                    this.HidLoader();
                    this.NoOrderLoaded(true);
                })
                ;

        }
        else {
            this.Initialize(farmers);
            //Rafraichissement de fond pour les exploitations :
            this._farmService.getFarms(true).subscribe(_farms => {

                let newFarmsId = (_farms.map(({ IdExploitation }) => Number(IdExploitation)).sort((n1, n2) => n1 - n2));
                let oldFarmsId = (farmers.map(({ IdExploitation }) => Number(IdExploitation)).sort((n1, n2) => n1 - n2));

                let equalsId: boolean = JSON.stringify(newFarmsId) === JSON.stringify(oldFarmsId);

                if (!equalsId) {
                    this.Initialize(_farms);
                    this._loadingService.show('Une mise à jour des exploitations est en cours ...');
                }
            },
                error => {
                    this.HidLoader();
                },
                () => {
                    this.HidLoader();
                }
            );
        }
        this.HidLoader();
    }

    private Initialize(farmers: Farm[]) {
        /*Nombre Max d exploitations pré selectionnable */
        this.ShowLoaderWaiting();
        let _maxFarmPreSelected: number = this.GetNumberFarmsPreselected(farmers);
        this.CleanSelectedCulturals();
        var farmMenu: menuItem = this._jarvisMenuService.Menus[0];
        if (!farmers) {
            this.HidLoader();
            return;
        }
        this.ClearMenuItems(this.menukey_farm);
        this.ClearMenuItems(this.menukey_campaign);
        farmers.forEach(farmElement => {
            if (farmMenu.GroupItems.filter(g => g.Id == farmElement.CoopSiretNumber).length == 0)
                this._jarvisMenuService.AddGroup(this.menukey_farm, farmElement.CoopSiretNumber, farmElement.CoopName);
        });
        let _CntPreselected = 0;
        farmers.forEach(farmElement => {
            let _blPreselectable = farmElement.CoopSiretNumber == farmMenu.GroupItems[0].Id;
            if (_blPreselectable) {
                _CntPreselected += 1;
                if (_CntPreselected > _maxFarmPreSelected)
                    _blPreselectable = false;
            }
            this._jarvisMenuService.AddItem(this.menukey_farm, farmElement, farmElement.CoopSiretNumber, farmElement.IdExploitation, farmElement.NameExploitation, _blPreselectable);
        });
        this._jarvisMenuService.Order(this.menukey_farm);
        var exploitationSelected = this.GetSelectedFarmIds();

        this._farmService.getCampagnes(exploitationSelected).subscribe(campagnes => {
            campagnes.forEach((campaign, index) => {
                this._jarvisMenuService.AddItem(this.menukey_campaign, campaign, "", campaign.toString(), campaign.toString(), index == 0);
            });
            this._jarvisMenuService.Order(this.menukey_campaign);
            if (this._jarvisMenuService.getSelectedItems(this.menukey_campaign).length > 0) {
                this._farmService.GetFarmByCultivationsFiltered(exploitationSelected, Number(this._jarvisMenuService.getSelectedItems(this.menukey_campaign)[0].Id), null).subscribe(farm => {
                    this.shownExploitations[this.currentUser.userid] = farm;
                    if (farm && farm.length)
                        this.BindingLogoCoopName(farm[0].CoopName);

                },
                    error => { this._loadingService.hide(); })
            }


            this.ReloadFieldCultivation(exploitationSelected, this.GetSelectedCampagne());
            this.AfterChangingMenus();
        },
            error => { this._loadingService.hide(); }
            , () => {
                this.HidLoader();
            }
        )

    }

    private GetNumberFarmsPreselected(farms: Farm[]): number {
        let preselected = 0;
        //Ne pas sélectionner d'exploitation si le nombre d'exploitation est important (>5)
        if (this.user != null && farms && farms.length > 0) {
            if (this.user.hasRole(eUserRole.AGRICULTEUR)) {
                preselected = 3;
            }
        }
        return preselected;
    }

    //Dialog Event Region
    public ExportDialogClosed(closed: boolean) {
        this.exportDialogOpened = closed;

    }
    public ConsoleDialogClosed(closed: boolean) {
        this.consoleDialogOpened = closed;
    }


    /*Clic Export CONSOLE ou PDF */
    public ActionClick(itemAction: itemValue) {
        if (this.IsFarmSelected()) {
            if (itemAction.Id === "CONSOLE") {
                this.consoleDialogOpened = true;
            }

            else if (itemAction.Id === "PDF") {
                this.exportDialogOpened = true;

                this.exportPrestationTypes = [];
                if (this.currentOrders != null && this.currentOrders.length > 0) {     //On envoit les PrestationsTypes Distinct des Orders donc filtrera choix au client
                    this.currentOrders.forEach(r => {
                        if (r.PrestationType != null && this.exportPrestationTypes.indexOf(r.PrestationType.toUpperCase()) == -1 && r.IsDiagPilotSPLAI() == false) { this.exportPrestationTypes.push(r.PrestationType.toUpperCase()) }
                    });
                }
            }
        }
    }


    public SelectionChanged(menuChanged: menuItem) {

        this.CleanSelectedCulturals();
        if (menuChanged.Code == this.menukey_farm) {
            this.RefreshLogoCoopName();
            var millisecondsToWait = 1000;
            // @ts-ignore
            setTimeout(this.refreshCulturesByFarms(), millisecondsToWait);


        }
        else if (menuChanged.Code == this.menukey_campaign) {

            this.refreshCulturesByFarmsAndCampagne()
        }
        else if (menuChanged.Code == this.menukey_cultivation) {
            this.BindSelectedCulturals();
        }
        if (this.subsFilteredFarms && this.subsFilteredFarms.unsubscribe)
            this.subsFilteredFarms.unsubscribe();

        let blSelectionDone: boolean = true;
        if (this._jarvisMenuService.getSelectedItems(this.menukey_campaign).length == 0 || !this.IsFarmSelected()) {
            //  Error("Veuillez renseigner les champs nécessaire.")
            //  return;

            blSelectionDone = false;
            this._farmService.FilteredOrders = [];

            this.subsFilteredFarms = this._farmService.GetEmptyFarmArray(

            ).subscribe(
                result => {

                    this.shownExploitations[this.currentUser.userid] = result;

                });
        }


        if (blSelectionDone) {
            this.ShowLoaderWaiting();
            this.subsFilteredFarms = this._farmService.GetFarmByCultivationsFiltered(

                this._jarvisMenuService.getSelectedItems(this.menukey_farm).map(({ Id }) => Id),
                Number(this._jarvisMenuService.getSelectedItems(this.menukey_campaign)[0].Id),
                this._jarvisMenuService.getSelectedModels(this.menukey_cultivation)
            ).subscribe(
                result => {

                    this.shownExploitations[this.currentUser.userid] = result;
                    this.HidLoader();
                },
                error => { this.HidLoader(); }
                ,
                () => {
                    this.HidLoader();
                }
            );
        }



    }

    // After view init the map target can be set!
    async ngAfterViewInit() {
        this.ShowLoaderWaiting();
    }

    public logout() {
        this._legendService.clean();
        this._mapService.clean();
        this._authService.logout();
        localStorage.removeItem("currentUser");
        this.router.navigate(['/auth/login']);
    }

    refreshCulturesByFarms() {
        this.ShowLoaderWaiting();
        var exploitationSelected = this.GetSelectedFarmIds();
        this.ClearMenuItems(this.menukey_campaign);
        this.ClearMenuItems(this.menukey_cultivation);
        if (exploitationSelected != null && exploitationSelected.length > 0) {

            this._farmService.getCampagnes(exploitationSelected).subscribe(campagnes => {
                campagnes.forEach((campaign, index) => {
                    this._jarvisMenuService.AddItem(this.menukey_campaign, campaign, "", campaign.toString(), campaign.toString(), index == 0)
                })
                this.ReloadFieldCultivation(exploitationSelected, this.GetSelectedCampagne());
                this.subsFilteredFarms = this._farmService.GetFarmByCultivationsFiltered(
                    this._jarvisMenuService.getSelectedItems(this.menukey_farm).map(({ Id }) => Id),
                    this.GetSelectedCampagne(),
                    this._jarvisMenuService.getSelectedModels(this.menukey_cultivation)
                ).subscribe(
                    result => {

                        this.shownExploitations[this.currentUser.userid] = result;

                    }
                    ,
                    error => { this.HidLoader(); },
                    () => {
                        this.HidLoader();
                    }
                )

                    ;
                this._jarvisMenuService.Order(this.menukey_campaign);
                this.AfterChangingMenus();
            }
            );
        }
        else {
            /*Aucune exploitation selectionnée */
            this.HidLoader();
        }

    }

    public refreshCulturesByFarmsAndCampagne() {
        this.ShowLoaderWaiting();
        var exploitationSelected = this.GetSelectedFarmIds();
        var campagneSelected = this.GetSelectedCampagne();
        this.ReloadFieldCultivation(exploitationSelected, campagneSelected);

        this.subsFilteredFarms = this._farmService.GetFarmByCultivationsFiltered(
            this._jarvisMenuService.getSelectedItems(this.menukey_farm).map(({ Id }) => Id),
            campagneSelected,
            this._jarvisMenuService.getSelectedModels(this.menukey_cultivation)
        ).subscribe(
            result => {
                this.shownExploitations[this.currentUser.userid] = result;
            },
            error => { this.HidLoader(); },
            () => {
                this.HidLoader();
            }
        );
    }

    /**
    *On recharge les Parcelles et Cultures selon les exploitations selectionnées et la campagne
    */
    private ReloadFieldCultivation(_iFarmsSelected: any[], _iCmpSelected: number) {

        this._farmService.getCultivations(_iFarmsSelected, _iCmpSelected).subscribe(
            result => {
                this._jarvisMenuService.removeAllItems(this.menukey_cultivation);

                result.forEach(cultivation => {
                    this._jarvisMenuService.AddItem(this.menukey_cultivation, cultivation, "", cultivation.Id.toString(), cultivation.Name);
                })
                this._jarvisMenuService.Order(this.menukey_cultivation);
                this.AfterChangingMenus();
                ;
            });
    }

    /**
    *on rempli la liste des cultures sélectionnées par le User pour export console
    */
    private BindSelectedCulturals() {
        this.CleanSelectedCulturals();
        let recs: any[] = this._jarvisMenuService.getSelectedModels(this.menukey_cultivation);
        if (recs.length > 0) {
            this.SelectedCultivations.GroupItems = recs;
        }
    }
    /**
      *Suppression des Cultures User Selectionnnées
      */
    private CleanSelectedCulturals() {
        this.SelectedCultivations = new menuItem();
        this.SelectedCultivations.GroupItems = [];
    }


    /**
     *permet le retrait des éléments des menus
     */
    private ClearMenuItems(menukey: string) {
        this._jarvisMenuService.removeAllItems(menukey);
    }

    /**
      *Indique si des exploitations on été selectionnées par le user
      */
    private IsFarmSelected() {
        var Selected = this.GetSelectedFarmIds();
        return Selected != null && Selected.length > 0;
    }


    /**
    *Retourne la liste des exploitation sélectionnées dans le menu par le user
    */
    private GetSelectedFarmIds(): any[] {
        return this._jarvisMenuService.getSelectedModels(this.menukey_farm).map(({ IdExploitation }) => IdExploitation);
    }
    /**
   *Retourne la campagne selectionnée par le USER
   * NULL si aucune campagne
   */
    private GetSelectedCampagne() {
        if (this._jarvisMenuService.getSelectedItems(this.menukey_campaign).length > 0)
            return Number(this._jarvisMenuService.getSelectedItems(this.menukey_campaign).filter(Checked => Checked.Checked = true)[0].Id);
        return null;
    }

    /**
     * Rafraichissement de Logo CoopName selon selection d exploitation
     */
    private RefreshLogoCoopName() {
        let Coops = this._jarvisMenuService.getSelectedModels(this.menukey_farm).map(({ CoopName }) => CoopName);
        if (Coops && Coops.length > 0 && Coops[0] != null) {
            this.BindingLogoCoopName(Coops[0]);
        } else {
            this.BindingLogoCoopName(null);
        }

    }
    /**
     * Remplissage de la coop niveau du logo BANNER
     * @param _CoopName
     */
    private BindingLogoCoopName(_CoopName: string) {
        this.CoopName = _CoopName;
    }

    /**Event sur changement de menu */
    private AfterChangingMenus() {
        this._farmerPreviService.SetIsLastCampaign = this._jarvisMenuService.IsLastRecordSelected(this.menukey_campaign);
        //on referme la modale de duplication de préco tech
        this._eventEmitterService.emitCloseDuplicateTechPrecoDialog();
        this._eventEmitterService.emitCloseUpdateFarmerPrevisDialog();
        this.InitFarmPrevisSubscriptions();
    }


    /**
     *  Gestion si aucun order chargé
     * @param blIsException indique qu'une exception au chargement s est produit
     */
    private NoOrderLoaded(blIsException: boolean = false) {

        this._farmService.FilteredOrders = [];
        this._mapService.CleanLegendMap();
        if (blIsException)
            this.Initialize(null);
        if (!blIsException) {
            this.UserAlerte('Il n\'y a pas de données à afficher pour ce compte');
            this.logout();
        }
        else
            this.UserAlerte('Une erreur est survenue lors de la récupération des données pour ce compte');
    }


    //#region Gestion des previsionelles

    private InitFarmPrevisSubscriptions() {

        this._eventEmitterService.invokeFarmerPrevisReloadingOrder.subscribe((fSubUpdatePrevis: farmerPrevisSaveChangesResume) => {
        
            if (fSubUpdatePrevis && this._lastIdPrevisChangeProgress !== fSubUpdatePrevis.UniqueId) {

                //on evite les soucriptions passant x X
                this._lastIdPrevisChangeProgress = fSubUpdatePrevis.UniqueId;
                if (fSubUpdatePrevis.IsDeleteMode()) {
                    this.UpdatePrevisDeleted(fSubUpdatePrevis)
                } else {
                    this.ReloadingOnPrevisChanges(fSubUpdatePrevis)
                }
            }
        });
    }

    /**Rechargement des données legend export .. apres modif de prévis */
    private ReloadingOnPrevisChanges(fSubUpdatePrevis: farmerPrevisSaveChangesResume) {

        let farmChangedIds = fSubUpdatePrevis.FarmsIds.map(t => t.toString());
        this.ShowLoaderWaiting();
        this._farmService.GetFarmByCultivationsFiltered(farmChangedIds, Number(this._jarvisMenuService.getSelectedItems(this.menukey_campaign)[0].Id), null, false).subscribe(ordersUpdate => {
             let forceFarmsReload = false;
            //on récupère que pour l'exploitation concerné ses orders sans enregistrer au service l'order
            if (ordersUpdate && ordersUpdate.length) {
                 //les orders chargés en interface
                let frontOrders = this._farmService.FilteredOrders;
                if(frontOrders?.length){

                    fSubUpdatePrevis.OrdersidsChanges.forEach(fUpdOrdId=>{
                        let ndxResp = ordersUpdate.findIndex(t=>t.OrderUid == fUpdOrdId)
                        let ndxFront = frontOrders.findIndex(t=>t.OrderUid == fUpdOrdId)
                        if(ndxResp !== -1 && ndxFront !== -1){
                            frontOrders[ndxFront] = ordersUpdate[ndxResp];
                        }else {
                            forceFarmsReload = true;
                            console.error('ReloadingOnPrevisChanges response order id not found',fUpdOrdId)
                        }
                    })
                    //les orders chargés mis son remis ce qui provoque le rechargement des données legend carto FRONT
                    this.UpdateFrontDatas(frontOrders);
                } else {
                    forceFarmsReload = true;
                }
                
                if(forceFarmsReload == true){
                    this.ReloadingFarmsSelected()
                }
                else {
                    this.HidLoader();
                }
                

            } else {
                this.ReloadingFarmsSelected();
            }
           
        })
    }

    /**Rechargement de l'interface depuis les exploitations selectionnées pour la campagne et les cultures selectionnées */
    private ReloadingFarmsSelected(){
        this.ShowLoaderWaiting();
        this._farmService.GetFarmByCultivationsFiltered(this._farmService.FilteredFarmsIds(), Number(this._jarvisMenuService.getSelectedItems(this.menukey_campaign)[0].Id), this._jarvisMenuService.getSelectedModels(this.menukey_cultivation), true).subscribe(ordersUpdate => {
            this.HidLoader();
        })
    }

    /**
     * Suppression depuis l'order selectionné
     * @param fSubUpdatePrevis 
     */
    private UpdatePrevisDeleted(fSubUpdatePrevis: farmerPrevisSaveChangesResume) {

        let forceReloading = false;
        //les orders chargés en interface
        let frontOrders = this._farmService.FilteredOrders;
        if (frontOrders?.length) {
            this.ShowLoaderWaiting();
            //on supprime direct dans l'order les previs parcelle avec le status suppression
            fSubUpdatePrevis.OrdersidsChanges.forEach((fordId: string) => {
                let ndxOrd = frontOrders.findIndex(x => x.OrderUid == fordId);
                if (ndxOrd !== -1) {
                    let forderupdt = frontOrders[ndxOrd];
                    let plotPrevisDeleted = fSubUpdatePrevis.Changes.filter(r => r.MatchOnDeleting() && r.OrderId == fordId);

                    if (plotPrevisDeleted.length > 0) {
                        plotPrevisDeleted.forEach((fPrevis: farmerPrevisSaveChangeResume) => {
                            let ndxSector = forderupdt.Sectors.findIndex(f => f.Id == fPrevis.SectorId);
                            if (ndxSector == -1) {
                                forceReloading = true;
                                return;
                            } else {
                                let fSector = forderupdt.Sectors[ndxSector];
                                let ndxPlot = fSector.CultivationPlots.findIndex(t => t.Id == fPrevis.PlotId);
                                if (ndxPlot == -1) {
                                    forceReloading = true;
                                    return;
                                } else {
                                    let fPlot = fSector.CultivationPlots[ndxPlot];
                                    if (fPlot.InterPrevis?.length) {
                                        let ndxPrevis = fPlot.InterPrevis.findIndex(t => t !== undefined && t.IdIntervention == fPrevis.InterId);
                                        if (ndxPrevis == -1) {
                                            forceReloading = true;
                                            return;
                                        } else {
                                            let previs = fPlot.InterPrevis[ndxPrevis];
                                            let previsIdent = previs.Ident;
                                            if (previsIdent == 0) {
                                                forceReloading = true;
                                                return;
                                            }

                                            fPlot.MicroPlots.forEach((fMicro => {
                                                if (fMicro.InterPrevis && fMicro.InterPrevis.findIndex(mc => mc.Ident == previsIdent) != -1) {
                                                    fMicro.InterPrevis.splice(fMicro.InterPrevis.findIndex(mc => mc.Ident == previsIdent),1);
                                                    
                                                }
                                            }))
                                            
                                                //suppression
                                             fPlot.InterPrevis.splice(ndxPrevis,1);
                                        }
                                      
                                        fSector.CultivationPlots[ndxPlot] = fPlot;
                                    }
                                }
                                forderupdt.Sectors[ndxSector] = fSector;
                            }

                        })
                    }

                    frontOrders[ndxOrd] = forderupdt;
                    this._farmService.FilteredOrders

                } else {
                    forceReloading = true;
                    return;
                }

            }

            )
        } else {
            forceReloading = true;
        }

        if (forceReloading == true) {
            //il y eu soucis donc on recharge depuis le back
            this.ReloadingOnPrevisChanges(fSubUpdatePrevis);
        } else {
            //ca provoque le rechargememnt des legendes..
            this.UpdateFrontDatas(frontOrders);
            this.HidLoader();

        }
    }

    /**On provoque le rechargement des legendes et données  order utilisateurs */
    private UpdateFrontDatas(_iFrontOrders:Farm[]){
        this._farmService.ReloadFrontOrdersDatas([]);
        setTimeout(() => {
            this._farmService.ReloadFrontOrdersDatas(_iFrontOrders);
            
          }, 500);
        ;
    }

    //fermeture de la modale de duplication de préconisation technicien via la croix
    public onDuplicateTechPrecoDialogClosed(event: any) {
        this._eventEmitterService.emitCloseDuplicateTechPrecoDialog();
    }

    //fermeture de la modale de modification farmer prévis via la croix
    public onFarmerPrevisUpdateDialogClosed(event: any) {
        this._eventEmitterService.emitCloseUpdateFarmerPrevisDialog();
    }


    //#endregion
    private UserAlerte(_userMessage: string) {
        window.alert(_userMessage);
        this.HidLoader();
    }

    private LogOnAnalytics() {
        this.googleService.eventScreenViewEmitter('DashBoard')
    }

    ShowLoaderWaiting() {
        this._loadingService.show("Merci de patienter");
    }

    HidLoader() {
        this._loadingService.hide();
    }
}
