import { Intervention } from './intervention';
import { isNullOrEmptyString } from '../../_helpers';

export class RecommendationView {
    IdRecommendation: string;
    Name: string;
    // Type: string;
    Quantity: number;
    IsChecked: boolean;
    OriginalName: string;
    CodeProduit: string;
    //Some Other CodeProduit for same Name-Date
    OtherCodeProduit: string[] = [];
    //Date au format YYYY-MM-dd employé pour filtrer les produit export
    Date: string;
    // indique une preco de fumure
    IsFumur: boolean = true;
    //indique si on est sur une intervention mode preco
    IsInterModePreco: boolean = false;

    //indique si on est sur une preco Pilotage Azote SP
    IsPilotSPPreco: boolean = false;
    SortName: any = null;
    //unité du produit
    Unit: string;
    //Ensemble des unités convertible
    ConvertUnits: ViewShapeConvertUnit;
    //si on est sur une intervention prévisionnelle farmer
    IsFarmerPrevis:boolean=false

    /**
     * instancie une recommendation depuis une intervention
     * @param _iplPreco plot Preconisation
     * @param _iIsoDate date au format YYYY-MM-DD ( permettant l order)
     * @param isChecked indique si il est checked
     * @param _iIsFumure indique si c est une preco de fumure
     * @param _iIsInterModePreco indique si on est sur une intervention en mode preco, false une preco ou modulation
     *  @param _iIsPilotSPPreco indique si on est sur une preconisation de pilotage azote SP (LAI)
     * @param [_iIsFarmerPrevis=false]  si on est sur une farmer previs
     */
    constructor(_iplPreco: Intervention, _iIsoDate: string, isChecked: boolean = false, _iIsFumure: boolean = true, _iIsInterModePreco: boolean = false, _iIsPilotSPPreco:boolean=false, _iIsFarmerPrevis:boolean=false) {
        if (_iplPreco) {
            this.IdRecommendation = _iplPreco.IdIntervention ? _iplPreco.IdIntervention.toString() : _iplPreco.IdGroupe;
            this.IsChecked = isChecked;
            if (_iplPreco.Fertilizer) {
                this.OriginalName = _iplPreco.Fertilizer.Name != null ? _iplPreco.Fertilizer.Name.toLowerCase() : "";
                this.Name = _iplPreco.Fertilizer.Name;
                this.Quantity = _iplPreco.Fertilizer.Dose;
                this.Unit = _iplPreco.Fertilizer.Unit;
            }
            //En mode preconisation, on dispose du code produit, en mode intervention, on prend le code fertilizer
            this.CodeProduit = isNullOrEmptyString(_iplPreco.CodeProduit) ? _iplPreco.Fertilizer.code : _iplPreco.CodeProduit;
            this.Date = _iIsoDate;
            this.IsFumur = _iIsFumure;
            this.SortName = this.FormatOrderableName();

            this.OtherCodeProduit = [];
            this.IsInterModePreco = _iIsInterModePreco;
            this.IsPilotSPPreco = _iIsPilotSPPreco;
        }
        this.IsFarmerPrevis = _iIsFarmerPrevis;

    }

    /**
     * indique la correspondance selon le Nom Original
     * @param _iName
     */
    public EqualByOriginalName(_iName: string) {
        return _iName ? _iName.toLowerCase() == this.OriginalName : false;
    }

    private FormatOrderableName(): string {
        let cbName = this.OriginalName ? this.OriginalName.toLowerCase() : "";
        let cbDate = this.Date ? this.Date.toLowerCase() : "";
        return cbName + cbDate;
    }

    public get OrderableName() {
        return this.SortName;
    }
}

/**Class gerant les unité de la préconisation */
export class ViewShapeConvertUnit {
    //l'unité préselectionné ( unité du produit de base)
    public Preselected: string;
    //l'unité final de convertion
    public Selected_key: string;
    //ensemble des unités de convertion possibles
    public Values: any[] = [];
    /**
     * initialisation du composant
     * @param _iPreselected le code de l'unité du produit de base
     * @param _iUnits ensemble des unités disponibles
     */
    public constructor(_iPreselected: string, _iUnits: any[]) {
        this.Preselected = _iPreselected;
        this.Selected_key = _iPreselected;
        this.Values = _iUnits;
    }

    /**Retourne si l unité a été cahngée */
    public IsUnitChanged() {
        return this.Selected_key && this.Selected_key !== null && this.Selected_key != this.Preselected;
    }

}
