import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { Farm, Cultivation, User, farmConfig } from '../_models/index';
import {HandleService, HttpRequestService} from '../_services/index';
import { environment } from '../../environments/environment'
import { JarvisUiService } from '../modules/jarvis-ui/jarvis-ui.service';
import { map } from 'rxjs/operators';

const GETFARM_ACTION = environment.BeApiOperational.getfarms;
const GETCAMPAGNE_ACTION = environment.BeApiOperational.getcampagne;
const GETCULTURE_ACTION = environment.BeApiOperational.getcultures;
const GETFILTER_ACTION = environment.BeApiOperational.getfilter;
const GETPARCELLE_ACTION = environment.BeApiOperational.getparcelles;
const GETUSERINFO_ACTION = environment.BeApiOperational.getuserinfo;

@Injectable()
export class FarmServices extends BehaviorSubject<Farm[]> {
    private baseUrl = environment.BeApiOperational.url;

    //les orders chargés
    private _filteredOrders : Farm[];

    //liste des identifiants des orders chargés en interface
    private _ordersIds:string[] = [];

    //dictionnaires des orders Id et steps
    private _ordersPrestas : Record<string,number>={};

    /**liste des identifiants des orders chargés en interface */
    public get FilteredOrdersIds(): string[]
    {
        return this._ordersIds;
    }

    /**liste des identifiants des exploitations chargées en interface */
    public FilteredFarmsIds(): string[]
    {
        return this._filteredOrders?.map(t=>t.IdExploitation);
    }

    /**Retourne la liste des identifiants d'order de type conseil (PK N PACK) */
    public GetOrderIdsOnAdvicePrestation():string[]{

        //FOData = 2 & EpiclesReturn = 4
        return HandleService.GetKeysByValues(this._ordersPrestas,[2,4])
    }
     /**Retourne la liste des identifiants d'order de Commande Pilot SP */
     public GetOrderIdsOnPilotSPPrestation():string[]{
        //OrderSpLai = 9
        return HandleService.GetKeysByValue(this._ordersPrestas,9)
    }

      /**liste des orders chargés en interface */
    public get FilteredOrders(): Farm[]
    {
        return this._filteredOrders;
    }

    /**les orders chargés */
    public set FilteredOrders(value:Farm[])
    {
        this._filteredOrders = value;
        this.BindOrderIdSelected();
    }

    /**
     * on remplace les données orders (farms)
     * ce qui provoque le rechargement des données legendes carto...
     * @param value 
     */
    public ReloadFrontOrdersDatas(value:Farm[]){
        this.FilteredOrders = value;
    }

    constructor(private _jarvisMenuService: JarvisUiService, private httpRequestService: HttpRequestService) {
        super([]);
    }

    getuserinfo(): any {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const body = { "smagToken": currentUser['smag_token'] };
        return this.httpRequestService.PostRequest(this.baseUrl + GETUSERINFO_ACTION, body , 'getuserinfo').pipe(
          map(
            (response) => {
                const userInfo = response;
                return this.convertJsonToUserInfo(userInfo);
            })
        );
    }

    public getFarms(allFarms: boolean) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const body = { "smagToken": currentUser['smag_token'] };
        return this.httpRequestService.PostRequest(this.baseUrl + GETFARM_ACTION, body, 'getFarms')
          .pipe(
            map(
            (response) => {
                return this.convertJsonToFarm(response, allFarms);
            })
        );
    }


    public getCampagnes(farmsId: string[]) {
      const body = { "FarmsId": farmsId };
      return this.httpRequestService.PostRequest(this.baseUrl + GETCAMPAGNE_ACTION, body, 'getCampagnes').pipe(
        map(
            (response) => {
                return this.convertJsonToCampagneList(response);
            })
      );
    }

   /**
    * Retourne les exploitation selon la camapgne et culture si présente
    * @param farmsId 
    * @param idCampaign 
    * @param cultivations 
    * @param preserveMemory on preserve dans le service les epxloitations recupérée comme order filtered
    * @returns 
    */
    public GetFarmByCultivationsFiltered(farmsId: string[], idCampaign: number, cultivations: Cultivation[], preserveMemory:boolean=true ) {
        const body = { "FarmsId": farmsId, "IdCampaign": idCampaign, "Cultivations": cultivations };

        return this.httpRequestService.post(this.baseUrl + GETFILTER_ACTION, body, 'GetFarmByCultivationsFiltered').pipe(
          map(
            (response) => {
                let apps = response;
                return this.convertJsonToFarm(apps, false);
            })
        );
    }


    public getCultivations(farmsId: string[], idCampaign: number) {

        const body = { idCampaign, farmsId };
        return this.httpRequestService.PostRequest(this.baseUrl + GETCULTURE_ACTION, body, 'getCultivations').pipe(
          map(
            (response) => {
                let apps = response;
                return this.convertJsonToCultivation(apps);
            })
        );

    }

    /**
     * 
     * @param any 
     * @param allFarm true on retourne l'ensemble des exploitations de l'utilisateur et enregistré en storage false on remplit this.FilteredOrders
     * @param preserveMemory si true remplis this.FilteredOrders Si pas allfarms
     * @returns 
     */
    private convertJsonToFarm(any, allFarm: boolean, preserveMemory:boolean= true): Farm[] {
        let convertedApps = new Array<Farm>();
        if (any.length === 0)
            return;

        if (any)
            any.forEach((element, i) => {
                convertedApps[i] = new Farm(element);
            });

        if (allFarm)
        {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            let farms = JSON.parse(localStorage.getItem('farms')) as { [userId: string] : Farm[]; };

            if(farms == null)
                farms =  {};

            farms[currentUser.userid] = convertedApps;
            localStorage.setItem('farms', JSON.stringify(farms));
        }
        else if(preserveMemory == true)
        {
            this.FilteredOrders = convertedApps;
        }
        return convertedApps;
    }

    convertJsonToUserInfo(personalInfo: any): User {
        let convertedInfo: User = new User();
        if(personalInfo.UserInfo != null)
        {
            if(personalInfo.UserInfo.FirstName!=undefined)
            convertedInfo.Firstname = personalInfo.UserInfo.FirstName;
            else
            convertedInfo.Firstname='';

            if(personalInfo.UserInfo.LastName!=undefined)
            convertedInfo.Lastname = personalInfo.UserInfo.LastName;
            else
            convertedInfo.Lastname='';
        }

        if (personalInfo && personalInfo.Role != undefined) {
          convertedInfo.Role = personalInfo.Role;
        } else {
          convertedInfo.Role = "";
        }
        return convertedInfo;
    }



    private convertJsonToCultivation(any): Cultivation[] {
        let convertedApps = new Array<Cultivation>();
        let i = 0;

        if (any)
            any.forEach(element => {

                if (element != null) {

                    convertedApps[i] = new Cultivation(element.Id, element.Name, element.Color);


                    if (element.Name == null)
                        element.Name = "Non défini(e)"

                    convertedApps[i].Name = element.Name;
                }
                i++;
            });
        return convertedApps;
    }

    private convertJsonToCampagneList(any): number[] {
        let convertedApps = new Array<number>();
        let i = 0;
        convertedApps = any.CampagnesValues.sort(function (a, b) { return b - a });;
        return convertedApps;

    }

    /**
     * depuis les orders chargé complète la liste des identifiants de ceux-ci
     */
    private BindOrderIdSelected(){
        this._ordersIds = [];
        this._ordersPrestas={};
        if(this.FilteredOrders?.length){
          this.FilteredOrders.forEach(
            (fOrder:Farm)=>{
              this._ordersIds.push(fOrder.OrderUid)
              this._ordersPrestas[fOrder.OrderUid] = fOrder.Step
            }
          )
        }
      }

    //Retourne un observable VIDE
    public GetEmptyFarmArray():Observable<Farm[]> {
        return EMPTY;
    }


    public static IncludePlotModulation(_iFarm:Farm):boolean
    {
        return (_iFarm.Sectors && _iFarm.Sectors.find(r=>r.CultivationPlots!=null && r.CultivationPlots.length && r.CultivationPlots.find(pl=> pl.Preconisations && pl.Preconisations.length>0)!=null )!=null)
    }


    public static IncludeModePreco(_iFarm:Farm): boolean
    {
      return _iFarm.HasModePreco();
    }
}
