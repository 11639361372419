import { Productrequest } from "./productrequest";

export class PrecizionRequest {
    FarmIds: string[];
    CampaignId: number;
    PlotIds: string[];
    PreconisationIds: string[];
    ConsoleId: string;
      /**
     *Filtre sur la selection Ancien traitement OrderByPlots
     * Plot=0, Cultivation=1
     */
    FilterSelected:number;
    Products :Productrequest[];
    /**
     * Indique qu il merge les Plot.precos - inter-ou modulation
     */
    blMergePrecos:boolean;

    /**
     * Indique qu il merge les Plot.precos - inter-ou modulation
     * QUE pour les même culture (true), si false pas de same culture
     */
    blMergePlotSameCulture:boolean;

    /**Taille du buffer d'�largissement de plots */
    BufferSize:number=0;

    /**indique que l'on exclue les preconisations parcelles avec un lien avec la prévis liée exportée */
    ExcludePrecosOnPrevis : boolean=false;

    /**
     *this is to initialize the three list objects to avoid errors when given values.
     */
    constructor() {
        this.FarmIds = [];
        this.PlotIds = [];
        this.PreconisationIds = [];
        this.Products=[];
        this.blMergePrecos=false;
        this.blMergePlotSameCulture = true;
        this.BufferSize=0;
        this.ExcludePrecosOnPrevis=false;
    }
}