import { mcFertilizer } from "./mcFertilizer";
import { Intervention } from "./intervention";
import { HandleService } from "../../_services";

/**
 * Class des Preconisations|Modulation à la MicroParcelle
 */
export class mcPreconisation {

    Ident:number;
    IdIntervention : number;
   
    Fertilizer:mcFertilizer;
    IdGroupe:string;
    Percent:number;
    IsPercent:boolean;
    Comm:string;
    RefDose:number;
    ModDatas:ModulComplements;

    public constructor(_iPreco: Intervention|mcPreconisation)
    {
        this.Ident=_iPreco.Ident;
        this.IdIntervention =_iPreco.IdIntervention;
        this.Fertilizer = new mcFertilizer(); 
        if(_iPreco.Fertilizer != null) {
            this.Fertilizer.Dose= _iPreco.Fertilizer.Dose;
            this.Fertilizer.code= _iPreco.Fertilizer.code;
        }
        
        this.IdGroupe= _iPreco.IdGroupe;
        this.Percent=_iPreco.Percent;
        this.IsPercent=_iPreco.IsPercent;
        this.Comm=_iPreco.Comm;
        this.RefDose=_iPreco.RefDose;
        this.ModDatas=_iPreco.ModDatas;
        
    }

    /**
     * Arrondit décimale de la dose du produit
     * @param _iDecimals nombre de décimal arrondit
     */
    public RoundDose(_iDecimals:number){
        if(this?.Fertilizer?.Dose){
            this.Fertilizer.Dose = HandleService.RoundDecimals(this.Fertilizer.Dose, _iDecimals);
        }
    }
}

export class ModulComplements
{
    Type:string;
    Value:number;
}


