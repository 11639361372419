import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { menuItem } from './../../../modules/jarvis-ui/_models/index';
import { eExportType, Productrequest } from '../../../_models/precizion/productrequest';
import { Console, RecommendationView, PrecizionRequest, Farm, CultivationPlot, Intervention,eTechInterType } from './../../../_models/index';
import { PrecizionServices, GoogleAnalyticsService, ModalService, FarmerPrevisService } from './../../../_services/index'
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { LoadingService } from '../../../framework/auth/components/loading/loading.service';
import { HandleService } from './../../../_services/handle.service';
import { mcPreconisation } from '../../../_models/beapi/mcPreconisation';
import { isNullOrEmptyString, isNullOrUndefined } from '../../../_helpers';
import { bootstrapApplication } from '@angular/platform-browser';
declare var originalAlert: any;

@Component({
  selector: 'console-window',
  templateUrl: './consolewindow.component.html',
  styleUrls: ['./consolewindow.component.scss', './consolewindow.component.responsive.scss']
})
export class ConsoleWindowComponent implements OnInit {

  @Input()
  public opened: boolean = false;
  @Input()
  public ilot: Farm[];

  /**
  *Ensemble des Cultures User selectionnées
  */
  @Input()
  public SelectedCultivations: menuItem;
  @Output()
  public DialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();


  /**INDIQUE choix client, si il faut merger les parcelles */
  public blCbxMergePrecos: boolean = true;
  /**INDIQUE choix client, si il faut merger les parcelles avec leur culture differentes */
  public blCbxMergeDiffCultivation: boolean = false;

  /**en mode sleection de toutes les preco affichées */
  public grdCheckAllChecked: boolean = false;
  public existSelectable:boolean=false;
  public gridSelection:number=0;
  public gridTotal:number=0;
  /** ensemble des preconisations-produit du client */

  public gridPrecos: RecommendationView[] = [];
  public listConsole: Console[];
  public dataSaved: boolean = false;
  public selectedConsole: Console;
  public BreakException: any = {};
  public selectedFormat: string = null;
  public isConsole: boolean;
  private preconisationParcelle: RecommendationView[] = [];
  public selectableSettings: SelectableSettings;
  //Affiche les checkbox Multi Conseil/Ferti
  public CheckModeMulti: boolean = true;
  //etat de la combo preconisation
  public ChkPrecosFerti: boolean = false;
  //etat de la combo autres (modulations)
  public ChkOtherPrecos: boolean = false;
  //etat de la combo intervention mode preco(apport orga)
  public ChkInterModePreco: boolean = false;

  //etat de la combo Pilotage SP LAI preco
  public ChkPilotSpPreco: boolean = false;

  //etat de la combo farmer previs
  public ChkFarmerPrevis: boolean = false;

  //etat indispo de la combo preconisation
  public HiddenChkPrecosFerti: boolean = true;
  //etat indispo de la combo autres (modulations)
  public HiddenChkOtherPrecos: boolean = true;
  //etat indispo de la combo INtervention de preco (apport orga)
  public HiddenChkInterModePreco: boolean = true;

  //etat indispo de la combo Pilotage SP LAI preco
  public HiddenChkPilotSpPreco: boolean = true;
  //etat indispo de la combo farmer previs
  public HiddenChkFarmerPrevis: boolean = true;

  /**Valeur du Slide buffer */
  public SlideBufferValue: number = 0;
  //indique si le traitement d'export est en cours
  private _exportInProgress: boolean = false;

  public SlideTltp = "Elargir le contour des parcelles peut permettre de palier si besoin à l’imprécision du contour cartographique en bordure de parcelle. Recommandé pour les interventions à faible largeur de travail.";
  public get SlideTitle():string {
    return 'Elargir le contour des parcelles  (' + this.SlideBufferValue + ' mètre' + (this.SlideBufferValue > 1 ? 's' : '') + ')';
  }

  /**retourne si le bouton export est verouillé (sans selection ou en cours d export) */
  public get disableExportButton():boolean{
   return this.gridSelection == 0 || this._exportInProgress == true;
  }


  public ColumnUnit = "Unité cible (Shapefile)";
  public ColumnUnitTltp = "Pour l’export au format Shapefile, la préconisation sera convertie dans l’unité choisie.";

  //textes + tootlip des combobox merge de preconisations
  public CbxMergePlotPrecoTxt = "Regrouper les préconisations des parcelles adjacentes";
  public CbxMergeDiffCultTxt = "Regrouper même si cultures différentes";
  public CbxMergePlotPrecoTltp = "Fusionne les préconisations pour les parcelles adjacentes en une seule préconisation (culture, date d’apport et produit doivent être identiques).";
  public CbxMergeDiffCultTltp = "Les préconisations ayant le même produit et la même date seront fusionnées pour toutes les parcelles adjacentes, même si la culture est différente.";
  public IconPrevisFarmerTltp = "Intervention personnalisée";

  constructor(private _precizionServices: PrecizionServices, private _loadingService: LoadingService, private googleService: GoogleAnalyticsService, private modaleService:ModalService) { }


  ngOnInit() {
    this.initInteraction();
    //pour préserver les selections anterieur on ne reinitialise pas les selectedConsole -blCbxMergeDiffCultivation
    window.alert = originalAlert;
    //indique si on a plusieurs types

    this.loadingTerminals();

  }

  private initInteraction() {
    this.CheckModeMulti = false;
    this.ChkOtherPrecos = false;
    this.ChkPrecosFerti = false;
    this.ChkInterModePreco = false;
    this.ChkPilotSpPreco = false;
    this.HiddenChkPrecosFerti = true;
    this.HiddenChkOtherPrecos = true;
    this.HiddenChkInterModePreco = true;
    this.HiddenChkPilotSpPreco = true;
    this.ChkFarmerPrevis = false;
    this.grdCheckAllChecked = false;
    this.existSelectable = false;
    this.gridSelection=0;
    this.gridTotal=0;
    this._exportInProgress = false;
    this.selectableSettings = {
      mode: "multiple"
    };
  }

  private loadingTerminals() {
    if (!this.opened || (!localStorage.getItem("consoles") || localStorage.getItem("consoles").length <= 1)) {
      this._precizionServices.getPrecizions().subscribe(consoles => {
        this.BindingTerminals(consoles);
      });
    }
    else {
      this.BindingTerminals(JSON.parse(localStorage.getItem("consoles")) as Console[]);
    }
  }

  private BindingTerminals(terminals: Console[]) {
    this.listConsole = terminals;
    this.PreselectedOutput(this.listConsole);
  }


  /**
      *Préselection du choix de sortie de fichier
      */
  private PreselectedOutput(_consoles: Console[]) {

    if (isNullOrUndefined(this.selectedConsole) && _consoles != null && _consoles.length > 0) {
      //préselection de la 1e console de la liste si aucune sélection déjà faite
      this.selectedConsole = _consoles[0];
    }
    //on initialise QUE si il a rien de déjà selectionné
    if (isNullOrEmptyString(this.selectedFormat)) {
      this.SelectionFormat(this.selectedConsole != null);
    }
    
  }

  /**
   * Selection des combo des formats
   *
   * @param _blSelectConsole true selection console false preselection shape
   */
  private SelectionFormat(_blSelectConsole) {
    this.selectedFormat = _blSelectConsole ? 'console' : 'shape';
    this.isConsole = _blSelectConsole;
  }

  public close() {
    this.opened = false;
    this.DialogClosed.emit(this.opened);
    this.gridPrecos.forEach(a => { a.IsChecked = false })
    this.SetGridPrecos(this.preconisationParcelle);

  }

  public open() {
    this.opened = true;

  }

  public submit() {

    this.dataSaved = true;
    this._exportInProgress = true;
    let param: PrecizionRequest = new PrecizionRequest();
    let IlotWithPrecos: Farm[] = this.ilot && this.ilot.length > 0 ? this.ilot.filter(r => !r.IsDiagnostic()) : null;
    if (!IlotWithPrecos) {
      this.UserAlerte("Aucune exploitation sélectionnée");
      this._exportInProgress = false;
      return;
    }
    IlotWithPrecos.forEach(element => {
      if (element.IdExploitation)
        param.FarmIds.push(element.IdExploitation);
    });
    param.BufferSize = this.SlideBufferValue;
    param.CampaignId = IlotWithPrecos[0].IdCampagne;
    param.blMergePrecos = this.blCbxMergePrecos;
    param.blMergePlotSameCulture = this.blCbxMergePrecos == true ? this.blCbxMergeDiffCultivation == false : true;

    //convertion des unités selon choix client
    //voir si necessaire de connaitre le mode shapefile de la console
    let include_change_unit = true;
    this.gridPrecos.forEach(a => {

      if (a.IdRecommendation && a.IsChecked) {

        let change_to_unit_key = null;
        // si en shape et une unité est selectionné != de la préselectionnée on modifiera l unité en export shape
        if (include_change_unit == true && a.ConvertUnits && a.ConvertUnits.IsUnitChanged() == true) {
          change_to_unit_key = a.ConvertUnits.Selected_key;
        }

        param.Products.push(new Productrequest(a.CodeProduit, a, change_to_unit_key));
        if (a.OtherCodeProduit != null && a.OtherCodeProduit.length) {// include some other code of product
          a.OtherCodeProduit.forEach(othercode => {
            if (othercode != null)
              param.Products.push(new Productrequest(othercode, a, change_to_unit_key));
          })
        }
      }
    });

    if (!param.Products || !param.Products.length) {
      this.UserAlerte("Veuillez sélectionner au moins une préconisation.", false);
      this._exportInProgress = false;
      return;
    }

    if(param.Products.length>1){
      if(param.Products.findIndex(r=>r.Type == eExportType.PilotSP || r.Type == eExportType.Preco) !== -1){
        if(param.Products.findIndex(r=> r.Type == eExportType.PrevisPrecAd || r.Type == eExportType.PrevisPrecSP) !== -1){
          //en export il filtrera les preco qui ont un lien avec la previs exportée si présent
          param.ExcludePrecosOnPrevis = true;
        }
      }
    }
    this.LogOnAnalytics(param);

    let closeModale= true;
    if (this.isConsole) {
      if (this.selectedConsole != null) {

        this._loadingService.show("Veuillez patienter pendant la génération des fichiers de modulation");
        param.ConsoleId = this.selectedConsole.id;

        this._precizionServices.getConsoleDownload(param).subscribe(
          response => {
            this.downloadingExportedFile(response);
          }
          ,
          error => {
            this._loadingService.hide();
            this.UserAlerte("Une erreur est survenue durant la génération.", true);
            closeModale = false;
          }
        );
      }
      else{
        this._exportInProgress = false;
       
        return;
      }
        

    } else {

      this._loadingService.show("Veuillez patienter pendant la génération des fichiers de modulation");
      this._precizionServices.getShapeDownload(param).subscribe(
        response => {
          this.downloadingExportedFile(response);
        },
        error => {
          this._loadingService.hide();
          this.UserAlerte("Une erreur est survenue durant la génération.", true);
          closeModale = false;
        });
    }
    this._exportInProgress = false;

    if(closeModale){
      this.ngOnInit();
      this.close();
    }
    
  }

  /**
   * Downloading exported file serveur
   * @param fileDownloadReq file exported downloader informations
   */
  private downloadingExportedFile(fileDownloadReq: any) {
    if (fileDownloadReq && fileDownloadReq.Uid && fileDownloadReq.Name) {
      let errorMsg = "Une erreur est survenue durant la récupération du fichier généré.";

      this._precizionServices.downloadExportedFile(fileDownloadReq).subscribe(p => {
        if (!p) {
          this.UserAlerte(errorMsg, true);
        }
      },
        error => {
          this.UserAlerte(errorMsg, true);
        });

      this._loadingService.hide();
    } else {
      this._loadingService.hide();
      this.UserAlerte("Une erreur est survenue durant la génération.", true);
    }
    this._exportInProgress = false;
  }

  ngOnChanges(changes: any) {
    this.BindingPrecos();
  }

  /* retourne la date formaté pour le produit préfix d un - si !=null */
  private getProductDate(date: Date): any {
    let _return = HandleService.getLOCALDate(date);
    return _return != "" ? " - " + _return : _return;
  }


  public handleConsoleRadioCheckChanged(event: any) {

    if (this.selectedFormat === "console")
      this.isConsole = true;
    else
      this.isConsole = false;
  }

  /**
   * remplissage des precos- produit date pour les exploitations selectionnées
   * Selon la campagne et les cultures
   */
  BindingPrecos() {
    this.preconisationParcelle = [];
    this.gridPrecos = [];
    this.gridSelection=0;
    this.gridTotal=0;
    this.existSelectable=false;

    /*filtre sur les cultures selectionnées */
    let filterCultureIds = [];
    if (this.SelectedCultivations && this.SelectedCultivations.GroupItems && this.SelectedCultivations.GroupItems.length) {
      this.SelectedCultivations.GroupItems.forEach(clt => {
        filterCultureIds.push(clt.Id);
      }
      );
    }
    let blfilterClt = filterCultureIds.length > 0;
    let _blIncludePilotSPPreco = false;

    let manageFarmerPrevis = FarmerPrevisService.ConfigEnableDuplication() == true;

    if (this.ilot && this.ilot.length > 0) {
      let _ShapeConvertUnits = this._precizionServices.GetDatasShapeConvertUnits();

      this.ilot.forEach(farm => {
        if (farm.IsAnnualOrder() || farm.IsDiagPilotSPLAI())
          return;

        if (farm.Sectors && farm.Sectors.length > 0) {

          let _blPrecoFumure = !farm.PresTypeModulation();
          let _blIsPilotPreco = farm.IsOrderPilotSPLAI();

          farm.Sectors.forEach(ilot => {
            if (ilot.CultivationPlots && ilot.CultivationPlots.length > 0) {
              ilot.CultivationPlots.forEach(parcelle => {
              
                if (blfilterClt) {//filtre selon les cultures seelctionnées
                  if (parcelle.CultivationSelected && parcelle.CultivationSelected.Id && filterCultureIds.findIndex(e => e == parcelle.CultivationSelected.Id.toString()) > -1) {
                  }
                  else {
                    return;
                  }
                }

                let includeTechPrecoPrevis= manageFarmerPrevis && parcelle.InterPrevis.findIndex(e=>e.SrcType == eTechInterType.Preconisation || e.SrcType == eTechInterType.PilotSp) != -1;

                //Ques les parcelles AVEC des precos
                if (parcelle.Preconisations && parcelle.Preconisations.length) {


                  parcelle.Preconisations.forEach((plPreco, index) => {

                    if(includeTechPrecoPrevis && parcelle.ExistPrevisTechPreconisation(plPreco.IdIntervention)){
                      //on n'affiche pas les précos avec une prévis liée à la parcelle
                      return;
                    }
                   
                    let _propIdent = plPreco.Ident;
                    let _propIdGroup = plPreco.IdGroupe;

                    /*Evite peter si Pas de fertilisant sur la preconisation*/
                    if (this.IsEmptyFertiDose(plPreco)) {
                      return;
                    }
                    if (_blPrecoFumure) {
                      if (_propIdent <= 0 || !this.IncludeMcPrecoDoseOnIdent(parcelle, _propIdent)) {//aucune micro ne dispose de dose >0
                        return;
                      }
                    }
                    else if (_propIdGroup == null || !this.IncludeMcModulationDoseOnIdGroup(parcelle, _propIdGroup)) {//Modulation
                      return;
                    }
                    let _elemdate: string = HandleService.getISODate(plPreco.BeginDate);
                    let selectedPlPreco = this.preconisationParcelle.find(a => a.EqualByOriginalName(plPreco.Fertilizer.Name) && _elemdate === a.Date && a.IsFarmerPrevis == false);
                   
                    if (selectedPlPreco) {
                      // same Product ON date
                      let addedDose = plPreco.Fertilizer.Dose;
                      let existingDose = selectedPlPreco.Quantity;
                      existingDose = existingDose + addedDose;
                      selectedPlPreco.Quantity = existingDose;
                      if (selectedPlPreco.CodeProduit != null && plPreco.CodeProduit != null && plPreco.CodeProduit != selectedPlPreco.CodeProduit) {// same product  Name but different product code
                        if (!selectedPlPreco.OtherCodeProduit.length || selectedPlPreco.OtherCodeProduit.indexOf(plPreco.CodeProduit) < 0) {
                          selectedPlPreco.OtherCodeProduit.push(plPreco.CodeProduit)
                        }
                      }
                    
                    } else {

                      if (_blIsPilotPreco == true) {
                        _blIncludePilotSPPreco = true;
                      }
                      let preco = new RecommendationView(plPreco, HandleService.getISODate(plPreco.BeginDate), false, _blPrecoFumure, false, _blIsPilotPreco);
                      //indique si on est sur une modulation en mode bouillie
                      let _isModulationBouillie = Intervention.IsModulationBouillie(plPreco);

                      let unit_on_area = " " + HandleService.FormatUnitOnArea(preco.Unit);
                      //Constitution du nom final à afficher dans la liste des Modulations
                      preco.Name = plPreco.Fertilizer.Name + unit_on_area + this.getProductDate(plPreco.BeginDate);
                      if (preco.Unit && _isModulationBouillie == false) {
                        //HORS mode bouillie
                        //on récupère la liste des unités de convertion possible
                        preco.ConvertUnits = this._precizionServices.GetShapeConvertUnit(preco.Unit, _ShapeConvertUnits);

                      }
                      this.preconisationParcelle.push(preco);
                    }
                  });
                }
                //Parcelles avec des interventions en mode préconisation
                if (parcelle.Interventions && parcelle.Interventions.length) {

                  parcelle.Interventions.forEach((plInter: Intervention, index) => {

                    let _blHasInterModePreco = plInter.ModePreco == true;

                    if (!_blHasInterModePreco) {
                      return;
                    }

                    if (this.IsEmptyFertiDose(plInter) || Intervention.IsInterDeleted(plInter) == true) {
                      return;
                    }

                    let _elemdate: string = HandleService.getISODate(plInter.BeginDate);

                    let selectedPlotIntervention = this.preconisationParcelle.find(a => a.EqualByOriginalName(plInter.Fertilizer.Name) && _elemdate === a.Date && a.IsFarmerPrevis == false);
                    if (selectedPlotIntervention) {
                      let addedDose = plInter.Fertilizer.Dose;
                      let existingDose = selectedPlotIntervention.Quantity;
                      existingDose = existingDose + addedDose;
                      selectedPlotIntervention.Quantity = existingDose;
                      if (selectedPlotIntervention.CodeProduit != null && plInter.CodeProduit != null && plInter.CodeProduit != selectedPlotIntervention.CodeProduit) {// same product  Name but different product code
                        if (!selectedPlotIntervention.OtherCodeProduit.length || selectedPlotIntervention.OtherCodeProduit.indexOf(plInter.CodeProduit) < 0) {
                          selectedPlotIntervention.OtherCodeProduit.push(plInter.CodeProduit)
                        }
                      }
                    } else {
                      let intervention = new RecommendationView(plInter, HandleService.getISODate(plInter.BeginDate), false, false, true);
                      let unit_on_area = " " + HandleService.FormatUnitOnArea(intervention.Unit);
                      intervention.Name = plInter.Fertilizer.Name + unit_on_area + this.getProductDate(plInter.BeginDate);
                      if (intervention.Unit) {

                        //on récupère la liste des unités de convertion possible
                        intervention.ConvertUnits = this._precizionServices.GetShapeConvertUnit(intervention.Unit, _ShapeConvertUnits);
                      }
                      this.preconisationParcelle.push(intervention);
                    }
                  })
                }

                if (parcelle.ExistInterPrevis() == true && manageFarmerPrevis == true)  {
                  parcelle.InterPrevis.forEach((plPrevis, index) => {

                    let _propIdent = plPrevis.Ident;
                    /*Evite peter si Pas de fertilisant sur la preconisation*/
                    if (this.IsEmptyFertiDose(plPrevis)) {
                      return;
                    }
                    if (_propIdent <= 0 || !this.IncludeMcPrevisDoseOnIdent(parcelle, _propIdent)) {//aucune micro ne dispose de dose >0
                      return;
                    }
                    let _elemdate: string = HandleService.getISODate(plPrevis.BeginDate);
                    let selectedPlPreco = this.preconisationParcelle.find(a => a.EqualByOriginalName(plPrevis.Fertilizer.Name) && _elemdate === a.Date && a.IsFarmerPrevis == true);
                    if (selectedPlPreco) {
                      // same Product ON date
                      let addedDose = plPrevis.Fertilizer.Dose;
                      let existingDose = selectedPlPreco.Quantity;
                      existingDose = existingDose + addedDose;
                      selectedPlPreco.Quantity = existingDose;
                      if (selectedPlPreco.CodeProduit != null && plPrevis.CodeProduit != null && plPrevis.CodeProduit != selectedPlPreco.CodeProduit) {// same product  Name but different product code
                        if (!selectedPlPreco.OtherCodeProduit.length || selectedPlPreco.OtherCodeProduit.indexOf(plPrevis.CodeProduit) < 0) {
                          selectedPlPreco.OtherCodeProduit.push(plPrevis.CodeProduit)
                        }
                      }

                    } else {

                      let fPrevis = new RecommendationView(plPrevis, HandleService.getISODate(plPrevis.BeginDate), false, _blPrecoFumure, false, _blIsPilotPreco, true);

                      //indique si on est sur une modulation en mode bouillie
                      let _isModulationBouillie = Intervention.IsModulationBouillie(plPrevis);

                      let unit_on_area = " " + HandleService.FormatUnitOnArea(fPrevis.Unit);
                      //Constitution du nom final à afficher dans la liste des Modulations
                      fPrevis.Name = plPrevis.Fertilizer.Name + unit_on_area + this.getProductDate(plPrevis.BeginDate);
                      if (fPrevis.Unit && _isModulationBouillie == false) {
                        //HORS mode bouillie
                        //on récupère la liste des unités de convertion possible
                        fPrevis.ConvertUnits = this._precizionServices.GetShapeConvertUnit(fPrevis.Unit, _ShapeConvertUnits);

                      }
                      this.preconisationParcelle.push(fPrevis);
                    }
                  });
                }


              })
            }
          })
        }
      })
    }

    if (this.preconisationParcelle.length > 0) {
      //on selectionne les combos et conditionne l affichage combo selon la presence de preco de ce type
      this.ChkPrecosFerti = this.preconisationParcelle.findIndex(t => t.IsFumur) != -1;
      this.HiddenChkPrecosFerti = !this.ChkPrecosFerti;
      this.ChkInterModePreco = this.preconisationParcelle.findIndex(t => t.IsInterModePreco) != -1;
      this.HiddenChkInterModePreco = !this.ChkInterModePreco;

      this.ChkPilotSpPreco = _blIncludePilotSPPreco;
      this.HiddenChkPilotSpPreco = !_blIncludePilotSPPreco;

      this.ChkOtherPrecos = this.preconisationParcelle.findIndex(t => t.IsInterModePreco == false && t.IsFumur == false) != -1;
      this.HiddenChkOtherPrecos = !this.ChkOtherPrecos;
      this.ChkFarmerPrevis = this.preconisationParcelle.findIndex(e => e.IsFarmerPrevis) != -1;
      this.HiddenChkFarmerPrevis = !this.ChkFarmerPrevis;
    }

    this.CheckModeMulti = this.IsModeMultiFormat();

    if (this.CheckModeMulti)
      this.cbxFiltrePrecos();
    else
      this.SetGridPrecos(this.preconisationParcelle);
  }


  /**
   * Attribution des preconisations au grid+Order des produits
   *
   * @param _iUserPreconisations
   */
  private SetGridPrecos(_iUserPreconisations: RecommendationView[]) {
    this.gridPrecos = _iUserPreconisations;
    this.OrderPrecos(this.gridPrecos);
    this.existSelectable = this.gridPrecos?.length > 0;
    if(this.grdCheckAllChecked == true){
      this.ChangeGridPrecoSelection(true);
    }
    this.gridTotal = this.gridPrecos?.length;
    this.updateSelectionCounter();
  }
  /**
   * Ordonne le tableau des preconisations
   */
  private OrderPrecos(_oPreconisations: RecommendationView[]) {
    if (_oPreconisations != null && _oPreconisations.length > 1) {
      _oPreconisations = _oPreconisations.sort(function (a, b) {
        return (isNullOrUndefined(a.OrderableName)) ? -1 : (isNullOrUndefined(b.OrderableName)) ? 1 : (a.OrderableName < b.OrderableName) ? -1 : (a.OrderableName > b.OrderableName) ? 1 : 0;
      })
    }
  }

  /**
   * show user message
   * @param _userMessage user message text
   * @param _iblAddContactSupport add text contact support if problem persist
   */
  private UserAlerte(_userMessage: string, _iblAddContactSupport: boolean = true) {

    let msg = _userMessage;
    if (_iblAddContactSupport == true) {
      msg += " Si le problème persiste, veuillez contacter votre support be Api."
    }
    this.modaleService.Error('Erreur export',msg)

  }

  //clic sur la combo Fusion de parcelle
  cbxMergePrecosClick() {
    this.blCbxMergePrecos = !this.blCbxMergePrecos;
    if (this.blCbxMergePrecos == false) {
      this.blCbxMergeDiffCultivation = false;
    }
  }

  //le client choisit sur le fait de fusionner sur des cultures differentes
  CbxMergeDiffCultivationsClick() {
    if (this.blCbxMergePrecos == true) {
      this.blCbxMergeDiffCultivation = !this.blCbxMergeDiffCultivation
    } else {
      this.blCbxMergeDiffCultivation = false;
    }

  }

  /**Filtre les précos selon checkbox types */
  cbxFiltrePrecos() {
    let _filterPrecos: RecommendationView[] = [];
    if (this.CountCheckBoxSelected() == 5) {
      _filterPrecos = this.preconisationParcelle;
    }
    else {
      this.preconisationParcelle.forEach(
        t => {
          
          if (t.IsFarmerPrevis == true) {
            //farmer previs on peut etre sur preco ou pilotsp 
            if (this.ChkFarmerPrevis) {
              _filterPrecos.push(t);
            }

          }
          else {
            if (t.IsPilotSPPreco && this.ChkPilotSpPreco) {
              _filterPrecos.push(t);
            } else if (t.IsInterModePreco && this.ChkInterModePreco) {
              _filterPrecos.push(t);
            }
            else if (t.IsInterModePreco == false && t.IsPilotSPPreco == false) {
              if ((this.ChkOtherPrecos && !t.IsFumur && !t.IsPilotSPPreco) || (this.ChkPrecosFerti && t.IsFumur))
                _filterPrecos.push(t);
            }
          }
        }
      )
    }


    this.SetGridPrecos(_filterPrecos);
  }

  /**
   * Event changement du status de la checkbox selection toute aucune preco
   */
  public onChangeCheckAll(){
   
    if(this.grdCheckAllChecked == true){
      //swith vers unchecked
      this.ChangeGridPrecoSelection(false);
    } else {
      this.ChangeGridPrecoSelection(true);
    }
  }

  /**
   * Event selection preco grid
   * @param beforeChangeStatus le status precedent l action
   */
  public onChangePrecoSelection(beforeChangeStatus:boolean){
    let isToUncheckChange = beforeChangeStatus == true;
       if(isToUncheckChange && this.grdCheckAllChecked == true && this.gridPrecos.length){
        //il a déselectionné du grid une preco donc on retire l cbx global
        this.grdCheckAllChecked = false
      
    }

    this.updateSelectionCounter();
    if(this.gridPrecos.length){
      //prendre en compte ce changement de status non comptabilisé
      if(isToUncheckChange){
        this.gridSelection-=1;
      } else{
        this.gridSelection+=1;
      }
     
    }
   
  }

  /**
   * Attribution du statut checkbox checked pour les données du grid
   */
  private ChangeGridPrecoSelection(_iSetChecked:boolean){
    if(this.gridPrecos.length){
      this.gridPrecos.forEach((fPreco:any)=>{
        fPreco.IsChecked = _iSetChecked == true;
      });
    }
    this.updateSelectionCounter();
  }

  /**Comptabilise le nombre de preco du grid sélectionné */
  private updateSelectionCounter(){
    this.gridSelection = 0;
    if(this.gridPrecos.length){
      this.gridSelection = this.gridPrecos.filter(r=>r.IsChecked == true).length;
    }
  }
  /**
   * Controle si on est dans un cas d'export multiformat
   * plus d un format
   * @return bool
   */
  private IsModeMultiFormat() {
    return this.CountCheckBoxSelected() > 1;
  }

  private CountCheckBoxSelected(): number {
    let selections: Boolean[] = [this.ChkOtherPrecos, this.ChkPrecosFerti, this.ChkPilotSpPreco, this.ChkInterModePreco, this.ChkFarmerPrevis];
    return HandleService.CountOccurenceKey(selections, true);
  }

  /**
   * Controle qu il dispose d au - 1 modulation Micro
   * @param CultivationPlot
   * @param iModIdGroupe
   */
  private IncludeMcModulationDoseOnIdGroup(CultivationPlot: CultivationPlot, iModIdGroupe: any): boolean {
    if (CultivationPlot.MicroPlots && CultivationPlot.MicroPlots.length) {
      return CultivationPlot.MicroPlots.findIndex(mc => mc.Preconisations && this.IncludeFertiDose(this.GetModulatByIdGroup(mc.Preconisations, iModIdGroupe), true)) > -1;
    }
    return false;
  }


  /**
    * Contrôle que la plot dispose d au - 1 micro avec 1 dose
    * selon preco Ident
    * @param CultivationPlot
    * @param iIdentPreco Plot Preco Ident
    */
  private IncludeMcPrecoDoseOnIdent(CultivationPlot: CultivationPlot, iIdentPreco: number): boolean {
    if (CultivationPlot.MicroPlots && CultivationPlot.MicroPlots.length) {
      return CultivationPlot.MicroPlots.findIndex(mc => mc.Preconisations && this.IncludeFertiDose(this.GetPrecoByIdent(mc.Preconisations, iIdentPreco), true)) > -1;
    }
    return false;
  }

  /**
  * Contrôle que la plot dispose d au - 1 micro avec 1 dose
  * selon previs Ident
  * @param CultivationPlot
  * @param iIdentPreco Plot Preco Ident
  */
  private IncludeMcPrevisDoseOnIdent(CultivationPlot: CultivationPlot, iIdentPrevis: number): boolean {
    if (CultivationPlot.MicroPlots && CultivationPlot.MicroPlots.length) {
      return CultivationPlot.MicroPlots.findIndex(mc => mc.InterPrevis && this.IncludeFertiDose(this.GetPrecoByIdent(mc.InterPrevis, iIdentPrevis), true)) > -1;
    }
    return false;
  }

  /**
   * Contrôle que la preco existe avec un ferti et une dose à celui ci
   * @param Preconisation
   * @param blCheckOver0Value Contrôle la présence d une dose Exclusivement superieur à 0 , si NON pas de contrôle de la valeur
   */
  private IncludeFertiDose(Preconisation: any, blCheckOver0Value: boolean): boolean {
    return (!this.IsEmptyFertiDose(Preconisation) && (blCheckOver0Value == false || Number(Preconisation.Fertilizer.Dose) > 0));
  }
  private IsEmptyFertiDose(Preconisation: any) {
    return (!Preconisation || !Preconisation.Fertilizer || (Preconisation.Fertilizer.Dose === null || Preconisation.Fertilizer.Dose === undefined))
  }

  /**
   * Retourne la micro preco selon son Ident
   * @param Preconisations ensemble des micro preconisations
   * @param _iPrecoIdent l'ident de la préconisation recherchée
   * @returns la préconisation avec cet Ident
   */
  private GetPrecoByIdent(Preconisations: mcPreconisation[], _iPrecoIdent: any) {
    return Preconisations.find(t => this.EqualByIdent(t, _iPrecoIdent));
  }

  /**
  * Retourne la micro modulation selon son IdGroup
  * @param Preconisations ensemble des micro modulations
  * @param _iPrecoIdent l'IdGroup de la modulation recherchée
  * @returns la modulation avec cet idGroup
  */
  private GetModulatByIdGroup(Modulations: mcPreconisation[], _iModIdGroup: any) {
    return Modulations.find(t => this.EqualByIdGroupe(t, _iModIdGroup));
  }
  private EqualByIdent(_imcPreco: mcPreconisation, _iIdent: number): boolean {
    return _imcPreco.Ident && _imcPreco.Ident != 0 && _imcPreco.Ident == _iIdent;
  }
  private EqualByIdGroupe(_imcPreco: mcPreconisation, _iIdGroupe: string): boolean {
    return _imcPreco.IdGroupe && isNullOrEmptyString(_imcPreco.IdGroupe) == false && _imcPreco.IdGroupe == _iIdGroupe;
  }

  private LogOnAnalytics(_iParams: PrecizionRequest) {
    if (this.selectedFormat && _iParams) {
      let _format = !this.isConsole ? this.selectedFormat : this.selectedConsole ? this.selectedConsole.id : "";
      this.googleService.LogExportTerminal(_iParams.Products.length, _format, _iParams.blMergePrecos, _iParams.BufferSize);
    }
  }
}
