import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsComponent } from './components.component';
import { MapsideComponent } from './mapside/mapside.component';
import { AppSharedMapComponent } from './map/map.component';
import { SharedMapService, SelectableService, AttributToTextService, NullToEmptyString } from './../../_services/index';
import { AppSharedMapPopupComponent } from './popup/popup.component';
import { ConsoleWindowComponent } from './consolewindow/consolewindow.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from "@angular/forms";
import { PrecizionServices, ExportPdfService } from './../../_services/index';
import { ExportwindowComponent } from './exportwindow/exportwindow.component';
import { LoadingService } from '../../framework/auth/components/loading/loading.service';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DuplicatePreconisationComponent } from './farmerprevis/duplicatepreconisation.component';
import { UpdateFarmerPrevisComponent } from './farmerprevis/updatefarmerprevis.component';

@NgModule({
  imports: [
    CommonModule,
    WindowModule,
    ButtonsModule,
    GridModule,
    DropDownsModule,
    FormsModule,
    InputsModule
  ],
  declarations: [ComponentsComponent, MapsideComponent, AppSharedMapComponent, NullToEmptyString, AppSharedMapPopupComponent, ConsoleWindowComponent, ExportwindowComponent, DuplicatePreconisationComponent,UpdateFarmerPrevisComponent],
  exports: [
    AppSharedMapComponent,
    MapsideComponent,
    NullToEmptyString,
    ConsoleWindowComponent,
    ExportwindowComponent,
    DuplicatePreconisationComponent,
    UpdateFarmerPrevisComponent
  ],
  providers: [SharedMapService, SelectableService, AttributToTextService, PrecizionServices, ExportPdfService, LoadingService]
})
export class ComponentsModule { }
