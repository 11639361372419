import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ExportPdfService,LegendsConfig, HandleService, GoogleAnalyticsService, ModalService } from './../../../_services/index';
import { PrintManager, PrintPdfExtraModel } from '../../../_models/print/print_manager';
import { LoadingService } from '../../../framework/auth/components/loading/loading.service';
import { Farm, farmConfig } from '../../../_models/index';

@Component({
  selector: 'export-window',
  templateUrl: './exportwindow.component.html',
  styleUrls: ['./exportwindow.component.scss']
})
export class ExportwindowComponent implements OnInit {

  @Input()
  public opened: boolean;

  @Input()
  public ilot: Farm[];

  @Input()
  public FilterPrestationsTypes: String[];
  /**Types de modulation */
  @Input()
  public ModulationPrecosTypes:any[];
  @Input()
  public DiagnosticPrestTypes:any[]
  @Output()
  public DialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**Interventions en mode préconisation */
  @Input()
  public IntersModePrecoTypes:any[];

  /**Les impressions supplémentaires formatées */
  @Input()
  public ExtraPrintModels:PrintPdfExtraModel[];

  protected selectedExportType: string;


  public PilotSpAdviceNCode = PrintManager.code_Print_Advice_Sp_LAI;

  constructor(private _exportPdfService: ExportPdfService, private _loadingService: LoadingService,private googleService : GoogleAnalyticsService,private modaleService:ModalService) { }

  ngOnInit() {

  }

  public close() {
    this.opened = false;
 
    this.DialogClosed.emit(this.opened);
  }

  public open() {
    this.opened = true;
 
  }

  protected submit() {
    if (!this.opened)
      return;
      this._loadingService.show("Merci de patienter, la génération du PDF est en cours.");
      //Modulation Preco Type
      let ModPrecoType=null;
       //Modulation Pdf Title
      let _modPdfTitle=null;
      let _filPrestType:string=null;
      //type d impression à exporter
      let _dwnldExportType=this.selectedExportType;
      let _blFound:boolean=false;
      
      if(this.selectedExportType)
      {
        if(this.ModulationPrecosTypes && this.ModulationPrecosTypes.length>0)
        {
          let Dataprecos=this.ModulationPrecosTypes.find(t=>t.value==this.selectedExportType);
          if(Dataprecos )
          {
            if(Dataprecos.code !== LegendsConfig.legPilotPrecoCode) {
              //Traitement des preco de modulation
              _dwnldExportType= Dataprecos.value == this._exportPdfService.propPDFInterModePrecoName ? Dataprecos.value : PrintManager.code_Print_Modulation;
              ModPrecoType=Dataprecos.code;
              _modPdfTitle='be Api - '+Dataprecos.value;
              _blFound=true;
            }else {
              _dwnldExportType=PrintManager.code_Print_Preco_Sp_LAI;
              ModPrecoType = null;
              _blFound=true;
            }
         
          }
        }

        if(this.IntersModePrecoTypes && this.IntersModePrecoTypes.length > 0)
        {
          let DataInterPrecos = this.IntersModePrecoTypes.find(t=>t.ModePreco == true);
          if(DataInterPrecos)
          {//Traitement des preco de modulation
            _dwnldExportType=PrintManager.code_Print_Inter_Mode_Preco;
            ModPrecoType = null;
            _modPdfTitle='be Api - Fichiers de modulation organique';
            _blFound=true;
          }
        }

       
         if(!_blFound && this.DiagnosticPrestTypes && this.DiagnosticPrestTypes.length>0)
        {
          let DataDiagnostic=this.DiagnosticPrestTypes.find(t=>t.value==this.selectedExportType);
          //impression des diagnostics
          if(DataDiagnostic)
          {
            //Traitement diagnostic pilot LAI ou Annuals
            _dwnldExportType= DataDiagnostic.code == farmConfig.propPrest_Pilot_Azote_SP_LAI ? PrintManager.code_Print_Diag_Sp_LAI : PrintManager.code_Print_Diag_Annuals;
            _filPrestType=DataDiagnostic.code;
            _modPdfTitle=this.formatDiagnosticPdfTitle(DataDiagnostic);
            _blFound=true;
          }
        }
      }
      this.LogOnAnalytics(_dwnldExportType);
    this._exportPdfService.ExportPdf(_dwnldExportType, this.ilot,ModPrecoType,_modPdfTitle,_filPrestType).subscribe(
      res => {
        this._exportPdfService.Downlaod(res);
        this._loadingService.hide();
      },
      error => {
        this._loadingService.hide();
        this.UserAlerte("Une erreur est survenue durant la génération.", true);

      }
    )
      ;
  }

  private formatDiagnosticPdfTitle(_iDiagData:any):String
  {
    let oReturn='be Api - ';
    let sfx=_iDiagData.value;
   if(_iDiagData.code)
   {
    switch (_iDiagData.code) {
      case farmConfig.propPrest_AnnAzote:
        sfx =LegendsConfig.pdfAnnaAzote;
        break;
      case farmConfig.propPrest_AnnRend:
        sfx =LegendsConfig.pdfAnnaRend;
        break;
        case farmConfig.propPrest_AnnCarboN:
          sfx =LegendsConfig.pdfAnnaCarboN;
          break;
      case farmConfig.propPrest_Pilot_Azote_SP_LAI:
        sfx =LegendsConfig.pdfDiagAzoteLAI;
        break;
      default:
        sfx =_iDiagData.value;
        break;
    }
    }
    return oReturn+sfx;
  }

  ///Formatage du nom de preconisation type pour interface
  public formatePrecoName(oPrecoName) {
    return this.CapitalizeFirstCarac(oPrecoName.toLowerCase().replace("azote sp","Azote SP")) ;
  }

  public includePrestaPilotSP(_iPrestations:any[]):boolean {
    return _iPrestations && _iPrestations.includes(farmConfig.propPrest_Pilot_Azote_SP_LAI);
  }

  private LogOnAnalytics(_iDownloadType: string)
  {
    if(_iDownloadType)
    {
      this.googleService.LogPrintPDF(_iDownloadType);
    }
  }

   /**
   * show user message
   * @param _userMessage user message text
   * @param _iblAddContactSupport add text contact support if problem persist
   */
   private UserAlerte(_userMessage: string, _iblAddContactSupport: boolean = true) {

    let msg = _userMessage;
    if (_iblAddContactSupport == true) {
      msg += " Si le problème persiste, veuillez contacter votre support be Api."
    }
    this.modaleService.Error('Erreur export PDF', msg)

  }

  /**mise en capitale de la 1e lettre du mot */
  public CapitalizeFirstCarac(_oName:string):string {
    return HandleService.CapitalizeFirstCarac(_oName);
  }
}
