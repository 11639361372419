import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { groupItem } from './_models/groupItem';
import { menuItem, menuType } from './_models/menuItem';
import { itemValue } from './_models/itemValue';

@Injectable()
export class JarvisUiService {

    private keepAfterNavigationChange = false;

    public updateMenuComponent: Observable<any>;
    public subjectMenuComponent: Subject<any> = new Subject<any>();

    public Menus: Array<menuItem> = new Array<menuItem>();
    constructor() {
        this.updateMenuComponent = this.subjectMenuComponent.asObservable();
    }

    public Clear(): void {
        this.Menus = [];
    }

    AddMenu(code: string, title: string, checkable: menuType, multipleGroupSelected: boolean = false): menuItem {
        var menu: menuItem = new menuItem();
        menu.Code = code;
        menu.Title = title;
        menu.Checkable = checkable;
        menu.MultipleGroupSelected = multipleGroupSelected;

        this.Menus.push(menu);
        this.subjectMenuComponent.next("addmenu");
        return menu;
    }

    AddGroup(menuCode: string, id: string, title: string): groupItem {
        var group: groupItem = null;
        var menu = this.Menus.find(m => m.Code == menuCode);
        if (menu != null) {
            group = new groupItem();
            group.Id = id;
            group.Items = new Array<itemValue>();
            group.Title = title;
            menu.GroupItems.push(group);
        }
        this.subjectMenuComponent.next("addgroup");

        return group;
    }
    hideMenu(codeMenu: string) {
        this.Menus.find(m => m.Code == codeMenu).Visible = false;
    }
    removeAllItems(codeMenu: string) {
        this.Menus.find(m => m.Code == codeMenu).GroupItems = new Array<groupItem>();
    }

    getSelectedModels(codeMenu: string): Array<any> {
        var menu = this.Menus.find(m => m.Code == codeMenu);
        if (menu != null) {
            var modelSelected = new Array<any>();
            menu.GroupItems.forEach(g => {
                modelSelected = modelSelected.concat(g.Items.filter(i => i.Checked).map(t => t.Model));
            });
            return modelSelected;
        }
    }

    getSelectedItems(codeMenu: string): Array<itemValue> {
        var itemsSelected = new Array<itemValue>();
        var menu = this.Menus.find(m => m.Code == codeMenu);
        menu.GroupItems.forEach(g => {
            itemsSelected = itemsSelected.concat(g.Items.filter(i => i.Checked));
        });
        return itemsSelected;
    }
    AddItem(menuCode: string, model: any, groupId: string = "", id: string, value: string, checked: boolean = false, image: string = "", title: string = ""): itemValue {
        var item: itemValue = null;
        var menu = this.Menus.find(m => m.Code == menuCode);
        if (menu != null) {
            var group: groupItem = null;
            if (groupId == "") {
                var defaultGroup = menu.GroupItems.find(g => g.Id == "default");
                if (defaultGroup != null)
                    group = defaultGroup;
                else {
                    group = new groupItem();
                    group.Id = "default";
                    group.Items = new Array<itemValue>();
                    group.Title = "Default";
                    menu.GroupItems.push(group);
                }
            }
            else
                group = menu.GroupItems.find(g => g.Id == groupId);

            item = new itemValue();
            item.Id = id;
            item.Value = value;
            item.Checked = checked;
            item.Model = model;
            item.Image = image;
            item.Title = title;
            group.Items.push(item);
        }
        this.subjectMenuComponent.next("additem");
        return item;

    }

    /**
     * Ordonne le Menu si il existe
     * @param menuCode le code du menu
     */
    Order(menuCode: string) {
        var menu = this.Menus.find(m => m.Code == menuCode);
        if (menu != null) {
            menu.Order(true);
        }
    }

    /**
 * Pour le menu sans groupement (sinon le 1e groupement)
 * contrôle que son dernier élément est sélectionné
 * @param _iMenuCode 
 */
    public IsLastRecordSelected(_iMenuCode: string): boolean | null {
        var menu = this.Menus.find(m => m.Code == _iMenuCode);
        if (menu != null && menu.GroupItems?.length) {
            let records = menu.GetItemsGroup(0);
            if (records !== null && records.length > 0) {
                if (records.length == 1) {
                    return true;
                } else {
                    return records[records.length - 1].Checked == true;
                }
            }
        }
        return null;
    }
}
