import { Sector } from './sector'
import { Legend } from './legend';
import { CultivationPlot } from '..';

export class Farm {

    IdClient: string;
    CoopCodeName: string;
    CoopSiretNumber: string;
    NameExploitation: string;
    Commune: string;
    IdExploitation: string;
    UserId: string;
    IdCampagne: number;
    DiagCreationDate: Date;
    Sectors: Array<Sector>;
    Legends: Array<Legend>;
    CoopName: string;
    PrestationType: String;
    Step: number;
    OrderUid: string;
    /**
     *farm: input parameter
     */
    constructor(farm: any) {

        if (farm === null || farm === undefined)
            return;
        //TODO: should throw exception
        if (farm.IdExploitation === 'null' || farm.IdExploitation === undefined)
            this.IdCampagne = 0;
        else
            this.IdCampagne = farm.IdCampagne;
        if (farm.NameExploitation === 'null')
            this.NameExploitation = 'Farm Name Not Defined';
        else
            this.NameExploitation = farm.NameExploitation;
        this.IdClient = farm.IdClient;
        this.CoopSiretNumber = farm.CoopSiretNumber;
        this.CoopCodeName = farm.CoopCodeName;
        this.CoopName = farm.CoopName;
        this.Commune = farm.Commune;
        this.IdExploitation = farm.IdExploitation;
        this.UserId = farm.UserId;
        this.IdCampagne = farm.IdCampagne;
        this.Sectors = [];
        if (farm.Sectors && farm.Sectors.length) {
            farm.Sectors.forEach((jsonSector: any) => {
                let convSector = this.MapObjectSector(jsonSector);
                if (convSector)
                    this.Sectors.push(convSector);
            });
        }
        this.Legends = this.SetLegends(farm.Legends);
        this.DiagCreationDate = new Date(farm.DiagCreationDate);
        this.Step = farm.Step;
        this.PrestationType = farm.PrestationType != null && farm.PrestationType != undefined ? farm.PrestationType.trim() : null;
        this.OrderUid = farm.Id;
    }

    /**
     * Mapping de l'ilot et des parcelles
     * ainsi on accède aux fonction jusqu'aux parcelles
     * @param iJsonSector 
     * @returns 
     */
    private MapObjectSector(iJsonSector: any) {
        let oSector = new Sector();
        oSector = Object.assign(new Sector, iJsonSector)
        if (iJsonSector?.CultivationPlots.length) {
            oSector.CultivationPlots = [];
            iJsonSector?.CultivationPlots.forEach(element => {
                oSector.CultivationPlots.push(new CultivationPlot(element))
            });
        }
        return oSector;
    }

    /**
     * Attribution des legendes
     * @param legends
     */
    SetLegends(legends: any): any {
        if (legends == null)
            return legends;

        legends.forEach((legend: any) => {
            switch (legend.Name) {
                case 'P2O5_OL':
                    legend.Name = 'P2O5 Olsen';
                    break;
                case 'PH_EAU':
                    legend.Name = 'pH';
                    break;
                case 'K2O_MGO':
                    legend.Name = 'K2O/MgO';
                    break;
                default:
                    break;
            }
            let previousLegendMaxValue = 0;
            legend.Class.forEach(element => {
                element.MaxValue = Math.round(element.MaxValue * 100) / 100;
                element.MinValue = previousLegendMaxValue;
                previousLegendMaxValue = element.MaxValue;
                element.Color = "rgb(" + Math.round(element.Red)
                    + "," + Math.round(element.Green)
                    + "," + Math.round(element.Blue) + ")";
            });
        });
        return legends;
    }
    /**
       *Contrôle que l' Order.PrestationType est FERTI
       */
    public PresTypeFerti(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_Ferti));
    }

    /**
   *Contrôle que l' Order.PrestationType est POTENTIEL
   */
    public PresTypePotentiel(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_Potentiel));
    }
    /**
 *Contrôle que l' Order.PrestationType est PACK
 */
    public PresTypePack(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_Pack));
    }

    public PresTypeModulation(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_Modulation));
    }

    /**
     * Indique que c est un diagnostic Pilot Sp LAI
     * Step 8
     * @returns boolean
     */
    public IsDiagPilotSPLAI() {
        return this.Step == 8 && (this.EqualPrestaType(farmConfig.propPrest_Pilot_Azote_SP_LAI));
    }

    /**
     * Retourne si il est un diagnostic (données annuelles ou diag pilot LAI)
     * @returns
     */
    public IsDiagnostic() {
        return (this.IsAnnualOrder() || this.IsDiagPilotSPLAI());
    }
    /**
     * Indique que c est un Order Pilot Sp LAI
     * Step 9
     * @returns boolean
     */
    public IsOrderPilotSPLAI() {
        return this.Step == 9 && (this.EqualPrestaType(farmConfig.propPrest_Pilot_Azote_SP_LAI));
    }

    /**
     * indique si c est un diagnostic annuel (Rendement ou Azote)
     * @returns
     */
    public IsAnnualOrder() {
        return this.PresAnnualAzote() || this.PresAnnualRdt() || this.PresAnnualCarboN();
    }
    /**Indique que c est un diagnostic annuel Rendement */
    public PresAnnualRdt(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_AnnRend));
    }

    /**Indique que c est un diagnostic annuel Azote */
    public PresAnnualAzote(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_AnnAzote));
    }

    /**Indique que c est un diagnostic annuel CarboN */
    public PresAnnualCarboN(): boolean {
        return (this.EqualPrestaType(farmConfig.propPrest_AnnCarboN));
    }

    private EqualPrestaType(ReqPrestation: string): boolean {
        return (this.PrestationType != null && this.PrestationType.toUpperCase() == ReqPrestation);
    }


    //**Retourne si il dispose d'au moins 1 intervention organique en mode preconisation*/
    public HasModePreco(): boolean {
        return (this.Sectors.length && this.Sectors.findIndex((r: Sector) => r.CultivationPlots.findIndex((pl: CultivationPlot) => pl.Interventions && pl.Interventions.length && pl.Interventions.findIndex(r => r.ModePreco === true) > -1) > -1) > -1);
    }

    public HasPreco(): boolean {
        return (this.Sectors.length && this.Sectors.findIndex((r: Sector) => r.CultivationPlots.findIndex((pl: CultivationPlot) => pl.Preconisations && pl.Preconisations.length) > -1) > -1);
    }

    /**
     * Retourne si la légende existe selon le nom indiqué
     * @param _iReqLegendName
     * @returns
     */
    public IncludeLegendName(_iReqLegendName): boolean {
        return this.Legends && this.GetLegendByName(_iReqLegendName) != null;
    }

    /**
     * Retourne la legend si présente selon le nom
     */
    public GetLegendByName(_iReqLegendName): Legend {
        return this.Legends.find((lg: Legend) => lg.Name && lg.Name == _iReqLegendName)
    }
    /**
     * Indique s'il dispose d'au moins 1 intervention previsionnelle
     * tout type (tech preco) confondus
     * @returns boolean
     */
    public HasInterPrevis(): boolean {
        return (this.Sectors.length && this.Sectors.findIndex((r: Sector) => r.CultivationPlots.findIndex((pl: CultivationPlot) => pl.ExistInterPrevis()) > -1) > -1);
    }

}

export class farmConfig {
    /**Nom de la propriete PrestationType BeApi rendement 'BEAPI_RDT' */
    public static propPrest_AnnRend = "BEAPI_RDT";

    /**Nom de la propriete PrestationType BeApi Azote Sp 'BEAPI_AZOTE_SP' */
    public static propPrest_AnnAzote = "BEAPI_AZOTE_SP";

    /**Nom de la propriete PrestationType BeApi Carbo N */
    public static propPrest_AnnCarboN = "BEAPI_CARBON";

    /**Nom de la propriete PrestationType Pilot BeApi Azote Sp LAI 'BEAPI_PILOT_SP' Step 8 & Step 9 */
    public static propPrest_Pilot_Azote_SP_LAI = "BEAPI_PILOT_SP";

    /**Nom de la propriete PrestationType Prestation Conseil Ferti (PK) Step 2 & Step 4*/
    public static propPrest_Ferti = "BEAPI_FERTI";
    /**Nom de la propriete PrestationType Prestation Conseil Potentiel (N) Step 2 & Step 4*/
    public static propPrest_Potentiel = "BEAPI_POTENTIEL";

    /**Nom de la propriete PrestationType Prestation Conseil Ferti & Potentiel (PK & N) Step 2 & Step 4*/
    public static propPrest_Pack = "BEAPI_PACK";

     /**Nom de la propriete PrestationType Prestation Modulation */
     public static propPrest_Modulation = "BEAPI_MODULATION";

}

