import { Geometry } from "./geometry";
import { CultivationPlot } from "./cultivationplot";

export class Sector{
    Id:number;
    PacNumber:number;
    Name:string;
    DepartmentNumber:number;
    CodeInsee:number;
    CodePostal:number;
    Surface:number;
    PacSurface:number;
    Geometry:Geometry
    CultivationPlots:Array<CultivationPlot>

}