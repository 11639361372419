import { HandleService } from "../../_services";
import { farmerPrevisOrderPreco, farmerPrevisOrderSector, CultivationPlot, InterPrevi } from "../index";

/**class des données du grid des farmer previs */
export class gridFarmerPrevisDatas {

    
    private _areaUnit = "ha";
    //#region properties
    //les enregistrements du grid à afficher
    private _records: grdFarmPlotPreviDatas[] = [];
    /**dose moyenne sur les parcelles sélectionnées calculée de produit */
    private _totalsSelectedDose: number = 0;
    /**total de quantité sur les parcelles sélectionnées de produit  */
    private _totalsSelectedQte: number = 0;

    /**total de surface sur les parcelles sélectionnées  */
    private _totalsSelectedAreas: number = 0;

    /**unité du produit */
    private _unit: string = "";
    /**reprend par id de preconisation source le nom des produits formaté */
    private _OriginsProducts: Record<number, string> = [];

    //#endregion
   /**
    * retourne la liste des nom de produits formatés de préco d'origine distinct
    * @param _iRemoveDeleted true: on supprime le nom de produit 'supprimé' de la liste SI il y a plus d'un produit retourné(incluant le supprimé)
    * @returns 
    */
    public GetOriginsProducts(_iRemoveMultiDeleted:boolean): string[] | null {
        if (Object.keys(this._OriginsProducts)?.length) {
            let products=  HandleService.DistinctValues(Object.values(this._OriginsProducts));
            if(_iRemoveMultiDeleted && products.length >1){
                products = products.filter(r=> r!== grdFarmPlotPreviDatas.TEXT_PRECO_DELETED);
            }
            return products;
        } else {
            return null;
        }
    }

    /**
     * Retourne le seul code produit de preco
     * si false il a plus d'un produit présent, string il a un seul code produit, et null plus aucun produit de preco présent
     * @returns 
     */
    public GetUniqueOriginProdCode():string|null|false{
        let oCodes = this.GetOriginsProductsCodes();
        return oCodes?.length == 0 ? null : (oCodes?.length ==1 ? oCodes[0] : false) 
    }

    /**
     * Retourne la liste distincte des codes produits des preconisations d'origine
     * @returns string[]
     */
    public GetOriginsProductsCodes():string[]{
        let oCodes:string[] =[];
        if(this.Exists){
            oCodes =HandleService.DistinctValues(this._records.map(r=>r.PrecoProductCode));
            return oCodes.filter(r=> r!= null && r!= "" && r!= "-");

        } else {
            return oCodes;
        }
    }


    /**
    * @returns retourne la quantité de produit pour les prévis sélectionnées
    */
    public GetTotalSelectedQte(): number {
        return this._totalsSelectedQte;
    }

    /**
    * @returns retourne la quantité de produit pour les prévis sélectionnées avec son unité
    */
    public GetTotalSelectedQteUnit(): string {
        return HandleService.RoundDecimalsView(this._totalsSelectedQte, 2) + " " + this._unit
    }

    /**
    * @returns retourne la surface totale des micros pour les prévis sélectionnées avec son unité
    */
    public GetTotalSelectedAreas(): string {
        return HandleService.RoundDecimalsView(this._totalsSelectedAreas, 2) + " " + this._areaUnit
    }

    /**
    * @returns retourne la dose de produit pour les prévis sélectionnées avec son unité
    */
    public GetTotalSelectedDoseUnit(): string {
        return HandleService.RoundDecimalsView(this._totalsSelectedDose, 2) + " " + this._unit + "/" + this._areaUnit
    }

    /**Retourne les previsionnelles  */
    public get Previs(): grdFarmPlotPreviDatas[] {
        return this._records
    }

    /**Retourne le nombre de previs selectionnées */
    public GetSelectedCounter(): number {
        return this._records.filter(r => r.selected == true)?.length
    }

    /**Retourne les prévis sélectionnées */
    public GetGridSelection(): grdFarmPlotPreviDatas[] {
        return this._records.filter(r => r.selected == true);
    }

    ///retourne si dispose de données
    public Exists() {
        return this._records?.length > 0;
    }

    /**
     * init
     * @param _iFarmerPrevis les données grid
     * @param _iComputePrevisTotals indique si on calcul les totaux des previs présent ainsi que remplit les produits origine
     */
    constructor(_iFarmerPrevis: grdFarmPlotPreviDatas[] | null = null, _iComputePrevisTotals: boolean) {
        if (_iFarmerPrevis?.length) {
            this._records = _iFarmerPrevis;
        }
        if (_iComputePrevisTotals == true) {
            this.ComputeSelectedPrevisTotals();
            this.BindOriginalProducts();
        }
    }

    /**Ordonne selon le nom exploitaton puis pacelle avec les dispos en 1e */
    public OrderByPlotNames() {
        if (this._records.length > 1) {
            this._records = this._records.sort(function (n1, n2) {
                let frmPlotName1 = n1.FarmName + " " + n1.PlotName;
                let frmPlotName2 = n2.FarmName + " " + n2.PlotName;
                let formated1 = n1.enabled ? frmPlotName1 : 'zzzzz' + frmPlotName1;
                let formated2 = n2.enabled ? frmPlotName2 : 'zzzzz' + frmPlotName2;

                return (formated1 < formated2) ? -1 : (formated1 > formated2) ? 1 : 0;
            });
        }
    }

    /**
     * Calcul les totaux des données sélectionnées
     * Surface dose et quantité
     */
    public ComputeSelectedPrevisTotals() {
        this._totalsSelectedQte = 0;
        this._totalsSelectedDose = 0;
        this._totalsSelectedAreas = 0;
        if (this._records?.length) {
            let SumQte = this._records.filter(s => s.selected).map(e => e.PrevisDose * e.MicrosAreas).reduce((SumQte, current) => SumQte += current, 0);
            let SumAreas = this._records.filter(s => s.selected).map(e => e.MicrosAreas).reduce((SumQte, current) => SumQte += current, 0);
            this._totalsSelectedDose = SumAreas > 0 ? SumQte / SumAreas : 0;
            this._totalsSelectedQte = SumQte;
            this._unit = this._records[0].PrevisProductUnit;
            this._totalsSelectedAreas = SumAreas;
        }
    }

    /**
     * Change le status de selection des donnée selon les UniqueIds passé
     * sur null deselectionne le tout
     * @param _iUniqueIds si null déselectionne si liste sélectionne en fonction de la lsite passée
     */
    public ChangeSelection(_iUniqueIds: string[] | null) {
        if (_iUniqueIds !== null) {

            if (_iUniqueIds.length > 0) {
                this.Previs.forEach(fPrevis => {
                    fPrevis.selected = _iUniqueIds.includes(fPrevis.UniqueId)
                })
            }
        } else {
            this.Previs.forEach(fPrevis => {
                fPrevis.selected = false
            })
        }
    }

    /**Constitution du dictionnaire des produits */
    private BindOriginalProducts() {
        this._OriginsProducts = [];
        if (this.Exists()) {
            let srcIds = HandleService.DistinctValues(this._records.map(t => t.PrevisSrcId));
            srcIds.forEach(fPrecoId => {
                let sp = this._records.find(z => z.PrevisSrcId == fPrecoId);
                if (!sp.PrecoDate && !sp.PrecoProductCode && sp.PrecoProduct && sp.PrecoProduct.trim() !== '-') {
                    this._OriginsProducts[Number(fPrecoId)] = grdFarmPlotPreviDatas.TEXT_PRECO_DELETED;
                } else {
                    this._OriginsProducts[Number(fPrecoId)] = sp.PrecoProduct + " - " + sp.PrecoDate;
                }
            })
        }
    }

}
//donnée du grid d'une inter previs farmer
export class grdFarmPlotPreviDatas {

    //texte affiché sur une preconisation technicien supprimée
    static TEXT_PRECO_DELETED = "supprimée";
    //#region properties
    //#region Grid management
    //id unique formaté
    public UniqueId: string;
    //si la prévis parcelle est sélectionnée dans le grid
    public selected: boolean;
     //si l'enregistrement est disponible dans le grid (non verouillé)
     public enabled: boolean;
     //texte affiché sur la checkbox non sélectionnable
     public cbxTooltip: string = "";
    //#endregion

    //#region order preco previs datas
    //id order
    public OrderId: string;
    //id cultivation plot
    public IdCultivationPlot: number;
    /**id de la preconisation source tech*/
    public PrecoId: number;
    /**Identifiant unique à la previs si existante son id_intervnetion sinon un id unique */
    public UniquePrevisId: number;
    /*si déja cree identifiant de la previs farmer*/
    public PrevisId: number | null;
    //ilot pac
    public SectorPac: number | null;
    //id ilot
    public SectorId: number | null;
    //nom de l'exploitation
    public FarmName: string;
    //nom de la parcelle
    public PlotName: string;
    /**Surface de la parcelle */
    public PlotArea: number;
    /**Somme des Surfaces des micros */
    public MicrosAreas: number;
    //nom de la culture
    public CultivationName: string;

    // #region données de la prévis
    //indique si le lien à la preco existe encore
    public IsPrecoLinked:boolean=true;
    //nom du produit de la preco (tech) source
    public PrecoProduct: string = "";
    //code du produit de la preco (tech)source
    public PrecoProductCode: string = "";
    //unité du produit de la preco (tech)source
    public PrecoProductUnit: string = "";
    public PrecoDate: string = "";
    //dose moyenne à la parcelle de la préco source
    public PrecoDose: number | null = null;

    //nom du produit de la preco (tech) source
    public PrevisProduct: string;
    //code du produit de la preco (tech)source
    public PrevisProductCode: string;
    //unité du produit de la preco (tech)source
    public PrevisProductUnit: string;
    public PrevisDate: string;
    //dose moyenne à la parcelle de la préco source
    public PrevisDose: number;
    //#endregion

    //#region combined

    //surface de la parcelle avec unité
    public PlotAreaWithUnit: string;
    //surface des micros de la parclle avec unité
    public MicrosAreasWithUnit: string;
    //dose du produit de la preco source avec unité
    public PrecoDoseUnit: string;
    //quantité du produit de la preco source avec unité
    public PrecoQteUnit: string;
    ////dose de la previs parcelle
    public PrevisDoseUnit: string;
    //qte de la previs parcelle avec unité
    public PrevisQteUnit: string;
    //qte de la previs parcelle
    public PrevisQte: number;

    //si la prévis est modulée au niveau de ses micros doses (doses différentes)
    public PrevisModulated: boolean = false;
    //#endregion
    /**retourne l'identifiant de la peco source de la previs actuelle */
    public get PrevisSrcId(): number {
        return this.PrecoId;
    }
    //#endregion

    /**
     * Initialisation d'un enregistrement du grid
     * @param _iOrder commande
     * @param _iCultivationPlot parcelle liée
     * @param _iPlotInterPrevi intervention previs
     * @param _iIsTechPrecos si on charge depuis duplication tech preco
     * @param _iCbxDisableTitle depuis chargement tech preco le texte a afficher sur une checkbox verouillée
     * @param _iSrcPreco la preconisation source dont il découle
     */
    constructor(_iOrder: farmerPrevisOrderPreco, _iSector: farmerPrevisOrderSector, _iCultivationPlot: CultivationPlot, _iPlotInterPrevi: InterPrevi, _iIsTechPrecos: boolean, _iCbxDisableTitle: string, _iSrcPreco: InterPrevi) {

        if (_iOrder == null && _iSector == null && _iCultivationPlot == null && _iPlotInterPrevi == null && _iIsTechPrecos == false) {
            //on est depuis l extension
            return;
        }
        this.cbxTooltip = "";

        //indique si farmer prévis initialisée (non présente en db)
        let isInitialPrevis = _iPlotInterPrevi.IsInitial();

        this.UniqueId = _iOrder.OrderId + "_" + _iCultivationPlot.IdCultivationPlot + "_" + _iPlotInterPrevi.SrcIdInter + "_" + _iPlotInterPrevi.type;

        this.OrderId = _iOrder.OrderId;
        this.SectorPac = _iSector.Pac ?? null;
        this.SectorId = _iSector.Id ?? null;
        this.FarmName = _iOrder.FarmName;
        this.IdCultivationPlot = _iCultivationPlot.IdCultivationPlot;
        this.PlotName = _iCultivationPlot.NameCultivationPlot;
        this.PlotArea = _iCultivationPlot.Surface;
        this.CultivationName = _iCultivationPlot.CultivationSelected?.Name;
        this.PrecoId = _iPlotInterPrevi.SrcIdInter;
        this.UniquePrevisId = _iPlotInterPrevi.UniquePrevisId;
        this.PrevisId = _iPlotInterPrevi.IdIntervention > 0 ? _iPlotInterPrevi.IdIntervention : null;
        this.selected = _iIsTechPrecos == false || isInitialPrevis;
        this.enabled = _iIsTechPrecos == false || isInitialPrevis;
        this.IsPrecoLinked = true;
        if (this.enabled == false && _iCbxDisableTitle !== null) {
            this.cbxTooltip = _iCbxDisableTitle;
        } else {
            this.cbxTooltip = "";
        }

        this.MicrosAreas = _iCultivationPlot.GetSumMicrosSurface();

        this.PlotAreaWithUnit = this.PlotArea !== null ? this.formatStringNumericUnit(this.PlotArea, " ha", false) : "";
        this.MicrosAreasWithUnit = this.MicrosAreas !== null ? this.formatStringNumericUnit(this.MicrosAreas, " ha", false) : "";
        if (_iIsTechPrecos == true) {
            this.PrecoProduct = null;
            this.PrecoProductCode = null;
            this.PrecoProductUnit = null;
            this.PrecoDate = null;
            this.PrecoDose = null;
            this.PrevisProduct = null;
            this.PrevisProductCode = null;
            this.PrevisProductUnit = null;
            this.PrevisDate = null;
            this.PrevisDose = null;
        } else {
            //en farmer modif
            if (_iSrcPreco) {

                this.PrecoProduct = _iSrcPreco.Fertilizer?.Name;
                this.PrecoProductCode = _iSrcPreco.Fertilizer?.code;
                this.PrecoProductUnit = _iSrcPreco.Fertilizer?.Unit;
                this.PrecoDate = HandleService.dateToString(new Date(_iSrcPreco.BeginDate)).toString()
                this.PrecoDose = _iSrcPreco.Fertilizer.Dose;
                this.PrecoQteUnit = this.PrecoDose !== null ? this.formatStringNumericUnit(this.PrecoDose * this.MicrosAreas, this.PrecoProductUnit, false) : "";
                this.PrecoDoseUnit = this.PrecoDose !== null ? this.formatStringNumericUnit(this.PrecoDose, this.PrecoProductUnit, true) : "";
            } else {
               
               
                if (_iPlotInterPrevi.IsPrecoSrcDeleted()) {
                    //la préconisation source plus présente
                    this.PrecoProduct = grdFarmPlotPreviDatas.TEXT_PRECO_DELETED;
                     
                } else {
                    this.PrecoProduct = '-';
                }
                this.IsPrecoLinked = false;
                this.PrecoProductCode = "";
                this.PrecoProductUnit = "";
                this.PrecoDate = "";
                this.PrecoDose = null;
            }

            this.PrevisProduct = _iPlotInterPrevi.Fertilizer?.Name;
            this.PrevisProductCode = _iPlotInterPrevi.Fertilizer?.code;
            this.PrevisProductUnit = _iPlotInterPrevi.Fertilizer?.Unit;
            this.PrevisDate = HandleService.dateToString(new Date(_iPlotInterPrevi.BeginDate)).toString();
            this.PrevisDose = HandleService.RoundDecimals(_iPlotInterPrevi.Fertilizer.Dose, 3);
            this.PrevisQte = HandleService.RoundDecimals(this.PrevisDose * this.MicrosAreas);

            this.PrevisQteUnit = this.PrevisDose !== null ? this.formatStringNumericUnit(null, this.PrevisProductUnit, false) : "";
            this.PrevisDoseUnit = this.PrevisDose !== null ? this.formatStringNumericUnit(null, this.PrevisProductUnit, true) : ""

            if (_iPlotInterPrevi && _iCultivationPlot) {
                this.PrevisModulated = _iCultivationPlot.IsPrevisModulated(_iPlotInterPrevi.Ident)
            } else {
                this.PrevisModulated = false;
            }
        }
    }

    /**format en un string avec l'unité */
    private formatStringNumericUnit(_iQte: number, _iUnit: string, _iblIsDose: boolean) {
        if (_iQte !== null) {
            return HandleService.RoundDecimalsView(_iQte, 2) + " " + _iUnit + (_iblIsDose ? "/ha" : "");
        } else {
            return " " + _iUnit + (_iblIsDose ? "/ha" : "")
        }
    }

}

