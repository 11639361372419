import { HandleService } from "../../_services";
import { mcPreconisation } from "../beapi/mcPreconisation";
import { ChangesFarmerPlotPrevis, ePrevisChanges,MicroPlot,Intervention,InterPrevi,farmConfig,CultivationPlot, } from "../index";

/**Class des farmer previs pour un order spécifique */
export class farmerPrevisOrderPreco {
    //#region properties
    /**Id order */
    public OrderId: string;
    /**campagne de l'order */
    public Campaign: number;
    /**Nom exploitation de l'order */
    public FarmName: string;
    /**Id exploitation de l'order */
    public FarmId: number;
    /**order prestation */
    public Prestation: string;
    /**ensemble des plots concernées par cette preco tech */
    public Sectors: farmerPrevisOrderSector[];
    //#endregion
    
    /**
     * Init
     * @param element données à initialiser lui et ses sous objets
     */
    public constructor(element: farmerPrevisOrderPreco | null) {
        this.Sectors = [];
        if (element) {
            this.OrderId = element.OrderId;
            this.FarmName = element.FarmName;
            this.Campaign = element.Campaign,
                this.Prestation = element.Prestation,
                this.FarmId = element.FarmId;
            element.Sectors.forEach(fSec => {
                this.Sectors.push(new farmerPrevisOrderSector(fSec));
            })
        }
    }

    /**
    * Indique si il dispose d'ilot
    * @param _iExistPrevis true on contrôle aussi la présence de prévis parcellaire
    * @returns 
    */
    public Exists(_iExistPrevis: boolean) {
        return this.Sectors?.length > 0 && (!_iExistPrevis || this.Sectors.findIndex(a => a.Exists(true)) != -1);
    }

    //#region Compute Changes

    /**
     * Application de la modificationsur la|les prévis pour les ilots présent si concernés
     * @param _iChangedPrevis la donnée du grid ou null pour les modif globale ou app mass
     * @param _ieTypeChange type de changement
     * @returns le nombre de parcelles avec une prévis modifiées
     */
    public ApplyChanges(_iChangedPrevis: ChangesFarmerPlotPrevis | null, _ieTypeChange: ePrevisChanges): number {

        let oChanges = 0;
        this.Sectors.forEach((fSector: farmerPrevisOrderSector) => {
            oChanges += fSector.ApplyChanges(_iChangedPrevis, _ieTypeChange);
        })
        return oChanges;
    }

    /**
     * Indique si l'order est de type conseil (PK &|N)
     * @returns 
     */
    public IsAdvicePrestation() {
        let advicePrestats = [farmConfig.propPrest_Ferti, farmConfig.propPrest_Potentiel, farmConfig.propPrest_Pack];
        return advicePrestats.includes(this.Prestation);
    }

    /**
    * Indique si l'order est de type pilotage azote SP
    * @returns 
    */
    public IsPilotSPPrestation() {

        return this.Prestation == farmConfig.propPrest_Pilot_Azote_SP_LAI;
    }

    /**Initialise les préconisations depuis les inter previs parcellaire (pas les micros) ainsi on garde le lien avec sa preco */
    public InitPreconisationsFromPrevis() {
        this.Sectors.forEach(fSec => {
            this.Sectors.forEach(s => {
                if (s.Exists(true)) {

                    s.Plots.forEach(oPlot => {
                        oPlot.Preconisations = [];
                        oPlot.InterPrevis.forEach(fPrevis => {
                            let onewPreco = new Intervention(fPrevis, false);
                            onewPreco.IdIntervention = fPrevis.SrcIdInter;
                            oPlot.Preconisations.push(onewPreco);
                            //on ne constitue pas aux micros pas utile
                        }

                        )
                    })
                }
            })
        })
    }

    //#endregion

     /**
     * retourne pour les ilots présente la liste qui ont leur dose micros toutes à 0
     * @returns 
     */
     public ExistAllDosesMicrosEmpty():string[]|null{

        let oEmptySecPrevis = [];
        if(this.Exists(true)){
            this.Sectors.forEach(fSec=> {
                let plotPrevisNoDoses = fSec.ExistAllDosesMicrosEmpty();
                if(plotPrevisNoDoses?.length > 0){
                    oEmptySecPrevis.push(plotPrevisNoDoses)
                }
            }    
            )
        }
        return oEmptySecPrevis?.length > 0 ? oEmptySecPrevis : null;
     }
}

/**Class des ilots des données prévis */
export class farmerPrevisOrderSector {
    //id ilot
    public Id: number;
    //numéro pac de l'ilot
    public Pac: number;

    public Name: string;
    //ensemble des plots concernées par cette preco tech
    public Plots: CultivationPlot[];

    public constructor(element: farmerPrevisOrderSector | null) {
        this.Plots = [];
        if (element) {
            this.Id = element.Id,
                this.Pac = element.Pac,
                this.Name = element.Name,
                element.Plots.forEach(fPlot => {
                    let mapPlot = new CultivationPlot(fPlot);
                    //comme on modifie les previs cloner celles ci a la parcelle et la micro
                    if (fPlot.InterPrevis?.length) {
                        mapPlot.InterPrevis = [];
                        fPlot.InterPrevis.forEach(r =>
                            mapPlot.InterPrevis.push(new InterPrevi(r)));

                        let usedMicros = fPlot.MicroPlots
                        mapPlot.MicroPlots = [];
                        usedMicros.forEach((fMicro) => {
                            mapPlot.MicroPlots.push(new MicroPlot(fMicro, true))
                        });
                    }

                    this.Plots.push(mapPlot);
                })
        }
    }

    /**
     * Indique si il dispose de parcelles
     * @param _iExistPrevis true on contrôle aussi la présence de prévis
     * @returns 
     */
    public Exists(_iExistPrevis: boolean) {
        return this.Plots?.length > 0 && (!_iExistPrevis || this.Plots.findIndex(a => a.ExistInterPrevis()) != -1);
    }

    /**
     * retourne pour les parcelles présente la liste qui ont leur dose micros toutes à 0
     * @returns 
     */
    public ExistAllDosesMicrosEmpty():string[]|null{
        let oEmptyPlotPrevis = [];
        if(this.Exists(true)){
            this.Plots.forEach(fPlot=> {

                let plotPrevisNoDoses = fPlot.ExistAllDosesMicrosEmpty();
                if(plotPrevisNoDoses !== null){
                    //la plot prévis ne dispose d'aucune micro dose
                    oEmptyPlotPrevis.push(fPlot.NameCultivationPlot + " " + HandleService.RoundDecimalsView(fPlot.GetSumMicrosSurface(),2)  +"ha") 
                }
            }
               
            )
        }
        return oEmptyPlotPrevis?.length > 0 ? oEmptyPlotPrevis : null;
    }

    /**Retourne si pour cet id la parcelle est présente */
    public IncludePlot(_iCultivationPlotId: number): boolean {
        return this.GetPlotIndex(_iCultivationPlotId) > -1;
    }

    /**Retourne l'index de la parcelle pour cet id cultivationplot */
    private GetPlotIndex(_iCultivationPlotId: number): number {
        return this.Plots.findIndex(p => p.IdCultivationPlot == _iCultivationPlotId);
    }

    //#region fonctions de modification des données
    /**
     * Application des changement pour les parcelles de l'ilot si concerné
     * @param _iChangedPrevis données à modifier ou null app global
     * @param _ieTypeChange type de modif
     * @returns nombre de parcelles modifées
     */
    public ApplyChanges(_iChangedPrevis: ChangesFarmerPlotPrevis | null, _ieTypeChange: ePrevisChanges): number {
        let oChanges = 0;

        let isUniquePrevisChange = ChangesFarmerPlotPrevis.IsUniquePrevisChange(_ieTypeChange);
        let opChange = _ieTypeChange;

        if (isUniquePrevisChange == true) {
            //modification individuelle
            if (this.IncludePlot(_iChangedPrevis.IdCultivationPlot)) {
                let ndxPlot = this.GetPlotIndex(_iChangedPrevis.IdCultivationPlot);
                let ndxPrevis = this.Plots[ndxPlot].InterPrevis.findIndex(r => r.UniquePrevisId == _iChangedPrevis.UniquePrevisId);
                if (ndxPrevis !== -1) {
                    if (this.ApplyPlotPrevisChanges(ndxPlot, ndxPrevis, _iChangedPrevis, opChange) == true) {
                        oChanges += 1;
                    }
                }
            }

        } else {
            //modification globale

            //global sans donnée
            let isChangeStatusDelete = _ieTypeChange == ePrevisChanges.GLOBAL_DELETE_PREVIS;
            let isChangeStatusCreation = _ieTypeChange == ePrevisChanges.CHANGE_TO_CREATE_PREVIS;

            let withFilterPlotPrevis = isChangeStatusDelete == false && isChangeStatusCreation == false && _iChangedPrevis.IncludePrevisSelection();

            let isSleectionPurcent = _ieTypeChange == ePrevisChanges.APP_MASS_CHANGE_BY_PURCENT;

            for (let ndxPlot = 0; ndxPlot < this.Plots.length; ndxPlot++) {

                if (isChangeStatusDelete) {
                    for (let ndxPrevis = 0; ndxPrevis < this.Plots[ndxPlot].InterPrevis.length; ndxPrevis++) {
                        if (this.SetPrevisDeletedStatus(ndxPlot, ndxPrevis)) {
                            oChanges += 1;
                        }
                    }
                } else if (isChangeStatusCreation) {
                    for (let ndxPrevis = 0; ndxPrevis < this.Plots[ndxPlot].InterPrevis.length; ndxPrevis++) {
                        if (this.SetPrevisCreationStatus(ndxPlot, ndxPrevis)) {
                            oChanges += 1;
                        }
                    }
                }
                else {
                    if (!withFilterPlotPrevis || _iChangedPrevis.IsSelectedPlotPrevis(this.Plots[ndxPlot].IdCultivationPlot)) {
                        for (let ndxPrevis = 0; ndxPrevis < this.Plots[ndxPlot].InterPrevis.length; ndxPrevis++) {

                            if (isSleectionPurcent) {

                                if (this.Plots[ndxPlot].InterPrevis[ndxPrevis].UniquePrevisId == _iChangedPrevis.GetSelectedPlotUniquePrevisId(this.Plots[ndxPlot].IdCultivationPlot)) {
                                    if (this.ApplyPlotPrevisChanges(ndxPlot, ndxPrevis, _iChangedPrevis, opChange) == true) {
                                        oChanges += 1;
                                    }
                                }
                            }

                            else if (!withFilterPlotPrevis || this.Plots[ndxPlot].InterPrevis[ndxPrevis].UniquePrevisId == _iChangedPrevis.GetSelectedPlotUniquePrevisId(this.Plots[ndxPlot].IdCultivationPlot))
                                if (this.ApplyPlotPrevisChanges(ndxPlot, ndxPrevis, _iChangedPrevis, opChange) == true) {
                                    oChanges += 1;
                                }
                        }
                    }
                }
            }
        }

        return oChanges;
    }

    /**
     * Attribution du statut de suppression à la prévis si elle dispose d'un id_intervention
     * @param _iNdxPlot 
     * @param _iNdxPrevis 
     * @returns 
     */
    private SetPrevisDeletedStatus(_iNdxPlot: number, _iNdxPrevis: number): boolean {

        let oPlot = this.Plots[_iNdxPlot];
        let oPrevis = oPlot.InterPrevis[_iNdxPrevis];

        if (oPrevis.IdIntervention > 0) {
            //donc il est bien crée en db
            oPrevis.SetDeletingStatus();
            return oPrevis.IsToDelete();
        } else {
            return false;
        }
    }
    /**
     * Attribution du statut depour la creation en db de la previs issue d'une preco
     * @param _iNdxPlot index de la parcelle
     * @param _iNdxPrevis index de la prévis
     * @returns true: il a le statut modifié
     */
    private SetPrevisCreationStatus(_iNdxPlot: number, _iNdxPrevis: number): boolean {

        let oPlot = this.Plots[_iNdxPlot];
        let oPrevis = oPlot.InterPrevis[_iNdxPrevis];

        if (oPrevis.IdIntervention == null || oPrevis.IdIntervention == 0) {
            //donc il est bien à creer
            oPrevis.SetUpdatingStatus();
            return oPrevis.IsToUpdate();
        } else {
            return false;
        }
    }

    /**
     * Application de la modificationsur la|les prévis pour les inter présentes présent si concernés
     * @param _iNdxPlot index de la parcelle
     * @param _iNdxPrevis index de la prévis retournée maj
     * @param _iChangedPrevis les modifs calculée ou encodée
     * @param _ieTypeChange le type de modif
     * @returns bool si une modif a été opérée
     */
    private ApplyPlotPrevisChanges(_iNdxPlot: number, _iNdxPrevis: number, _iChangedPrevis: ChangesFarmerPlotPrevis | null, _ieTypeChange: ePrevisChanges): boolean {

        let oPlot = this.Plots[_iNdxPlot];
        let oPrevis = oPlot.InterPrevis[_iNdxPrevis];
        
        let computebyPurcent = _ieTypeChange == ePrevisChanges.PREVIS_CHANGE_BY_PURCENT || _ieTypeChange == ePrevisChanges.APP_MASS_CHANGE_BY_PURCENT
        //% configuré
        let pctConfigured = computebyPurcent ? _iChangedPrevis.GetUserConfigPurcentCompute() : 0;

        //il peur changer durant traitement on garder original
        let _eTypeChange: ePrevisChanges = _ieTypeChange;
        if (oPrevis !== undefined) {
            //indique si la dose parcelle est recalculée selon les qte des micros par la surfac de celles-ci
            let computePlotPrevisDose = true;
            let _coeffDoseComputed: number | boolean = false;
            
            // changement de quantité de mass au prorata
            if(_eTypeChange == ePrevisChanges.APP_MASS_PREVIS_QTE) {
                let identPlotPrevis = _iChangedPrevis.GetSelectedPlotPrevis(oPlot.IdCultivationPlot);
                let plotMcAreas = oPlot.GetSumMicrosSurface();
                //les quantité au pro rata on été calculée on les recupere
                let previsDoseComputed = plotMcAreas > 0 ? _iChangedPrevis.GetApMassQteRatioPlot(identPlotPrevis) / plotMcAreas : 0;
                _coeffDoseComputed = oPrevis.Fertilizer.Dose > 0 ? previsDoseComputed / oPrevis.Fertilizer.Dose : 0;
                _eTypeChange = ePrevisChanges.PREVIS_DOSE

                _iChangedPrevis.PrevisDose = previsDoseComputed;
                computePlotPrevisDose = true;
            }
            //changement de quantité en interface les doses sont recalculées
            else if (_eTypeChange == ePrevisChanges.PREVIS_QTE) {
                //calcul la dose moyenne depuis la qte client
                let plotMcAreas = oPlot.GetSumMicrosSurface();
                let previsDoseComputed =plotMcAreas > 0 ? _iChangedPrevis.PrevisQte / plotMcAreas : 0;
                _coeffDoseComputed = oPrevis.Fertilizer.Dose > 0 ? previsDoseComputed / oPrevis.Fertilizer.Dose : 0;
                _eTypeChange = ePrevisChanges.PREVIS_DOSE

                _iChangedPrevis.PrevisDose = previsDoseComputed;
                computePlotPrevisDose = false;
            } else if (_eTypeChange == ePrevisChanges.PREVIS_DOSE) {
                _coeffDoseComputed = oPrevis.Fertilizer.Dose > 0 ? _iChangedPrevis.PrevisDose / oPrevis.Fertilizer.Dose : 0;
                _eTypeChange = _eTypeChange = ePrevisChanges.PREVIS_DOSE;
                computePlotPrevisDose = false;
            }

            if (_eTypeChange == ePrevisChanges.PREVIS_DOSE) {
                //modification dose|quantiée convertie
                oPlot = this.ApplyMicroDosePrevisChanges(oPlot, oPrevis.Ident, _coeffDoseComputed, computePlotPrevisDose)
                if (!computePlotPrevisDose) {
                    //on attribue la dose encodée client
                   
                    oPrevis.Fertilizer.Dose = HandleService.RoundDecimals(_iChangedPrevis.PrevisDose, 2);
                }

            } else if (_eTypeChange == ePrevisChanges.GLOBAL_FERTILIZER) {
                //changement de produit
                let newFertilizer = _iChangedPrevis.GetNewPrevisFertilizer();
                let oldDose = oPrevis.Fertilizer.Dose;
                let selectedCompo = _iChangedPrevis.GetNewFertilizerCodeCompo();
                let oldCompo = Number(oPrevis.Fertilizer[selectedCompo]);
                let nwCompo = newFertilizer.GetComposition(selectedCompo);

                let oldUnit = oPrevis.Fertilizer.Unit;
                let nwUnit = newFertilizer.unit;
                let unitChangeCoeff = this.GetChangingUnitCoeff(oldUnit, nwUnit)
               
                oPrevis.Fertilizer = newFertilizer.MapFertilizer(oldDose);

                if (oPrevis.CodeProduit) {
                    oPrevis.CodeProduit = newFertilizer.code;

                }
                oPlot = this.ApplyMicroProductChanges(oPlot, oPrevis.Ident, unitChangeCoeff, newFertilizer.code, oldCompo, nwCompo, computePlotPrevisDose)
                
            } else if (_eTypeChange == ePrevisChanges.GLOBAL_DATE) {
                //changer la date parcelle uniquement pas de recalcul pas de rechargement 
                oPrevis.BeginDate = new Date(_iChangedPrevis.PrevisDate)
            } else if (_eTypeChange == ePrevisChanges.PREVIS_CHANGE_BY_PURCENT || _eTypeChange == ePrevisChanges.APP_MASS_CHANGE_BY_PURCENT) {
                //changement par %
                oPlot = this.ApplyMicroDoseChangeByPurcent(oPlot, oPrevis.Ident, pctConfigured, computePlotPrevisDose);
            }

            //on indique le status en modifié ainsi au save il fera le save
            oPrevis.SetUpdatingStatus();
            return true;
        }

        return false;

    }

    /**
     * Maj des dose selon un % passé
     * @param _oPlot parcelle  retournée avec ses micros maj
     * @param _iPrevisIdent Ident de la previs de la parcelle
     * @param _isPurcent  pourcentage à appliquer
     * @param _iComputeDosePlot le cas d'une gestion par dose, si true on reclacule la dose moyenne de la parcelle pour la previs selon les qte de chaque micro par sa surface
     * @returns 
     */
    private ApplyMicroDoseChangeByPurcent(_oPlot: CultivationPlot, _iPrevisIdent: number, _isPurcent: number, _iComputeDosePlot: boolean = false): CultivationPlot {

        let sumMcQte = 0;
        let coeff = _isPurcent / 100;
        let sumMcAras = 0;
        _oPlot.MicroPlots.forEach(
            fMc => {
                if (fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent) !== -1) {
                    let previs = fMc.InterPrevis[fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent)];
                    previs.Fertilizer.Dose = this.RoundDose(previs.Fertilizer.Dose + (previs.Fertilizer.Dose * coeff));
                    if (_iComputeDosePlot) {
                        sumMcQte += previs.Fertilizer.Dose * fMc.Surface;
                    }
                    fMc.InterPrevis[fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent)] = previs;
                }
                sumMcAras += fMc.Surface;
            }
        )

        if (_iComputeDosePlot) {
            _oPlot = this.computeAvgPlot(_oPlot, sumMcQte, sumMcAras, _iPrevisIdent);
        }
        return _oPlot;
    }
    /**
     * MAJ des données d'une prévis pour ses micros de la parcelle
     * @param _oPlot parcelle  retournée avec ses micros maj
     * @param _iPrevisIdent Ident de la previs de la parcelle
     * @param _iMultiCoeffDose si présent on multiplie la dose de la micro par ce coefficient
     * @param _iComputeDosePlot le cas d'une gestion par dose, si true on recalcul la dose moyenne de la parcelle pour la previs selon les qte de chaque micro par sa surface
     * @returns 
     */
    private ApplyMicroDosePrevisChanges(_oPlot: CultivationPlot, _iPrevisIdent: number, _iMultiCoeffDose: number | false, _iComputeDosePlot: boolean = false): CultivationPlot {

        let sumMcQte = 0;
        let sumMcAras = 0;
        _oPlot.MicroPlots.forEach(
            fMc => {
                if (fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent) !== -1) {
                    if (_iMultiCoeffDose !== false) {
                        let fPrevis: mcPreconisation = fMc.InterPrevis[fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent)];

                        fPrevis.Fertilizer.Dose = this.RoundDose(fPrevis.Fertilizer.Dose * _iMultiCoeffDose);
                        if (_iComputeDosePlot) {
                            sumMcQte += fPrevis.Fertilizer.Dose * fMc.Surface;
                        }
                    }
                }
                sumMcAras += fMc.Surface;
            }
        )

        if (_iComputeDosePlot) {
            _oPlot = this.computeAvgPlot(_oPlot, sumMcQte, sumMcAras, _iPrevisIdent);
        }
        return _oPlot;
    }

    /**
     * Maj des doses des prévis micros depuis un changement de fertilizant minérale
     * @param _oPlot parcelle maj
     * @param _iPrevisIdent ident de la prévis
     * @param _iUnitCoeff coefficiant unit multiplicateur
     * @param _iProductCode code produit à remplacer 
     * @param _iOldCompoValue ancienne valeur (ancien produit) de la compo selectionnée
     * @param _iNewCompoValue nouvelle valeur (new produit) de la compo selectionnée
     * @param _iComputeDosePlot indique que la dose moyenne à la parcelle est recalculée
     * @returns 
     */
    private ApplyMicroProductChanges(_oPlot: CultivationPlot, _iPrevisIdent: number, _iUnitCoeff: number, _iProductCode: string, _iOldCompoValue: number, _iNewCompoValue: number, _iComputeDosePlot: boolean = false): CultivationPlot {
        //{dose préco} * {teneur source} / {teneur cible} * {coeff unité}
        let sumMcQte = 0;
        let compoComputed= _iNewCompoValue !== 0 ? _iOldCompoValue / _iNewCompoValue * _iUnitCoeff : 0;

        let sumMcAras = 0;

        _oPlot.MicroPlots.forEach(
            fMc => {
                if (fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent) !== -1) {
                    let fPrevis: mcPreconisation = fMc.InterPrevis[fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent)];
                    fPrevis.Fertilizer.code = _iProductCode;

                    if (fPrevis.Fertilizer.Dose > 0) {
                       
                        let computedDose =  fPrevis.Fertilizer.Dose * compoComputed;
                        fPrevis.Fertilizer.Dose = this.RoundDose(computedDose);

                        fMc.InterPrevis[fMc.InterPrevis.findIndex(p => p.Ident == _iPrevisIdent)] = fPrevis;
                        sumMcQte += fPrevis.Fertilizer.Dose * fMc.Surface;
                    }
                }
                sumMcAras += fMc.Surface;
            }
        )
        if (_iComputeDosePlot) {
            _oPlot = this.computeAvgPlot(_oPlot, sumMcQte, sumMcAras, _iPrevisIdent);
        }
        return _oPlot;
    }

    /**
     * Calcul de la moyenne parcellaire de la prévis farmer
     * @param _oPlot 
     * @param _iMcSumQte quantité totale calculée
     * @param _iMcAreas somme des surfaces des micros
     * @param _iPrevisIdent ident de la prévis parcellaire
     * @returns 
     */
    private computeAvgPlot(_oPlot: CultivationPlot, _iMcSumQte: number, _iMcAreas: number, _iPrevisIdent: number): CultivationPlot {
        let previsIndex = _oPlot.InterPrevis.findIndex(r => r.Ident == _iPrevisIdent);
        if (previsIndex !== -1) {
            _oPlot.InterPrevis[previsIndex].Fertilizer.Dose = _iMcAreas > 0 ? this.RoundDose(_iMcSumQte / _iMcAreas, 2) : 0;
        }

        return _oPlot;
    }

    /**Arrondit la dose à 2 position */
    private RoundDose(_iDose: number, _iDecimals: number = 3): number {
        return HandleService.RoundDecimals(_iDose, _iDecimals)
    }

    /**
     * Retourne le coefficiant multiplicateur sur un changement d'unité
     * @param _iSrcUnit unité source
     * @param _iTrgUnit unité de conversion
     * @returns 
     */
    private GetChangingUnitCoeff(_iSrcUnit: string, _iTrgUnit: string): number {
        let oCoeff = 0;
        if (_iSrcUnit?.trim().toLowerCase() == "kg" || _iSrcUnit?.trim().toLowerCase() == "l") {
            if (_iTrgUnit?.trim().toLowerCase() == "kg" || _iTrgUnit?.trim().toLowerCase() == "l") {
                oCoeff = 1;
            } else if (_iTrgUnit?.trim().toLowerCase() == "t" || _iTrgUnit?.trim().toLowerCase() == "m3") {
                oCoeff = 0.001;
            }
        } else if (_iSrcUnit?.trim().toLowerCase() == "t" || _iSrcUnit?.trim().toLowerCase() == "m3") {
            if (_iTrgUnit?.trim().toLowerCase() == "t" || _iTrgUnit?.trim().toLowerCase() == "m3") {
                oCoeff = 1;
            } else if (_iTrgUnit?.trim().toLowerCase() == "kg" || _iTrgUnit?.trim().toLowerCase() == "l") {
                oCoeff = 1000;
            }
        }
        return oCoeff;
    }
    //#endregion

}