import { Geometry } from './geometry'
import { GroundType } from './groundtype';
import { Cultivation } from './cultivation';
import { MicroPlot } from './microplot';
import { Intervention } from './intervention';
import { Specie } from './specie';
import { InterPrevi } from './interPrevi';
import { HandleService } from '../../_services';

export class CultivationPlot {

    //#region properties
    //id increment de la parcelle
    Id: number;
    Geometry: Geometry;
    IdCultivationPlot: number;
    NameCultivationPlot: string;
    //surface de la parcelle
    Surface: number;
    //si présent somme des surfaces de ses micros
    McSurface: number;
    GroundTypeSelected: GroundType;
    CultivationSelected: Cultivation
    PreviousCultivationSelected: Cultivation
    MicroPlots: Array<MicroPlot>;
    SpecieSelected: Specie;
    AdviceCreationDate: Date;
    //Liste des préconisations tech
    Preconisations: Intervention[];
    //Liste des interventions tech
    Interventions: Intervention[];
    //Liste des interventions  previsionnelles farmer
    InterPrevis: InterPrevi[];
    Rendement: number;
    //#region NON employé en restitution jusque là
    // 
    // StonesRatio: number;
    // StonesRatioSubsoil: number;
    // PreviousSpecieSelected: Specie;
    // EfficiencyObjective: number;
    // HasPreviousIrrigation: boolean;
    // HasIrrigation: boolean;
    // IrrigationVolume: number;
    // IrrigationWaterNitrate: number;
    // BlockYearP: number;
    // BlockYearK: number;
    // NumberOrganicMatter: number;
    // NumberMinerals: number;
    // ReversalYear: number;
    // ReversalMonth: number;
    // DestuctionDate: Date;
    // VegetationStateCipan: number;
    // TopkillingDate: Date;
    // SowingDate: Date;
    // ObjectiveProteine: Boolean;
    // LeguminousProportion: number;
    // BiomassEH: number;
    // BiomassSH: number;
    // RemainingSH: number;
    // CumulativeRainfall: number;
    // IsCatchCrop: boolean;
    // IsPreviousCatchCrop: boolean;
    // RotationMeadow : boolean;
    // Nabs_value:number;
    //#endregion



    constructor(element: any | null) {
        if (element !== null) {
            this.Bind(element)
        }
    }
    //init données parcelle
    public Bind(element: any) {
        this.Geometry = element.Geometry;
        this.Id = element.Id;
        this.IdCultivationPlot = element.IdCultivationPlot;
        this.NameCultivationPlot = element.NameCultivationPlot;
        this.GroundTypeSelected = element.GroundTypeSelected;
        this.CultivationSelected = element.CultivationSelected;
        this.PreviousCultivationSelected = element.PreviousCultivationSelected;
        this.MicroPlots = [];
        if(element.MicroPlots){
            element.MicroPlots.forEach(fMc => {
                this.MicroPlots.push(new MicroPlot(fMc, true))
            });
        }
        
        this.SpecieSelected = element.SpecieSelected;
        this.AdviceCreationDate = element.AdviceCreationDate;
        this.Interventions = element.Interventions;
        this.Rendement = element.Rendement;
        this.Preconisations = element.Preconisations;

        this.InterPrevis = []
        if(element.InterPrevis){
            element.InterPrevis.forEach(fPrevis => {
                this.InterPrevis.push(new InterPrevi(fPrevis))
            });
        }
        this.Surface = element.Surface;
        this.McSurface = element.McSurface;
    }

    //#region functions

    /**Indique si dispose de farmer tech previs */
    public ExistInterPrevis(): boolean {
        return this.InterPrevis?.length > 0;
    }

    /**Retourne si présent la prévis de la parcelle dont toutes ses micros ont leur dose à 0 */
    public ExistAllDosesMicrosEmpty(): InterPrevi | null {
        if (this.ExistInterPrevis() && this.MicroPlots?.length > 0) {

            let oEmptyPrevis = null;

            this.InterPrevis.forEach(fPrevis => {
                if (this.MicroPlots.findIndex(r => r.InterPrevis?.length > 0 && r.InterPrevis.findIndex(a => a.Ident == fPrevis.Ident && a.Fertilizer.Dose > 0) != -1) == -1) {
                    //cette prévis parcellaire ne dispose pour ses micros d'aucune dose micro
                    oEmptyPrevis = fPrevis;
                }
            })
            return oEmptyPrevis;

        }
        return null
    }

    /**Indique si dispose de farmer tech previs issus de tech preco conseil */
    public ExistTechPrecoInterPrevis(): boolean {
        return this.ExistInterPrevis() && this.GetTechPrecoInterPrevis()?.length > 0;
    }

    /**
   * Retourne si il existe une prévis liée à cet id de préco
   * @param _iPrecoId tech preconisation idIntervention
   * @returns 
   */
    public ExistPrevisTechPreconisation(_iPrecoId: number): boolean {
        return this.InterPrevis.findIndex(r => r.SrcIdInter == _iPrecoId) !== -1;
    }

    /**Retourne la liste farmer tech previs issus de tech preco conseil */
    public GetTechPrecoInterPrevis(): InterPrevi[] {
        return this.InterPrevis.filter(r => r.IsTechAdvicePreco());
    }

    /**Indique si dispose de farmer tech previs issus de tech preco pilot SP */
    public ExistTechPilotSPPrecoInterPrevis(): boolean {
        return this.ExistInterPrevis() && this.GetTechPilotSpPrecoInterPrevis()?.length > 0;
    }

    /**Retourne la liste farmer tech previs issus de tech preco pilot SP */
    public GetTechPilotSpPrecoInterPrevis(): InterPrevi[] {
        return this.InterPrevis.filter(r => r.IsTechPilotSP());
    }

    /**Retourne la somme de surface des micros */
    public GetSumMicrosSurface(): number {

        if(!this.McSurface){
            let oSum = 0;
            if (this.MicroPlots?.length) {
                oSum = this.MicroPlots.reduce((oSum, current) => oSum + current.Surface, 0);
            }
            return oSum;
        } else {
            return this.McSurface;
        }
    }

    /**
     * Retourne si la prévis est modulée niveau de ses micros 
     * (doses différentes)
     */
    public IsPrevisModulated(_iPrevisIdent: number): boolean {

        if (_iPrevisIdent && this.MicroPlots?.length > 1) {
            let mcDoses = [];
            this.MicroPlots.map(r => r?.InterPrevis.filter(t => t.Ident == _iPrevisIdent).map(r => mcDoses.push(r.Fertilizer.Dose)));
            mcDoses = HandleService.DistinctValues(mcDoses)
            return mcDoses?.length > 1;
        } else {
            return false;
        }
    }
    //#endregion

    /**
     * Si quantité >=1 Arrondit décimales des doses des farmer prévis selon l'unité
     * ainsi que maj de la dose max (arrondie) de la prévis
     *  tonne et m3 arrondit 2 decimales - autres unités à 0 decimale
     */
    public RoundInterPrevisUnitDoses(){

        if(this.ExistInterPrevis() && this.MicroPlots.length){
            let decimalesUnits = ['t','m3']
            this.InterPrevis.forEach(
                (oPrevis:InterPrevi)=>{
                    if(oPrevis.Fertilizer?.Unit && oPrevis.Ident){
                       
                        let decimals = 0;
                        if(decimalesUnits.includes(oPrevis.Fertilizer.Unit.toLowerCase())){
                           
                            decimals = 2;
                        }
                        this.MicroPlots.forEach((oMc:MicroPlot)=>{
                            if(oMc.InterPrevis?.length){
                                let ndxMcPrevis = oMc.InterPrevis.findIndex(t => t.Ident == oPrevis.Ident);
                                if(ndxMcPrevis !== -1 && oMc.InterPrevis[ndxMcPrevis].Fertilizer.Dose){
                                    if(decimals == 0 && oMc.InterPrevis[ndxMcPrevis].Fertilizer.Dose <1){
                                        //on laisse
                                    } else {
                                        oMc.InterPrevis[ndxMcPrevis].RoundDose(decimals);
                                    }
                                }
                            }
                        })

                        if(decimals == 0 && oPrevis.Fertilizer.Dose <1){
                            //on laisse
                        } else {
                            oPrevis.RoundDose(decimals);
                        }

                        if(oPrevis.Legend && oPrevis.Legend?.Class.length > 0){
                            let lstNdx = oPrevis.Legend.Class.length -1;
                            //cas d'une seul dose max elle est identique en min max
                            if(oPrevis.Legend.Class[lstNdx].MinValue == oPrevis.Legend.Class[lstNdx].MaxValue){
                                oPrevis.Legend.Class[lstNdx].MinValue = HandleService.RoundDecimals(oPrevis.Legend.Class[lstNdx].MinValue, decimals)
                            }
                            oPrevis.Legend.Class[lstNdx].MaxValue = HandleService.RoundDecimals(oPrevis.Legend.Class[lstNdx].MaxValue, decimals) 
                        }
                    }
   
                }
            )
        }
    }

}
