import { Injectable } from '@angular/core';


@Injectable()
export class HandleService {

    private static LEGEND_COLOR_MIN = 'hsl(0,68,54)';
    private static LEGEND_COLOR_MAX = 'hsl(240,68,54)';
    //le nombre de rangs par défaut
    public static LEGEND_COUNT_RANGES = 10;


    public static isMobile() {
        return parseInt(window.getComputedStyle(document.getElementById('state-indicator')).getPropertyValue('z-index'), 10);
    }

    public static weightedAverage(microDatas: any[], plotKey: string) {
        let sumValueBySurface: any = null;
        let sumSurface = 0;
        let result = null;

        microDatas.forEach(microData => {
            let microValue = microData[plotKey].value
            if (microValue !== null && microValue !== undefined) {

                if (sumValueBySurface === null) sumValueBySurface = 0;

                sumValueBySurface += microValue * microData.surface.value;
                sumSurface += microData.surface.value;
            }
        });

        if (sumSurface && sumValueBySurface !== null) {
            result = sumValueBySurface / sumSurface;
        }

        return result;
    }

    public static roundByType(data: any) {

        if (data.group === 'preconisation' && data.type === 'float') {
            data.value = Math.round(data.value * 10) / 10;
        } else {
            switch (data.type) {
                case 'integer':
                    data.value = Math.round(data.value);
                    break;
                case 'float':

                    // 4 décimales
                    data.value = Math.round(data.value * 10000) / 10000;
                    break;
            }
        }
    }

    /**
     * Assigne les propriétés édités aux propriétés reçus en entrée
     * @param sourceProperty
     * @param editedProperty
     */
    public static setProperties(sourceProperty: any, editedProperty: any) {
        switch (sourceProperty.type) {
            case 'date':
                sourceProperty.value = HandleService.dateToString(editedProperty.value);
                break;
            case 'combo':
                sourceProperty.selected_id = editedProperty.selected_id;
                sourceProperty.value = editedProperty.value;
                break;
            case 'select':
                sourceProperty.selected_id = editedProperty.selected_id;
                sourceProperty.value = editedProperty.value;
                break;
            default:
                sourceProperty.value = editedProperty.value;
                break;
        }

        sourceProperty.computed = editedProperty.computed;

        return sourceProperty;
    }



    public static zeroPrefix(digit: number) {
        return ('0' + digit).slice(-2);
    }

    /**
     * Retourne une copie de l'objet
     * @param oldObj
     */
    public static deepCopy(obj: any) {
        let copy: any;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.deepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
            }
            return copy;
        }
    }

    /**
    * Converti un objet Date en chaine de caractère
    * @param date
    */
    public static dateToString(date: Date) {
        if (!date) return date;
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return this.zeroPrefix(day) + '/' + this.zeroPrefix(month) + '/' + year;
    }

    /**
     * Convertion d'une date au format mm-dd-yyyy format input Date
     * @param date 
     * @param _iAdd1Day ajout 1 jour du a la date formaté iso gmt 
     * @returns 
     */
    public static StringToInputDateFormat(date: string, _iAdd1Day: boolean = true): string {
        if (date) {
            let formatedDate: Date = this.stringToDate(date);
           return HandleService.DateToInputDateFormat(formatedDate, _iAdd1Day)
        }
        return "";
    }

    /**
 * Convertion d'une date au format mm-dd-yyyy format input Date
 * @param date 
 * @param _iAdd1Day ajout 1 jour du a la date formaté iso gmt 
 * @returns 
 */
    public static DateToInputDateFormat(_iDate: Date, _iAdd1Day: boolean = true): string {
      
        if (_iDate && _iDate.getDate() > 0) {
            let oDate = new Date(_iDate);
            if (_iAdd1Day) {
                //avec le gmt la date est convertie 1jour en moins
                oDate.setDate(oDate.getDate() + 1);
            }
            return oDate.toISOString().split('T')[0];
        }
        return "";
    }
    /**
    * Converti une chaine de caratère en object Date
    * @param date date format yyyy-mm-dd
    */
    public static stringToDate(date: string):Date {
        if (date.includes('-')) {
            date = date.replace('-', '');
        }
        let dates = date.split('/');
        return new Date(parseInt(dates[2], 10), parseInt(dates[1], 10) - 1, parseInt(dates[0], 10))
    }
    /**
    *  Retourne la date sans Time au format ISO (toISOString) Date
    * @param date 
    */
    public static getISODate(date: Date): string {
        if (typeof (date) !== typeof (Date))
            date = new Date(date);
        return date != null ? date.toISOString() : "";
    }

    /**
   * Retourne la date sans Time au format LOCAL (toLocaleDateString)  Date
   * @param date 
   */
    public static getLOCALDate(date: Date): string {
        if (typeof (date) !== typeof (Date))
            date = new Date(date);
        return date != null ? date.toLocaleDateString() : "";
    }

    /**
  * Convertion d'un string Date en date
  * si !=null sinon renvoit string ""
  * @param date 
  */
    public static getStringLocalDate(date: string): any {
        let oDate: Date = null;
        if (date != null)
            oDate = new Date(date);
        return oDate != null ? oDate.toLocaleDateString() : ""
    }


    /**
     * Formatage d'une unité en ajoutant à l hectare (+/ha)
     * @param _iUnit l'unité à suffixer de '/ha'
     * @returns 
     */
    public static FormatUnitOnArea(_iUnit: string): string {
        return _iUnit ? _iUnit + "/ha" : _iUnit;
    }

    /**
* Generation des légendes avec le premier step à 0
* @param maxValue valeur maximale de la légende
* @param minValue valeur minimale de la légende
* @param minColor
* @param maxColor
* @param step nombre d iterations
*/
    public static generateScaleWithFirstStepZero(maxValue: number, minValue: number = 0, nbLegendItem: number = 10, minColor: string = HandleService.LEGEND_COLOR_MIN, maxColor: string = HandleService.LEGEND_COLOR_MAX) {
        const res: any[] = [];

        const startHslValues = /hsl\((\d+),\s*([\d.]+),\s*([\d.]+)\)/g.exec(minColor);
        const endHslValues = /hsl\((\d+),\s*([\d.]+),\s*([\d.]+)\)/g.exec(maxColor);

        if (startHslValues[1] && endHslValues[1]) {
            let rgb: number[];

            rgb = this.hslToRgb(parseInt(startHslValues[1], 10), parseInt(startHslValues[2], 10), parseInt(startHslValues[3], 10));

            const legendZero = this.GenerateLegendColorRGBA(0, 0, rgb);
            res.push(legendZero);

            if (maxValue === 0) {
                return res;
            }

            const range = maxValue - minValue;
            const areSameValues = range == 0;

            if (areSameValues) {
                // return legend avec 1 seul item supplémentaire (bleu foncé)
                rgb = this.hslToRgb(parseInt(endHslValues[1], 10), parseInt(endHslValues[2], 10), parseInt(endHslValues[3], 10));

                res.push(this.GenerateLegendColorRGBA(minValue, maxValue, rgb));
                return res;
            }

            const decimalPrecision = 2;
            const precision = Math.pow(10, decimalPrecision);

            // nbLegendItem-1 car le premier item est déjà défini
            const gap = Math.round(range / (nbLegendItem - 1) * precision) / precision;

            const rangeHue = parseInt(endHslValues[1], 10) - parseInt(startHslValues[1], 10);
            const gapHue = Math.round(rangeHue / (nbLegendItem - 1));

            for (let i = 1; i < nbLegendItem; i++) {
                rgb = this.hslToRgb(gapHue * i, parseInt(startHslValues[2], 10), parseInt(startHslValues[3], 10));

                const min = Math.round((minValue + gap * (i - 1)) * precision) / precision;
                const max = Math.round((minValue + gap * (i)) * precision) / precision;
                res.push(this.GenerateLegendColorRGBA(min, max, rgb));

            }

            // on reset les valeurs extrêmes pour palier aux imprécisions des arrondis
            res[res.length - 1].MaxValue = maxValue;
        }

        return res;
    }

    /**
     * Generation d'une legende de couleur rang
     * @param _iMinValue valeur minimale
     * @param _iMaxValue valeur maximale
     * @param rgb structure rgb de couleur
     * @returns 
     */
    private static GenerateLegendColorRGBA(_iMinValue, _iMaxValue, rgb) {
        return this.GenerateLegendColor(_iMinValue, _iMaxValue, rgb[0], rgb[1], rgb[2], 1)
    }

    /**
     * Generation d'une legende de couleur rang depuis couleurs rgba
     * @param _iMinValue valeur minimale
     * @param _iMaxValue valeur maximale
     * @param _iRed Valeur couleur rouge
     * @param _iGreen Valeur couleur verte
     * @param _iBlue Valeur couleur bleu
     * @param iAlpha Valeur alpha
     * @returns 
     */
    private static GenerateLegendColor(_iMinValue, _iMaxValue, _iRed, _iGreen, _iBlue, iAlpha) {
        return {
            MinValue: _iMinValue,
            MaxValue: _iMaxValue,
            Color: 'rgba(' + _iRed + ',' + _iGreen + ',' + _iBlue + ',' + iAlpha + ')',
            Blue: _iBlue,
            Green: _iGreen,
            Red: _iRed,
            Alpha: iAlpha,
        };
    }

    public static hslToRgb(h: number, s: number, l: number) {

        h /= 360;
        s /= 100;
        l /= 100;

        let r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            let hue2rgb = function hue2rgb(p: number, q: number, t: number) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    /**
     * Convertit un string pour le visuel avec virgule decimale
     * @param _oNumber nombre à traiter
     * @param _iDecimals nombre de décimales à arrondir, -1: remplace juste le . decimale par une virgule
     * @returns string
     */
    public static RoundDecimalsView(_oNumber: number, _iDecimals: number = 2): string {
        if (_oNumber && _iDecimals >= 0) {
          
            if (_iDecimals == 0) {
                return Math.round(_oNumber).toString();
            }
            return parseFloat(_oNumber.toString()).toFixed(_iDecimals).replace(".", ",")
        }
        return _oNumber.toString().replace(".", ",");
    }

    /**
     * arrondit un nombre
    * @param _oNumber nombre à traiter
     * @param _iDecimals nombre de décimales à arrondir
     * @returns number
     */
    public static RoundDecimals(_oNumber: number, _iDecimals: number = 2): number {
        if (_oNumber && _iDecimals >= 0) {
            if (_iDecimals == 0) {
                return Math.round(_oNumber);
            }
            return parseFloat(parseFloat(_oNumber.toString()).toFixed(_iDecimals))
        }
        return _oNumber;
    }

    /**
    * Retourne le nombre d occurence de la clés demandée
    * @param _iList liste de string bool number
    * @param _iKey clés à comptabiliser
    * @returns 
    */
    public static CountOccurenceKey(_iList: any[], _iKey: any): number {
        return _iList ? _iList.filter(r => r == _iKey).length : 0;
    }

    /**
     * Capitalise le 1e caractere du string si >1 caractere
     * @param _oName 
     * @returns 
     */
    public static CapitalizeFirstCarac(_oName: string): string {
        if (_oName && _oName.length > 1)
            _oName = _oName[0].toUpperCase() + _oName.slice(1);
        return _oName;
    }

    /**
     * Retourne pour un dico les clés avec cette valeur recherchée
     * @param _iDico dictionnaire de données
     * @param _iReqValue unique valeur recherchée
     * @returns  string[]
     */
    public static GetKeysByValue(_iDico: Record<string, any>, _iReqValue: any): string[] {

        let oKeys: string[] = [];
        Object.keys(_iDico).forEach((fKey: string) => {
            if (_iDico[fKey] && _iDico[fKey] == _iReqValue) {
                oKeys.push(fKey);
            }
        });
        return oKeys;
    }

    /**
     * Retourne pour un dico les clés avec ces valeurs
     * @param _iDico dictionnaire de données
     * @param _iReqValue ensemble de valeurs recherchées
     * @returns  string[]
     */
    public static GetKeysByValues(_iDico: Record<string, any>, _iReqValues: Array<any>): string[] {

        let oKeys: string[] = [];
        Object.keys(_iDico).forEach((fKey: string) => {
            if (_iDico[fKey] && _iReqValues.includes(_iDico[fKey])) {
                oKeys.push(fKey);
            }
        });
        return oKeys;
    }

    /**
     * Retourne le nombre de clés présentes
     * @param _iDico dictionnaire de données
     * @returns number
     */
    public static CountRecords(_iDico: Record<string, any>): number {
        return Object.keys(_iDico)?.length;
    }

    /**
     * filtre unique valeur
     * @param _oList liste à filtrer
     * @returns  any[]
     */
    public static DistinctValues(_oList: any[]): any[] {
        if (_oList?.length > 1) {
            return _oList.filter((value, index, array) => array.indexOf(value) === index);
        }
        return _oList;
    }

    /**
     * filtre unique valeur sur 2 niveaux
     * @param _oList liste 2 niveaux
     * @param _iExcludeUndefined true on exclue les valeurs null et undefined
     * @returns 
     */
    public static Distinct2LevelsValues(_oList: any[], _iExcludeUndefined:boolean): any[] {
        let oDist:any[] = [];
        if (_oList?.length > 0) {
            _oList.forEach(t=> {
                oDist.push(...HandleService.DistinctValues(t))
              
            } )
        }

        if(_iExcludeUndefined && oDist.length){
            oDist = oDist.filter(d=>d !== undefined && d !== null);
        }
        return HandleService.DistinctValues(oDist)
    }


}