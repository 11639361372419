<kendo-window  *ngIf="opened" (close)="close()" class="previsModale">
  <kendo-window-titlebar>
    <i class="icon-farmer"></i>
    <span class="k-window-title">{{ title }}</span>
    <button class="k-button k-bare k-button-icon" kendoWindowMaximizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowRestoreAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <div class="modale-container" [class]="IsLockedModale == true ?'lockedModaleCss' : ''">

    <div class="dvTool-container" [class]="ShowTools ?'dvToolExtended' : ''">
      <div class="dvTool">
        <div class="dvUserTools" *ngIf="ShowTools == true">
          <div class="dvUserToolTitleCss">{{ModaleToolsText}}</div>
          <label class="lbluserPurcentChoice">{{labelUserPurcentChoice}}</label>
          <input type="number" class="tbxchoicePurcent" [(ngModel)]="userPurcentChoice" [step]="5"
            [min]="_minPurcent" [max]="_maxPurcent" onkeydown="if(event.key==='.'){event.preventDefault();}"  (change)="OnChangePurcentConfig(event)"
            />{{PurcentUnit}}
        </div>
        <div class="dvToolBottomButtonCss">
          <i class="icon-wrechKey"  title="{{BtnShowingToolsTltp}}" [disabled]="DisableChanges" (click)="onClickShowingTools()"></i>
          
        </div>  
      </div>
    </div>
    <div class="previs-container">

      <div class="precoOrigin-container">
        <div class="precoOrigin">
          <label>{{PrecoOriginText}} : {{PrecoOriginProduct}}</label>
          <label class="composlist" *ngIf="IconPrecoriginComposition"> <i class="icon-chemistry" title="{{iconCompositionsTltp}}" ></i> {{IconPrecoriginComposition}} </label>
        </div>
      </div>

      <div class="changes-container" >
        <div class="chgDate-container" [class]="LockDivsModale == true ? 'lockDivCss' : ''">
          <label>{{lblChangeDate}} </label>
          <input type="date" name="previs_date" id="id_previs_date" placeholder="jj/mm/aaaa"  [(ngModel)]="currentPrevisDate" [min]="minPrevisDate" [max]="maxPrevisDate" (focusout)="onChangePrevisDate($event)">
        </div>

        <div class="chgFertilizer-container" [class]="LockDivsModale == true ? 'lockDivCss' : ''">
          <div *ngIf="IsOriginPrecoDeleted" class="dvOverLockedCbxFerti">
            <label title="{{cbxChangeFertiUnavailable}}" style="width: 90%; height: 24px;">    </label>
          </div>
          <label [for]="comboFertilizers">{{lblCbxFertilizers}}</label>
          <kendo-combobox #comboFertilizers [disabled]="!minFerilizers" [data]="minFerilizers" [textField]="'name'"
            [valueField]="'code'" [(ngModel)]="selectedMinFertilizer" class="cbxMineralsCs"
            (valueChange)="onFertilizerValueChanged($event)" [class]="IsOriginPrecoDeleted ? 'lockedCbxFerti' : ''" >
          </kendo-combobox>
          <label class="composlist" *ngIf="FertilizerComposTxt"> <i class="icon-chemistry" title="{{iconCompositionsTltp}}" ></i>{{FertilizerComposTxt}} </label>
        </div>

        <div class="chgFertilizerCompos-container" *ngIf="choiceFertiCompositions?.length">
          <div class="modChgFertilizerCompos">
            <label class="lblCompoTitle">{{ChoiceCompositionTitle}} : {{selectedMinFertilizer.name}}</label>
            <div *ngFor="let compo of choiceFertiCompositions">
              <input type="radio" [name]="compo.code +'compoSelection'" [value]="compo.code" [id]="compo.code"
                [(ngModel)]="selectedFertiComposition" class="k-radio" (change)="onChangeFertilizerCompos(compo)">
              <label [for]="compo.code" class="k-radio-label">{{compo.value}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="grid-container" [class]="LockDivsModale == true ? ' lockDivCss ' : ''">

          <kendo-grid [data]="GridFarmerPrevis?.Previs" height="80%" width="95%" *ngIf="GridFarmerPrevis !== null"
            scrollable="false" (dataStateChange)="onStateChange($event)" class="gridDataCss">
            <ng-template kendoGridNoRecordsTemplate>
              {{EmptyRecordText}}
            </ng-template>

            <kendo-grid-column field="selected" title="Sélection" width="24">

                <!-- Case à cocher de sélection générale -->
              <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" class="smagGrdCBxCss" (click)="onChangeCheckAll()"
                [(ngModel)]="grdCheckAllChecked" />
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem>
                <input type="checkbox" class="smagGrdCBxCss" (click)="onFarmPlotPreviSelection(dataItem)" [title]="dataItem.cbxTooltip"
                  [disabled]="!dataItem.enabled" [checked]="dataItem.selected" />
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="FarmName" title="Exploitation" *ngIf="IsMultiFarm"></kendo-grid-column>
            <kendo-grid-column field="SectorPac" title="îlot" [width]="24"></kendo-grid-column>
            <kendo-grid-column field="PlotName" title="Parcelle" [width]="130">
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem['PlotName']}}
                <img *ngIf="dataItem['IsPrecoLinked'] == false" class="image-modulated" title="{{IconPrecoLinkBreakTltp}}" src="/assets/synchro-off.svg">

              </ng-template>
              
            </kendo-grid-column>
            <kendo-grid-column field="MicrosAreasWithUnit" title="Surface" [width]="50" ></kendo-grid-column>

            <kendo-grid-column field="PrecoDoseUnit" title="Dose préconisée"
              width="80"></kendo-grid-column>
            <kendo-grid-column field="PrecoQteUnit" title="Quantité préconisée"
              width="80"></kendo-grid-column>

            <kendo-grid-column field="PrevisDose" title="Dose moyenne" width="{{widthColDose}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-numerictextbox [disabled]="!dataItem.enabled" class="grdTbxNumericCss"
                  [(ngModel)]="dataItem['PrevisDose']" (blur)="onPrevisDosingChanged(dataItem,'PrevisDose')"
                  [spinners]="false" format="#.###" [maxlength] ="qteMaxLength"></kendo-numerictextbox>
                {{dataItem['PrevisDoseUnit']}}
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="PrevisQte" title="Quantité" width="{{widthColDose}}">
              <ng-template kendoGridCellTemplate let-dataItem>
                <kendo-numerictextbox [disabled]="!dataItem.enabled" class="grdTbxNumericCss"
                  [(ngModel)]="dataItem['PrevisQte']" (blur)="onPrevisDosingChanged(dataItem,'PrevisQte')"
                  [spinners]="false" format="#.###"  [maxlength] ="qteMaxLength"></kendo-numerictextbox>
                {{dataItem['PrevisQteUnit']}}
                <img *ngIf="dataItem['PrevisModulated'] == true" class="image-modulated" title="{{IconPrevisModulatedTltp}}" src="/assets/logo_img.png" alt="{{IconPrevisModulatedTltp}}">
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column width="80">
              <ng-template kendoGridCellTemplate let-dataItem>
                <button kendoButton class="btnChangePctPrevis" title="{{ReducePctTitle}}"
                  (click)="onPreviChangeByPurcent('N', dataItem)"> {{ReducePctText}}</button>
                <button kendoButton class="btnChangePctPrevis" title="{{MorePctTitle}}"
                  (click)="onPreviChangeByPurcent('P', dataItem)"> {{MoreAddPctText}}</button>
              </ng-template>
            </kendo-grid-column>

          </kendo-grid>
          <div *ngIf="IsLoadingInProgress">
            {{LoadingPrecoInProgressTxt}}
          </div>
      </div>
     
      <div class="grdTotals-container" [class]="LockDivsModale == true ? 'lockDivCss' : ''">
        <div class="selectTotCnt" >
          <label id="lblSelectionCount">{{SelectionCounter}}/{{TotalGridRows}}</label>
        </div>
        <div class="plotTotCss" >
        </div>
        <div class="areaTotCss" >
          <input type="text" class="inputTotal" [value]="GrdTotAreas" readonly/>
        </div>
        <div class="precoTotEmptyCss">
        </div>
        <div class="qteTotCss" >
          <input type="text" class="inputTotal" [value]="GrdTotProductDose" readonly/>
        </div>
        <div class="qteTotCss" >
         
          <input type="text" class="inputTotal"  [value]="GrdTotProductQte" readonly/>
        </div>
        <div class="purcentsCss" >
          <div class = "appMassPurcents">
            <button kendoButton class="btnChangePctPrevis" title="{{ReducePctTitle}}"
              (click)="onAppMasseChangeByPurcent('N', dataItem)"> {{ReducePctText}}</button>
            <button kendoButton class="btnChangePctPrevis" title="{{MorePctTitle}}"
              (click)="onAppMasseChangeByPurcent('P', dataItem)"> {{MoreAddPctText}}</button>
            </div>
        </div>
      </div>

      <div class="gdAppMasse-container" [class]="LockDivsModale == true ? 'lockDivCss' : ''">    
        <div class="massAppCss">
          <div class = "appMassQuantity">
            <kendo-numerictextbox class="tbxAppMassQteCss" [(ngModel)]="appMasseQte"
             [spinners]="false" format="#.###"  [maxlength] ="qteMaxLength"
             ></kendo-numerictextbox> {{ProductUnit}}
          </div>
          <div class="applMassTextCss">
            <button class="k-button k-primary btn btn-primary"
              title="{{BtnAppMassQuantityTltp}}" (click)="onAppMasseQuantity()">{{BtnAppMassQuantityTxt}}</button>
          </div>
        </div>        
      </div>

      <div class="dvBottomButtons">
        
          <div class="dvLeftButtons">
            <button class="k-button k-primary btn btn-primary bottomButton" [disabled]="DisableChanges == true"
             (click)="onReinitButtonClick()">{{BtnReinitText}}</button>
            <button class="k-button k-primary btn btn-primary bottomButton"  [class]="BtnDeleteCss"
              (click)="onDeleteFarmerPrevisClick()">{{BtnDeleteText}}</button>
          </div>
         
          <div class="dvRightButtons">
            <button class="k-button btn bottomButton"  
              (click)="onCloseButtonClick()">{{BtnCancelText}}</button>
            <button class="k-button k-primary btn btn-primary bottomButton" [disabled]="DisableChanges == true"
                (click)="onSaveButtonClick()">{{BtnSaveText}}</button>
          </div>
        </div>

    </div>
  </div>
</kendo-window>