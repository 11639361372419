<app-jarvis-ui (SelectionChanged)="SelectionChanged($event)" (ActionClick)="ActionClick($event)" (logout)="logout()" [User]="user" [Menus]="menus" [ImageUrl]="imageUrl" [CoopName]="CoopName" name="jarvisMenu"></app-jarvis-ui>
<input name = "tokeninput" value= {{currentUser.token}} type="hidden" />
<input name = "applicationId" value="8c481d582cc5472f99dc78980c2cc308" type="hidden" />

<map [ilot]="currentOrders"  [type]="'plot'" ></map>

<console-window  [ilot]="currentOrders"  [opened] = "consoleDialogOpened" [SelectedCultivations]="SelectedCultivations" (DialogClosed)="ConsoleDialogClosed($event)"></console-window>
<export-window [opened] = "exportDialogOpened" [ilot]="currentOrders" [FilterPrestationsTypes]="exportPrestationTypes" [ModulationPrecosTypes]="ModPrecosTypes" [IntersModePrecoTypes]="IntersModePrecoTypes" [DiagnosticPrestTypes]="DiagnosticPrestTypes" [ExtraPrintModels]="ExtraPrintPdfModels" (DialogClosed)="ExportDialogClosed($event)"></export-window>

<duplicate-tech-preconisation (DialogClosed)="onDuplicateTechPrecoDialogClosed($event)"></duplicate-tech-preconisation>
<update-farmer-previs    (DialogClosed)="onFarmerPrevisUpdateDialogClosed($event)" ></update-farmer-previs>
