<kendo-window title="" *ngIf="opened" (close)="close()" [minWidth]="250" [width]="450">
  <kendo-window-titlebar>
    <div class="k-window-title">Export PDF</div>
    <button class="k-button k-bare k-button-icon" kendoWindowMinimizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowMaximizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowRestoreAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowCloseAction></button>
  </kendo-window-titlebar>

  <form class="k-form">
    <fieldset>
      <legend>Veuillez sélectionner le document à exporter : </legend>

      <div *ngIf="FilterPrestationsTypes!= null && (FilterPrestationsTypes.indexOf('BEAPI_FERTI')>-1 || FilterPrestationsTypes.indexOf('BEAPI_PACK')>-1)">
        <input type="radio" name="exportType" id="fertilite" value="fertilite" [(ngModel)]="selectedExportType" class="k-radio">
        <label for="fertilite" class="k-radio-label">Dossier diagnostic Fertilité</label>
      </div>

      <div *ngIf="FilterPrestationsTypes!= null && (FilterPrestationsTypes.indexOf('BEAPI_POTENTIEL')>-1 || FilterPrestationsTypes.indexOf('BEAPI_PACK')>-1)">
        <input type="radio" name="exportType" value="potentiel" id="potentiel" [(ngModel)]="selectedExportType" class="k-radio">
        <label for="potentiel" class="k-radio-label">Dossier diagnostic Potentiel</label>
      </div>

      <div    *ngFor="let item of DiagnosticPrestTypes">
        <input type="radio" name="exportType" [value]="item.value" [id]="item.code" [(ngModel)]="selectedExportType" class="k-radio">
        <label [for]="item.code" class="k-radio-label">Dossier diagnostic {{CapitalizeFirstCarac(item.value)}}</label>
     </div>
     <div class="dvspace"></div>
      <div *ngIf="FilterPrestationsTypes!= null && (FilterPrestationsTypes.indexOf('BEAPI_FERTI')>-1 || FilterPrestationsTypes.indexOf('BEAPI_PACK')>-1)">
        <input type="radio" name="exportType" value="fumure" id="fumure"  [(ngModel)]="selectedExportType" class="k-radio">
        <label for="fumure" class="k-radio-label">Dossier conseil Fumure</label>
      </div>
      <div *ngIf="FilterPrestationsTypes!= null && (FilterPrestationsTypes.indexOf('BEAPI_POTENTIEL')>-1 || FilterPrestationsTypes.indexOf('BEAPI_PACK')>-1)">
        <input type="radio" name="exportType" value="Conseil azote" id="azote" [(ngModel)]="selectedExportType" class="k-radio">
        <label for="azote" class="k-radio-label">Dossier conseil Azote</label>
      </div>
      <div *ngIf="includePrestaPilotSP(FilterPrestationsTypes) == true">
        <input type="radio" name="exportType" [value]="PilotSpAdviceNCode" id="adviceazoteSP" [(ngModel)]="selectedExportType" class="k-radio">
        <label for="adviceazoteSP" class="k-radio-label">Dossier conseil Pilotage Azote SP</label>
      </div>
      <div class="dvspace"></div>
      <div>
        <input type="radio" name="exportType" value="preconisation" id="preconisation" [(ngModel)]="selectedExportType" class="k-radio">
        <label for="preconisation" class="k-radio-label">Préconisations Fumure</label>
      </div>
      <div *ngFor="let item of ModulationPrecosTypes">
            <input type="radio" name="exportType" [value]="item.value" [id]="item.code" [(ngModel)]="selectedExportType" class="k-radio">
            <label [for]="item.code" class="k-radio-label">Préconisations {{formatePrecoName(item.value)}}</label>
      </div>
      <div class="dvspace"></div>
      <div *ngFor="let mdl of ExtraPrintModels">
        <input type="radio" name="exportType" [value]="mdl.code" [id]="mdl.code" [(ngModel)]="selectedExportType" class="k-radio">
        <label [for]="mdl.code" class="k-radio-label">{{mdl.title}}</label>
      </div>
  
    </fieldset>
    <div class="text-right">
      <button type="button" class="k-button" (click)="close()">Annuler</button>
      <button type="button" class="k-button k-primary" (click)="submit()" [disabled]="!selectedExportType" >Exporter</button>
    </div>
  </form>

</kendo-window>
