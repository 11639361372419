
//Class gestion d'un dictionnaire
export class Dictionary {
    protected _records:Record <string | number, string | number>

    constructor(){
        this._records={};
    }

    /**
     * Ajout ou remplace selon la clés
     * @param _ikey clés
     * @param _iValue valeur
     */
    public Add(_ikey:string | number, _iValue:string | number){
        this._records[_ikey] = _iValue;
    }

    /**
     * Retourne la valeur selon la clés
     * @param _ikey 
     * @returns 
     */
    public GetByKey(_ikey:string | number): string | number| null{
        return this._records[_ikey] ?? null
    }

    /**
     *  Retourne les clés du dictionnaire
     * */
    public GetKeys():string[] | number[]{
        return Object.keys(this._records);
    }

    /**
     * retourne si il dispose d'enregistrement
     * @returns 
     */
    public Exists():boolean{
        return Object.keys(this._records).length > 0;
    }
    
}