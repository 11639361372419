import { Injectable, OnDestroy, TemplateRef } from "@angular/core";
import { DialogCloseResult, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { Subscription } from "rxjs";

@Injectable()
export class ModalService implements OnDestroy {

    private dialogSubs: Subscription;

    //ButtonThemeColor primary utilisé le bouton devient le bouton principale preselectionné rouge
    private _button_theme_primary = 'primary';
    constructor(private dialogService: DialogService) { }

    ngOnDestroy() {
        this.dialogSubs.unsubscribe();
    }

    /**
       * Modale d'alerte avec un seul bouton
       * @param title
       * @param content
       * @param action
       */
    public alert(title: string, content: string | TemplateRef<any>, action: () => void = undefined) {
        this.SimpleUserMessage(false, title, content);
    }

    /**
        * Modale d'information erreur avec un seul bouton
        * @param title
        * @param content
        * @param action
        */
    public Error(title: string, content: string | TemplateRef<any>, action: () => void = undefined) {
        this.SimpleUserMessage(true, title, content);
    }
    /**
     * Modale d'alerte ou erreur avec un seul bouton
     * @param _iIsError
     * @param title
     * @param content
     * @param action
     */
    private SimpleUserMessage(_iIsError: boolean, title: string, content: string | TemplateRef<any>, action: () => void = undefined) {
        const dialog: DialogRef = this.dialogService.open({
            title: title,
            content: content,
            actions: [
                { text: "Ok", primary: true, themeColor: this._button_theme_primary }
            ],
            width: 450,
            height: 200,

            cssClass: "modalUserMessage " + (_iIsError ? "modalErrorCss" : "modalWarningCss")
        });

        this.dialogSubs = dialog.result.subscribe((result) => {
            if (result instanceof DialogCloseResult) {

            } else {
                if (action) action();
                dialog.close();
            }
        });
    }

    /**
     * Modale de confirmation avec deux boutons
     * @param title
     * @param content
     * @param actionOk
     * @param actionKo
     * @param buttons
     */
    public confirm(title: string, content: string | TemplateRef<any>, actionOk: () => void = undefined, actionKo: () => void = undefined, buttons: any = { 'ok': 'Oui', 'ko': 'Non' }) {
        const dialog: DialogRef = this.dialogService.open({
            title: title,
            content: content,
            actions: [
                { text: buttons.ko },
                { text: buttons.ok, primary: true, themeColor: this._button_theme_primary }
            ],
            width: 450,
            height: 200,

            cssClass: 'modalConfirmCss'
        });

        this.dialogSubs = dialog.result.subscribe((result: any) => {
            if (result instanceof DialogCloseResult) {
                actionKo();
            } else {
                if (result.text === buttons.ok) actionOk();
                else actionKo();
            }
        });
    }
}