import { Subscription } from 'rxjs';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FarmerPrevisService, EventEmitterService, FarmServices, EventActionModalLegend } from '../../../_services';
import { LegendItem, farmerPrevisOrdersPrecosDatas, gridFarmerPrevisDatas,eTechInterType } from '../../../_models';
import { LoadingService } from '../../../framework/auth/components/loading/loading.service';

@Component({
  selector: 'duplicate-tech-preconisation',
  templateUrl: './duplicatepreconisation.component.html',
  styleUrls: ['./duplicatepreconisation.component.scss'],
})

// composant de duplication de préconisations technicien gestion interface
export class DuplicatePreconisationComponent implements OnInit {
  public opened: boolean = false;
  //souscription a l'écoute d event ouverture de la modale
  private openModalTechPrecoSubs: Subscription;

  @Output() DialogClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  //la légende préco sélectionnée
  public duplicatedPreco: LegendItem;

  //état checked de la checkbox selection tout
  public grdCheckAllChecked:boolean=false;
  //prévis en cours de chargement
  private _isLoadingPrevis: boolean = false;



  //#region text modale
  static ButtonDuplicationTitle = "Dupliquer la préconisation en intervention personnalisée afin de pouvoir la modifier";

  private _emptyRecordText = "Aucune préconisation n'est récupérée (peut être sont elles déjà dupliquées)";
  private _loadingRecordText = "Chargement en cours";
  public titlePrefix: string = 'Modification de la préconisation : ';
  public toBannerHelperText: string = 'Sélectionner la ou les parcelles concernées par la modification : ';
 public IconPrevisFarmerTltp:string= "Intervention personnalisée";
  public PrecoEverDuplicatedTxt: string = "Une préconisation ne peut être dupliquée qu'une seule fois";
  public LoadingPrecoInProgressTxt: string = "Chargement en cours ...";
  //titl modal de la préco tech sélectionnée
  public title: string = '';
  private _gridEmptyText = this._emptyRecordText;
  //#endregion

  ///résultat de la requete des farmer previs pour une préco
  private _precoOrderFarmerPrevis: farmerPrevisOrdersPrecosDatas = null;

  ///données du grid de base présente les exploitantion precos
  private _gridFarmsPrecos: gridFarmerPrevisDatas = null;
  //le nombre de preco selectionnés
  private _precoSelectedCounter: number = 0;
  //si on est sur un multi exploitation
  private _isMultiFarm: boolean = true;
  private _totalGridRows: number = 0;

  private existSelectable:boolean=false;

  /**le nombre de preco selectionnés */
  public get SelectionCounter() {
    return this._precoSelectedCounter;
  }

  public get TotalGridRows() {
    return this._totalGridRows;
  }

  /**Indique si on est sur un multi exploitation */
  public get IsMultiFarm() {
    return this._isMultiFarm;
  }

  ///données du grid de base présente les exploitantion precos
  public get GridFarmsPrecos() {
    return this._gridFarmsPrecos;
  }

  //indique que le chargement des preco est en cours
  public get IsLoadingInProgress() {
    return this._isLoadingPrevis == true;
  }

  //texte à afficher si pas de données grid (selon etat)
  public get EmptyRecordText() {
    return this._gridEmptyText;
  };

  

  public constructor(private _farmerPreviService: FarmerPrevisService, private _eventEmitterService: EventEmitterService, private farmDataService: FarmServices, private _loadingService: LoadingService) {
  }

  public ngOnInit() {

    if (FarmerPrevisService.ConfigEnableDuplication() == true) {
      this.openModalTechPrecoSubs = this._eventEmitterService.invokeDuplicateTechPrecoDialogChange.subscribe((precoOptions: EventActionModalLegend) => {
        if (precoOptions.IsOpen && this._farmerPreviService.IsDuplicationAccess && precoOptions?.LegendSelected && precoOptions.IsSrcPrevis == false) {
          this.opened = true;
          this.onLegendDuplicatePrecoTechClick(precoOptions?.LegendSelected)
        } else {
          //cas d'un changement de campagne il faut effacer et refermer la modale
          this.Clear();
          this.close()
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.openModalTechPrecoSubs.unsubscribe();
  }

  //nettoyage des données de la préconisation tech farmer previs chargé
  private Clear() {
    this._gridFarmsPrecos = null;
    this._precoSelectedCounter = 0;
    this._isMultiFarm = false;
    this._precoOrderFarmerPrevis = null;
    this.grdCheckAllChecked=false;
    this.existSelectable = false;
  }

  /**Fermeture de la modale */
  public close() {
    if (this.opened) {
      this.opened = false;
      this.DialogClosed.emit(this.opened);
    }
  }

  /**event changement de checkbox selection toute les previs selectionnables*/
  public onChangeCheckAll(){
    if(this._gridFarmsPrecos.Exists()) {
      let setSelected = this.grdCheckAllChecked == false; 
      let allow= false;
      this._gridFarmsPrecos.Previs.forEach(fPrevis=>{
        if(fPrevis.enabled == true){
          fPrevis.selected = setSelected;
          allow = true;
        }
      })
      if(!allow){
        //plus aucun selectionnable
        this.grdCheckAllChecked = true
      }
      else {
        this.UpdateSelectionCounter();
      }
    }
  }

  /** event sur clic duplication de preconisation tech */
  onLegendDuplicatePrecoTechClick(_iPrecoSelected: LegendItem) {

    this._precoOrderFarmerPrevis = null;
    this._gridFarmsPrecos = null;
    this.ChangeStatusLoading(true);

    //contrôle si on est sur une legende de preco Pilot SP
    let _blisPrecoPilotSP = _iPrecoSelected.uniqueFormatedId && FarmerPrevisService.IsLegendUniqIdOnType(_iPrecoSelected.uniqueFormatedId, eTechInterType.PilotSp);

    //on charge la liste des order id concernés par le type de presta de la préconisation
    let prestaOrderIds = _blisPrecoPilotSP ? this.farmDataService.GetOrderIdsOnPilotSPPrestation() : this.farmDataService.GetOrderIdsOnAdvicePrestation();

    if (_iPrecoSelected && _iPrecoSelected.uniqueFormatedId && prestaOrderIds?.length) {
      this.duplicatedPreco = _iPrecoSelected;
   
      this.title = this.titlePrefix + _iPrecoSelected?.legendName;
      this._farmerPreviService.GetFarmerPrevisDatas(_iPrecoSelected.uniqueFormatedId, prestaOrderIds, true).subscribe(
        (data: any) => {
          if (data && data.Orders?.length) {
            this._precoOrderFarmerPrevis = new farmerPrevisOrdersPrecosDatas(data, true)
            this.BindFrontOrdersPrevisExchangeData(this._precoOrderFarmerPrevis);
          }
          this.ChangeStatusLoading(false);
        },
        (error: any) => {
          alert('Une erreur est survenue accès impossible .');
          this.ChangeStatusLoading(false);
          this.close()
        }
      )
    } else {
      this.ChangeStatusLoading(false);
      this.close();
    }
  }

  /**Constitution et remplissage des données des parcelles de la  Préconisation tech sélectionné */
  private BindFrontOrdersPrevisExchangeData(_iResponse: farmerPrevisOrdersPrecosDatas) {
    this.grdCheckAllChecked = false;
    this.existSelectable = false;
    this._gridFarmsPrecos = new gridFarmerPrevisDatas(null, false);
    if (_iResponse) {
      this._gridFarmsPrecos = _iResponse.Map(this.PrecoEverDuplicatedTxt, true);
    }

    this._isMultiFarm = _iResponse.Orders.length > 1;
    this.UpdateSelectionCounter();
    this._totalGridRows = this._gridFarmsPrecos?.Previs.length ?? 0;
     //si au chargement > 1 selection on coche les autres sont non sélectionnable
     if(this._gridFarmsPrecos.GetGridSelection()?.length > 0){
      this.grdCheckAllChecked = true;
      this.existSelectable  =true;
    }
    
  }

  /**MAJ du compteur d'éléments sélectionnés */
  private UpdateSelectionCounter() {
    this._precoSelectedCounter = this._gridFarmsPrecos?.GetSelectedCounter() ?? 0
  }

  /**Changement du statut de chargement */
  private ChangeStatusLoading(_iIsLoadingProcess: boolean) {

    if (_iIsLoadingProcess == true) {
      this.ShowLoaderWaiting();
    } else {
      this.HidLoader();
    }
    this._isLoadingPrevis = _iIsLoadingProcess == true;
    this._gridEmptyText = _iIsLoadingProcess == true ? this._emptyRecordText : this._loadingRecordText;
  }


  //sur une selection de parcelle preco
  public onFarmPlotPreviSelection(selection: any) {
    selection.selected = !selection.selected;
    this.UpdateSelectionCounter();
  }

  //sur event click suivant
  public onNextClick(event: any) {

    let previsSelected = this._gridFarmsPrecos.GetGridSelection();
    if (previsSelected?.length && this._precoOrderFarmerPrevis?.Exists(true) == true) {
      //on récupere les données prévis initialisée clone
      let formatedPrevis = this._precoOrderFarmerPrevis.Clone();

      //on filtre sur celles sélectionnées et on nettoie
      formatedPrevis.FilterByGridRows(previsSelected, true);
      if (formatedPrevis.Exists(true)) {
        this.Clear();

        //on ajout les preconisations initialisées depuis les inter previs car non récupéré depuis l'appel preco
        //pas les micros inutile
        formatedPrevis.Orders.forEach(foOrder => {
          foOrder.InitPreconisationsFromPrevis();
        })

        let precosinitialDatas = new EventActionModalLegend(true, null, false, formatedPrevis);

        this._eventEmitterService.emitCreationFarmPrevisDialogChange(precosinitialDatas);
        this.close();
      } else {
      }

    } else {
    }
  }

  //sur event fermeture
  public onClose() {
    this.close();
  }

  //visualiser le chargmeent loader
  ShowLoaderWaiting() {
    this._loadingService.show("Merci de patienter durant le chargement des préconisations ...");
  }

  //dissimuler le loader
  HidLoader() {
    this._loadingService.hide();
  }
}
