import { Injectable, NgZone, ElementRef, OnDestroy } from '@angular/core';
import { SelectableService } from './selectable.service';
import { AttributToTextService } from './attribut-to-text.service';
import { LegendService, LegendsConfig } from './legend.service';
import { Subscription } from 'rxjs';
import { Farm, Sector, Geometry, CultivationPlot, MicroPlot, LegendItem, LegendProperties, LegendProperty, Observation, LegendGroup, Legend, LegendColor } from '../_models/index';
import { environment } from '../../environments/environment';

// Déclaration Openlayers
declare var ol: any;

// déclaration projs4 (systeme de projection)
declare var proj4: any;

// Déclaration du module carto SMAG
declare var Smag: any;

// Déclaration de la configuration BEAPI
declare var SmagBeapiConfig: any;

@Injectable()
export class SharedMapService implements OnDestroy {

    // Instance openlauers
    public map: any;

    // Instance de l'interaction select
    interactionSelect: any;

    // Couches
    ilotLayer: any;
    plotLayer: any;
    microLayer: any;
    overlay: any;


    // Type de carto (parcelle ou micro)
    type: string;

    // Groupe de la légende en cours
    public currentGroup: string;

    public legendColorAttrs: Legend;
    public legendTitle: string;

    private selectableSubs: Subscription;

    //property pour legend
    public selectedLayer: string;

    //property pour legend property
    public selectedLegendProperty: string;

    ngOnDestroy() {
        this.selectableSubs.unsubscribe();
    }

    clean() {

        // Instance openlauers
        this.map = undefined;
        // Instance de l'interaction select
        this.interactionSelect = undefined;
        // Couches
        this.ilotLayer.getSourceVector().clear();
        this.plotLayer.getSourceVector().clear();
        this.microLayer.getSourceVector().clear();
        this.overlay = undefined;

        this.legendColorAttrs = null;
    }

    constructor(private _ngZone: NgZone, private selectableService: SelectableService, private att: AttributToTextService, private _legendService: LegendService) {
        // Projection Lambert 93
        proj4.defs('EPSG:2154', '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');


        var mapConfig = {
            'layers': [{
                'type': 'cartodb',
                'options': {
                    'cartocss_version': '2.1.1',
                    'cartocss': '#layer { polygon-fill: #F00; }',
                }
            }]
        };


        // Création de la couche openlayers Ilots
        this.ilotLayer = new ol.layer.Image({
            title: 'Ilots',
            source: new ol.source.ImageVector({
                source: new ol.source.Vector(),
                style: this.sectorStyleFunction
            })
        });

        // Création de la couche openlayers Parcelles
        this.plotLayer = new ol.layer.Image({
            title: 'Parcelles',
            configuration: mapConfig,
            source: new ol.source.ImageVector({
                source: new ol.source.Vector(),
                style: this.defaultStyleFunction
            })
        });

        // Création de la couche openlayers Micro parcelle
        this.microLayer = new ol.layer.Image({
            title: 'Micro',
            source: new ol.source.ImageVector({
                source: new ol.source.Vector(),
                style: this.defaultStyleFunctionPlot
            })
        });

        Smag.Config.VectorsVar = [];
        Smag.Config.VectorsCode = [];
    }

    // Initialisation de la carte
    render(type: string) {

        // Assignation du type de carto
        this.type = type;

        // Exécuter en dehors de Angular pour une question de performance
        this._ngZone.runOutsideAngular(() => {

            // Instance de la cartographie
            this.map = Smag.Map.init({
                raster: new ol.layer.Tile({
                    source: Smag.Map.Layer.geoportal('ORTHOIMAGERY.ORTHOPHOTOS', undefined, 'https://data.geopf.fr/'+'wmts'),
                    type: 'base',
                    preload: 1,
                    title: "Orthophotos Géoportail"
                }),
                projection: 'EPSG:2154',
                center: [556011.5324791223, 6859466.917189516],
                zoom: 12
            });

            const zoomButtons = new ol.control.Zoom();
            this.map.addControl(zoomButtons);
            const zoomslider = new ol.control.ZoomSlider();
            this.map.addControl(zoomslider);

            this.map.addInteraction(new ol.interaction.MouseWheelZoom({ duration: 500 }));

            // orde des couches selon le type
            if (this.type === 'plot') {
                Smag.Map.Layer.add(this.microLayer, 'micro');
                Smag.Map.Layer.add(this.plotLayer, 'plot');
                Smag.Map.Layer.add(this.ilotLayer, 'ilot');
            }
            else {
                Smag.Map.Layer.add(this.ilotLayer, 'ilot');
                Smag.Map.Layer.add(this.plotLayer, 'plot');
                Smag.Map.Layer.add(this.microLayer, 'micro');
            }

            // Ajout de l'interaction Select
            //this.map.addInteraction(this.createInteractionSelect());
        });
        return this.map;
    }

    // couche active selon le type (utile pour la sélection)
    getActiveLayer() {

        if (this.type === 'ilot') {
            var legendItem = new LegendItem();
            legendItem.name = "Sector";
            legendItem.legendName = "Ilots";
            //this.setLayer("", legendItem);
            return this.ilotLayer;
        }

        return (this.type === 'plot') ? this.plotLayer : this.microLayer;
    }

    // Création de l'interaction "Select" qui permet de sélectionner une feature
    createInteractionSelect() {

        // 2coute les changement d'état de la sélection
        this.selectableSubs = this.selectableService.getSelection().subscribe((selection: any) => {
            this.selectFeatures(selection);
        });

        let interactionSelect = new ol.interaction.Select({

            // Permet de garder la sélection
            toggleCondition: function (mbe: any) {
                return mbe.originalEvent.ctrlKey;
            },
            layers: [this.getActiveLayer()],
            style: this.defaultSelectStyleFunction
        });

        // Un clique en dehors de toutes features désélectionne la sélection courante
        this.map.on('singleclick', function (mbe: any) {
            var clickOnFeature = false;
            mbe.map.forEachFeatureAtPixel(mbe.pixel, function () {
                clickOnFeature = true;
                return clickOnFeature;
            });
            if (!clickOnFeature) {
                let deselected = interactionSelect.getFeatures().getArray().slice(0);
                interactionSelect.getFeatures().clear();
                interactionSelect.dispatchEvent(new ol.interaction.SelectEvent(ol.interaction.SelectEventType.SELECT, [], deselected));
            }
        });

        // Transmition du tableau d'ids des éléments sélectionnées
        interactionSelect.on('select', (evt: any) => {
            this.selectableService.fromMap = true;
            this.selectableService.setSelection(interactionSelect.getFeatures().getArray().map(function (item: any) { return item.getId() }));
        });

        this.interactionSelect = interactionSelect;

        return interactionSelect;
    }

    loadFeaturesFromModel(model: Farm[], layerCode: string): any {
        let plotName = "plot";
        let microName = "micro";

        if (model === null || model === undefined || !model.length) {
            this.CleanLegendMap();
            return;
        }
        
        this._legendService.loadFeatures(model);


        this._ngZone.runOutsideAngular(() => {
            if (!this.map) return;


            if (this.selectedLayer == undefined || this.selectedLayer == 'ilot') {
                this.type = 'ilot';
                let layerIlot = Smag.Map.Layer.getByCode(layerCode);
                layerIlot.getSourceVector().clear();
                layerIlot.getSourceVector().addFeatures(this._legendService.ilotFeatures);

            }
            if (this.selectedLayer == 'plot') {
                this.type = 'plot';
                let layerPlot = Smag.Map.Layer.getByCode(plotName);
                layerPlot.getSourceVector().clear();
                layerPlot.getSourceVector().addFeatures(this._legendService.plotFeatures);

            }
            if (this.selectedLayer == 'micro') {
                this.type = 'micro';
                let layerPlot = Smag.Map.Layer.getByCode(plotName);
                layerPlot.getSourceVector().clear();
                layerPlot.getSourceVector().addFeatures(this._legendService.plotFeatures);


                let layerMicro = Smag.Map.Layer.getByCode(microName);
                layerMicro.getSourceVector().clear();

                layerMicro.getSourceVector().addFeatures(this._legendService.microFeatures);
            }
            if (this.selectableService.getList().length) {
                this.selectableService.trigger();

            } else {
                // Recadrage sur la couche active
                this.fitActiveLayer();
            }
        });
    }

    // Sélection d'un ensemble de features correspondant aux identifiants passés en paramètre
    selectFeatures(selectedItems: any[]) {
        if (!this.map) return;

        this._ngZone.runOutsideAngular(() => {
            // On vide l'ancienne sélection
            this.interactionSelect.getFeatures().clear();

            // Couche vectorielle
            let sourceLayer = this.getActiveLayer().getSourceVector();

            // Etendu pour le zoom
            let extent: any;

            for (let index of selectedItems) {

                // récupération de la feature correspondant à l'identifiant
                var feature = sourceLayer.getFeatureById(index);

                if (!feature) return;

                // Création de l'extend pour le recadrage
                if (!this.interactionSelect.getFeatures().getArray().length) {
                    extent = feature.getGeometry().getExtent().slice(0);
                } else {
                    ol.extent.extend(extent, feature.getGeometry().getExtent())
                }


                // Ajout à la sélection carto
                this.interactionSelect.getFeatures().push(feature);
            }

            // Si la sélection est vide -> recadrage sur l'ensemble des features
            if (!extent) extent = sourceLayer.getExtent();

            // Recadrage
            if (isFinite(extent[0])) {
                this.map.getView().fit(extent, this.map.getSize());

                // On masque la popup car il y a déplacement
                if (this.overlay.getElement().children.length && this.overlay.getElement().children[0].children.length) {
                    this.overlay.getElement().children[0].children[0].innerHTML = '';
                    this.overlay.setPosition(undefined);
                }
            }
        });
    }
    /**Bind des donnees carto à afficher selon la legende */
    public setLayer(selectedLegend: string, legendItem: LegendItem, noZoom: boolean = false) {


        if (selectedLegend === "Diagnostic fertilité" ||
        selectedLegend === "Diagnostic potentiel" ||
        selectedLegend === "Conseil fumure" || selectedLegend === "Conseil azote") {
            this.selectedLayer = "micro";
            this.selectedLegendProperty = legendItem.legendName;

            //on ne charge pas les données de modulation ni diagnostic Annuels ou données de pilotage
            if (this._legendService.getPDFModulationTypes().length || this._legendService.GetPDFDiagnosticTypes().length || this._legendService.IncludePilotageSP()) {
                //On exclue les micro contenant de la modulation du pilotage
                var featConsOnly = this._legendService.microFeatures.filter(a => {
                    let properties = a.getProperties().properties as LegendProperties[];
                    let selectedProperty = properties.find(p => {
                        //on exclue les données Modulation et Annuels et pilotage
                        return (p.legendGroupKey !== LegendsConfig.legdModulations && p.legendGroupKey !== LegendsConfig.legdAnnaAzote && p.legendGroupKey !== LegendsConfig.legdAnnaRend && p.legendGroupKey !== LegendsConfig.legdAnnaCarboN && p.legendGroupKey !== LegendsConfig.legdPilotSpLAI && p.legendGroupKey !== LegendsConfig.legPilotSpPreco);
                    });
                    if (selectedProperty != null)
                    return a;
                });
                this.ClearVectorsSetNewFeatures(featConsOnly, noZoom);
            }
            else
            this.ClearVectorsSetNewFeatures(this._legendService.microFeatures, noZoom);

        } else if (legendItem.name === "CultivationPlots") {
            this.selectedLayer = "plot";
            this.selectedLegendProperty = legendItem.legendName;

            this.ClearVectorsSetNewFeatures(null, false, this._legendService.plotFeatures);

        } else if (legendItem.name === "Sector") {
            this.selectedLegendProperty = legendItem.legendName;
            this.selectedLayer = "ilot";

            this.ClearVectorsSetNewFeatures(null, noZoom, null, this._legendService.ilotFeatures)
        }

        else if (LegendService.IsPreconisationLegend(selectedLegend)) {

            let mcFeatures = this._legendService.GetMicroFeatureGroup(selectedLegend, legendItem.legendName);
            this.ClearVectorsSetNewFeatures(mcFeatures, noZoom);
            // this.reZoomUsingFeature(featuresSelected);
        }
        else if (selectedLegend == LegendsConfig.legdAnnaRend || selectedLegend == LegendsConfig.legdAnnaAzote || LegendsConfig.legdAnnaCarboN) {

            let mcFeatures = this._legendService.GetMicroFeatureGroup(selectedLegend);
            this.ClearVectorsSetNewFeatures(mcFeatures, noZoom);
        }

        else if (selectedLegend == LegendsConfig.legdPilotSpLAI) { // on dispose de 2 legendes MAIS de données diverses donc on filtre sur le code

            var mcFeatures = this._legendService.GetMicroFeatureGroup(selectedLegend, legendItem.name)
            this.ClearVectorsSetNewFeatures(mcFeatures, noZoom);
        }
    }

    /**
    * nettoyage des vecteurs et attribution des nouvelles données micros à afficher
    * @param _iMcFeatures ensemble des données micros selectionnées à afficher
    * @param noZoom centralise sur le layer
    * @param _iPlotsFeatures si != null alors ajout les couches des parcelles
    * @param _iSectorsFeatures si != null alors ajout les couches ilot
    */
    private ClearVectorsSetNewFeatures(_iMcFeatures: any, noZoom: boolean, _iPlotsFeatures: any = null, _iSectorsFeatures: any = null) {
        let layerIlot = Smag.Map.Layer.getByCode("ilot");
        layerIlot.getSourceVector().clear();
        if (_iSectorsFeatures !== null) {
            layerIlot.getSourceVector().addFeatures(_iSectorsFeatures);
        }

        let layerPlot = Smag.Map.Layer.getByCode("plot");
        layerPlot.getSourceVector().clear();
        if (_iPlotsFeatures !== null) {
            layerPlot.getSourceVector().addFeatures(_iPlotsFeatures);
        }

        let layerMicro = Smag.Map.Layer.getByCode("micro");
        layerMicro.getSourceVector().clear();
        if (_iMcFeatures !== null)
        layerMicro.getSourceVector().addFeatures(_iMcFeatures);


        if (!noZoom) this.fitActiveLayer();
    }

    setStyleConseils(attr: LegendItem, group: string, blAroundToInt: boolean = true): any {

        this.InitializeLegendInfo(attr, false, blAroundToInt);
        let styleFunction = (feature: any, resolution: number) => {
            let legend = attr.style;
            let color: string = '#cccccc';

            // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
            var extent = feature.getGeometry().getExtent();
            var size = ol.extent.getSize(extent);
            var geomW = size[0] / resolution; // la résolution permet de gérer la taille en fonction du niveau de zoom

            var label: string = "";

            if (!feature.get("properties"))
            return;
            var propertyLegend = feature.get("properties").filter(t => t.legendGroupKey == group);
            if (propertyLegend != null && propertyLegend.length > 0) {
                var legendAttr = propertyLegend[0].legendProperies.filter(t => t.propertyName == attr.legendName);
                if (legendAttr != null && legendAttr.length > 0) {
                    if (legendAttr[0].propertyValue && legendAttr[0].propertyValue !== "")
                    label = legendAttr[0].propertyValue + " " + legendAttr[0].unit;
                    //get style
                    color = this.GetLegendColorByValue(legendAttr[0].propertyValue);
                }
            }
            //  let label = feature.get("namePlot") || '';
            let textLength = label.length * 10;

            let styles = [];
            styles.push(new ol.style.Style({
                fill: new ol.style.Fill({
                    color: color
                }),
                stroke: new ol.style.Stroke({
                    color: '#000000',
                    width: 1
                }),
                text: new ol.style.Text({
                    font: '12px Calibri,sans-serif',
                    fill: new ol.style.Fill({ color: '#000' }),
                    stroke: new ol.style.Stroke({
                        color: '#fff', width: 3
                    }),
                    // get the text from the feature - `this` is ol.Feature
                    text: (textLength <= geomW) ? label : '',
                })
            }));

            return styles;
        };
        this.microLayer.getSource().setStyle(styleFunction);
    }

    /**
    * Mise à jour du style selon la légende sélectionné (seulement sur le micro parcelle)
    * pour les diagnostics
    * @param attr
    */
    setDiagnosticStyle(attr: LegendItem, group: string) {
        // avec chg Culture peut ne plus avoir de legend
        this.legendTitle = "";
        this.legendColorAttrs = null;
        //on rejette l'attibution de couleur si la feature ne dispose pas de la legende
        //cas d'un group de legende sur des orders différents donc superposition de micro contours
        let _withEqLegAttributName = false;

        // nom de la propriete à afficher sans modif de l attribut
        let _liblPropertie = attr.name.toUpperCase();
        switch  (_liblPropertie){
            case LegendsConfig.legAnnaRend_Indic:
            _liblPropertie = LegendsConfig.legLibAnnaRend_Indic;
            break;
            case LegendsConfig.legDiag_SP_Lai:
            _liblPropertie = LegendsConfig.legLibDiag_LAI;
            _withEqLegAttributName = true;
            break;
            case LegendsConfig.legOrd_SP_Lai_Advice_N:
            _liblPropertie = LegendsConfig.legLibOrd_SP_Lai_Advice_N;
            _withEqLegAttributName = true;
            break;
            case LegendsConfig.legAnnaRend_Rendement:
            _liblPropertie = LegendsConfig.legLibAnnaRend_Rendement;
            break;
            case LegendsConfig.legAnnaCarboN_Biom:
            _liblPropertie = LegendsConfig.legLibAnnaCarboN_CarboN;
            break;
            case LegendsConfig.legAnnaAzoteEH:
            _liblPropertie = LegendsConfig.legLibAnnaAzoteEH;
            break;
            case LegendsConfig.legAnnaAzoteSH:
            _liblPropertie = LegendsConfig.legLibAnnaAzoteSH;
            break;
            case LegendsConfig.legAnnaAzoteNAbs:
            _liblPropertie = LegendsConfig.legLibAnnaAzoteNAbs;
            break;
            case LegendsConfig.legFertiMATORG:
            _liblPropertie = LegendsConfig.legLibFertiMATORG;
            break;
        }

        return this.setLegendStyle(attr, group, _liblPropertie, _liblPropertie, _withEqLegAttributName);
    }

    /**
    *  Mise à jour du style selon la légende (Farm.Legendes) sélectionné (seulement sur le micro parcelle)
    * @param attr
    * @param group
    * @param _iSpecPropertLibel Si !=null on Affiche legende (color) titre Spécifique indiqué SINON legend.Name
    * @param _iSpecPropertName Si !=null on recherche la propriete Name Spécifique indiqué pour correspondance valeur
    * @param _iblCheckSameName Si true on regarde la présence de l attribut name et correspondance (cas de supeposition de contour different order)
    */
    setLegendStyle(attr: LegendItem, group: string, _iSpecPropertLibel: string = null, _iSpecPropertName: string = null, _iblCheckSameAttrName:boolean = false, _iblClearOnEmptyLegend: boolean = false) {
        this.legendTitle = "";
        this.legendColorAttrs = null;

        let styleFunction = (feature: any, resolution: number) => {

            var legends: Array<Legend> = feature.get("farm").Legends;

            let color: string = '#cccccc';
            // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
            var extent = feature.getGeometry().getExtent();
            var size = ol.extent.getSize(extent);
            var geomW = size[0] / resolution; // la résolution permet de gérer la taille en fonction du niveau de zoom
            var label: string = "";

            if (feature.get("properties") != null) {
                var propertyLegend = feature.get("properties").filter(t => t.legendGroupKey == group);


                if (propertyLegend != null && propertyLegend.length > 0) {

                    let _firstLegend = propertyLegend[0];

                    if(_iblCheckSameAttrName == true && attr.name && _firstLegend.legendItemKey && attr.name !== _firstLegend.legendItemKey) {
                        //cas des legendes groupées d'order différent (donc superposition de contour et données micro)
                      // donc on ne gère pas cette feature ni la légende si elle n est pas trouvée
                       return;
                    }
                    else{
                        let _libelProp = _iSpecPropertName ? _iSpecPropertName : attr.name;
                        var legendAttr = _firstLegend.legendProperies.filter(t => t.propertyName == _libelProp);
                        if (legendAttr != null && legendAttr.length > 0) {
                            if (legendAttr[0].propertyValue && legendAttr[0].propertyValue !== "")
                            label = legendAttr[0].propertyValue + " " + legendAttr[0].unit;
                            //get style
                            color = this.GetLegendColorByValue(legendAttr[0].propertyValue);
                        }
                    }
                }
            }

              //légendes
              if (legends != null) {
                var legend = legends.find(l => l.Name.toUpperCase() == attr.name.toUpperCase());
                if (legend != null && (this.legendColorAttrs == null || legend !== this.legendColorAttrs)) {
                    let _libelProp = _iSpecPropertLibel ? _iSpecPropertLibel : legend.Name;
                    this.legendColorAttrs = legend;
                    this.legendTitle = attr.unit && attr.unit.length ? _libelProp + " (" + attr.unit + ")" : _libelProp;
                }
            }

            let textLength = label.length * 10;
            let styles = [];
            styles.push(new ol.style.Style({
                fill: new ol.style.Fill({
                    color: color
                }),
                stroke: new ol.style.Stroke({
                    color: '#000000',
                    width: 1
                }),
                text: new ol.style.Text({
                    font: '12px Calibri,sans-serif',
                    fill: new ol.style.Fill({ color: '#000' }),
                    stroke: new ol.style.Stroke({
                        color: '#fff', width: 3
                    }),
                    // get the text from the feature - `this` is ol.Feature
                    text: (textLength <= geomW) ? label : '',
                })
            }));
            return styles;
        };


        this.microLayer.getSource().setStyle(styleFunction);
    }

    setStyleAssolement(attr: LegendItem, group: string): any {

        let styleFunction = (feature: any, resolution: number) => {

            let color: string = '#cccccc';

            // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
            var extent = feature.getGeometry().getExtent();
            var size = ol.extent.getSize(extent);
            var geomW = size[0] / resolution; // la résolution permet de gérer la taille en fonction du niveau de zoom
            this.legendColorAttrs = null;
            this.legendTitle = "";
            let label: string = "";
            if (feature.get("properties") != null) {
                var propertyLegend = feature.get("properties").filter(t => t.legendGroupKey == group);
                if (propertyLegend != null && propertyLegend.length > 0) {

                    let propertyRelativeAvecModulationFumer = propertyLegend.filter(property => property.legendItemKey === attr.legendName);
                    if (propertyRelativeAvecModulationFumer && propertyRelativeAvecModulationFumer.length) {
                        let legendAttr = propertyRelativeAvecModulationFumer[0].legendProperies.filter(t => t.propertyName == "Culture");
                        if (legendAttr != null && legendAttr.length > 0) {
                            if (legendAttr[0].propertyValue != null && legendAttr[0].propertyValue !== "")
                            label = legendAttr[0].propertyValue;
                        }
                        let legendColorAttr = propertyRelativeAvecModulationFumer[0].legendProperies.filter(t => t.propertyName == "Culture_color");
                        if (legendColorAttr != null && legendColorAttr.length > 0) {
                            if (legendColorAttr[0].propertyValue != null && legendColorAttr[0].propertyValue != "")
                            color = legendColorAttr[0].propertyValue.match('#') ? legendColorAttr[0].propertyValue : '#' + legendColorAttr[0].propertyValue;;
                        }
                    }
                }

                let styles = [];
                let textLength = label.length * 10;
                styles.push(new ol.style.Style({
                    fill: new ol.style.Fill({
                        color: color
                    }),
                    stroke: new ol.style.Stroke({
                        color: '#000000',
                        width: 1
                    }),
                    text: new ol.style.Text({
                        font: '12px Calibri,sans-serif',
                        fill: new ol.style.Fill({ color: '#000' }),
                        stroke: new ol.style.Stroke({
                            color: '#fff', width: 3
                        }),
                        // get the text from the feature - `this` is ol.Feature
                        text: (textLength <= geomW) ? label : '',
                    })
                }));

                return styles;
            };
        }
        this.plotLayer.getSource().setStyle(styleFunction);
    }


    setStylePotentiel(attr: LegendItem, group: string) {
        this.InitializeLegendInfo(attr, true);
        let styleFunction = (feature: any, resolution: number) => {
            let legend = attr.style;
            let color: string = '#cccccc';

            // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
            var extent = feature.getGeometry().getExtent();
            var size = ol.extent.getSize(extent);
            var geomW = size[0] / resolution; // la résolution permet de gérer la taille en fonction du niveau de zoom

            var label: string = "";

            if (!feature.get("properties"))
            return;
            var propertyLegend = feature.get("properties").filter(t => t.legendGroupKey == group);
            if (propertyLegend != null && propertyLegend.length > 0) {
                let legendAttr = propertyLegend[0].legendProperies.filter(t => t.propertyName == attr.legendName);
                if (legendAttr != null && legendAttr.length > 0) {
                    if (legendAttr[0].propertyValue != null && legendAttr[0].propertyValue !== "") {
                        label = legendAttr[0].propertyValue + " " + legendAttr[0].unit;
                    }
                    color = this.GetLegendColorByValue(legendAttr[0].propertyValue);
                }
            }

            //  let label = feature.get("namePlot") || '';
            let textLength = label.length * 10;

            let styles = [];
            styles.push(new ol.style.Style({
                fill: new ol.style.Fill({
                    color: color
                }),
                stroke: new ol.style.Stroke({
                    color: '#000000',
                    width: 1
                }),
                text: new ol.style.Text({
                    font: '12px Calibri,sans-serif',
                    fill: new ol.style.Fill({ color: '#000' }),
                    stroke: new ol.style.Stroke({
                        color: '#fff', width: 3
                    }),
                    // get the text from the feature - `this` is ol.Feature
                    text: (textLength <= geomW) ? label : '',
                })
            }));

            return styles;
        };
        this.microLayer.getSource().setStyle(styleFunction);
    }

    /**
    * attribution de couleur pour la valeur de la propriete
    * @param attr
    * @param _iLegGroup Legend.Group
    * @param _iMcPropKey intitulé de la propriété dans le tooltip a faire correspondre selon la legende interface
    */
    setStyleModulationFumure(attr: LegendItem, _iLegGroup: string, _iMcPropKey: string = "Dose") {
        this.InitializeLegendInfo(attr, true);
        let styleFunction = (feature: any, resolution: number) => {
            let color: string = '#cccccc';

            // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
            var extent = feature.getGeometry().getExtent();
            var size = ol.extent.getSize(extent);
            var geomW = size[0] / resolution; // la résolution permet de gérer la taille en fonction du niveau de zoom

            var label: string = "";

            if (!feature.get("properties"))
            return;
            let propertyLegends = feature.get("properties").filter(t => t.legendGroupKey == _iLegGroup);

            if (propertyLegends && propertyLegends.length) {
                let propertyRelativeAvecModulationFumer = propertyLegends.filter(property => property.legendItemKey === attr.legendName);
                if (propertyRelativeAvecModulationFumer && propertyRelativeAvecModulationFumer.length)
                var legendAttr = propertyRelativeAvecModulationFumer[0].legendProperies.filter(t => t.propertyName == _iMcPropKey);

                if (legendAttr != null && legendAttr.length > 0) {
                    if (legendAttr[0].propertyValue != null && legendAttr[0].propertyValue !== "") {
                        label = legendAttr[0].propertyValue + " " + legendAttr[0].unit;
                    }

                    //get style
                    color = this.GetLegendColorByValue(legendAttr[0].propertyValue);
                }

            }
            //  let label = feature.get("namePlot") || '';
            let textLength = label.length * 10;

            let styles = [];
            styles.push(new ol.style.Style({
                fill: new ol.style.Fill({
                    color: color
                }),
                stroke: new ol.style.Stroke({
                    color: '#000000',
                    width: 1
                }),
                text: new ol.style.Text({
                    font: '12px Calibri,sans-serif',
                    fill: new ol.style.Fill({ color: '#000' }),
                    stroke: new ol.style.Stroke({
                        color: '#fff', width: 3
                    }),
                    // get the text from the feature - `this` is ol.Feature
                    text: (textLength <= geomW) ? label : '',
                })
            }));

            return styles;
        };
        this.microLayer.getSource().setStyle(styleFunction);
    }

    private GetLegendColorByValue(value: any): string {
        let colorSelectedByValue: string = '#cccccc';
        if (this.legendColorAttrs != null && value != null && value != undefined && value !== '') {
            for (let i = 0, len = this.legendColorAttrs.Class.length; i < len; i++) {
                let c = this.legendColorAttrs.Class[i];
                if (c.MaxValue >= Number(value)) {
                    colorSelectedByValue = c.Color;
                    break;
                }
            }
        }

        return colorSelectedByValue;
    }

    /**
    * Initialise les legend du component Gauche
    * @param attr
    * @param shouldInitalizeColorInfo initialise les couleurs de legende et arrondit à 2 decimal ou en entier selon blAroundToInt
    * @param blAroundToInt on arrondit les valeurs de legende à un entier
    */
    private InitializeLegendInfo(attr: LegendItem, shouldInitalizeColorInfo: boolean, blAroundToInt: boolean = false): void {
        let legend = attr.style;
        if (legend != null) {
            if (shouldInitalizeColorInfo) {
                let limitedecimale: number = 100;
                let previousLegendMaxValue = 0;
                let lastIndexValue: number = legend.Class != null && legend.Class.length > 0 ? legend.Class.length - 1 : 0;
                legend.Class.forEach((element, index) => {
                    element.Color = "rgb(" + Math.round(element.Red)
                    + "," + Math.round(element.Green)
                    + "," + Math.round(element.Blue) + ")";
                    if (blAroundToInt) {
                        element.MaxValue = this.roundUp(element.MaxValue, 0);
                        element.MinValue = previousLegendMaxValue;
                        previousLegendMaxValue = element.MaxValue;
                    }
                    else {
                        element.MaxValue = (lastIndexValue == index) ? this.roundUp(element.MaxValue, 2) : Math.round(element.MaxValue * limitedecimale) / limitedecimale;
                        element.MinValue = previousLegendMaxValue != 0 ? previousLegendMaxValue : this.roundUp(element.MinValue, 2);
                        previousLegendMaxValue = element.MaxValue;
                    }
                });
            }
            else if (blAroundToInt) {
                legend.Class.forEach((element, index) => {
                    element.MaxValue = this.roundUp(element.MaxValue, 0);
                    element.MinValue = this.roundUp(element.MinValue, 0);
                });
            }
            this.legendColorAttrs = legend;
            this.legendTitle = attr.unit && attr.unit.length ? legend.Name + " (" + attr.unit + ")" : legend.Name;
        }
        else {
            this.legendTitle = "";
            this.legendColorAttrs = null;
        }
    }

    roundUp(num, precision) {
        precision = Math.pow(10, precision);
        return (Math.ceil(num * precision) / precision);
    }

    setStyleIlot(attr: LegendItem, group: string): any {
        let styleFunction = (feature: any, resolution: number) => {
            this.legendColorAttrs = null;
            this.legendTitle = "";
            let label: string = "";

            if (feature.get("properties") != null) {
                var propertyLegend = feature.get("properties").filter(t => t.legendGroupKey == group);
                if (propertyLegend != null && propertyLegend.length > 0) {
                    let legendAttr = propertyLegend[0].legendProperies.filter(t => t.propertyName == "Ilot n°");
                    if (legendAttr != null && legendAttr.length > 0) {
                        if (legendAttr[0].propertyValue != null && legendAttr[0].propertyValue !== "")
                        label = legendAttr[0].propertyValue;
                    }
                }

                let styles = [];
                let textLength = label.length * 10;
                // Calcul des dimmentions en pixel, utile pour l'affichage du label intérieur
                var extent = feature.getGeometry().getExtent();
                var size = ol.extent.getSize(extent);
                var geomW = size[0] / resolution;
                // la résolution permet de gérer la taille en fonction du niveau de zoom
                let fills = new ol.style.Fill({
                    color: 'rgba(255,255,255,0)'
                });
                let stroke_ilot = new ol.style.Stroke({
                    color: '#d331d3',
                    width: 2
                });
                styles.push(new ol.style.Style({
                    stroke: stroke_ilot,
                    text: new ol.style.Text({
                        font: '12px Calibri,sans-serif',
                        fill: new ol.style.Fill({ color: '#000' }),
                        stroke: new ol.style.Stroke({
                            color: '#fff', width: 3
                        }),
                        // get the text from the feature - `this` is ol.Feature
                        text: (textLength <= geomW) ? label : '',
                    })
                }));

                return styles;
            };
        }
        this.ilotLayer.getSource().setStyle(styleFunction);
    }
    getColorStyle(feature: any, attr: string, group: string) {
        let color: string = '#cccccc';

        const colorScale = (feature.get(attr)) ? feature.get(attr).style : undefined;

        if (colorScale && feature.get(attr).group === group) {
            let value = feature.get(attr).value;
            for (let i = 0; i < colorScale.length - 1; i++) {
                let cs1 = colorScale[i];
                let cs2 = colorScale[i + 1];

                if (value >= cs1.range && value <= cs2.range) {
                    color = (value - cs1.range < cs2.range - value) ? cs1.color : cs2.color;
                }
            }

            if (color === '#cccccc') {
                let lastScale = colorScale[colorScale.length - 1];
                if (value > lastScale.range) color = lastScale.color
            }
        }
        return color;
    }

    /**
    * Mise à jour des propriété d'une feature
    * @param properties
    */
    updateData(properties: any) {
        var feature = this.microLayer.getSourceVector().getFeatureById(properties.indexId);

        feature.setProperties(properties);
        feature.changed();
    }

    refreshMicro() {
        var feature = this.microLayer.getSourceVector().getFeatures()[0];

        if (feature) feature.changed();
    }

    /**
    * Recadrage de la carto
    */
    fitActiveLayer() {
        this._ngZone.runOutsideAngular(() => {
            if (this.map.getSize()) {
                let extent = this.getActiveLayer().getSourceVector().getExtent();
                if (extent && isFinite(extent[0]) && this.map) this.map.getView().fit(extent, this.map.getSize());
            }
        });
    }

    /**
    * Style des Ilos
    * @param feature
    * @param resolution
    */
    sectorStyleFunction(feature: any, resolution: number) {
        var fill = new ol.style.Fill({
            color: 'rgba(255,255,255,0)'
        });
        var stroke = new ol.style.Stroke({
            color: '#d331d3',
            width: 2
        });
        let styles: any[] = [
            new ol.style.Style({
                image: new ol.style.Circle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5
                }),
                fill: fill,
                stroke: stroke
            })
        ];

        return styles;
    }

    /**
    * Style des feature parcelle et micro
    * @param feature
    * @param resolution
    */
    defaultStyleFunction(feature: any, resolution: number) {
        var fill = new ol.style.Fill({
            color: 'rgba(255,255,255,0)'
        });
        var stroke = new ol.style.Stroke({
            color: '#ffff00',
            width: 2
        });
        let styles: any[] = [
            new ol.style.Style({
                image: new ol.style.Circle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5
                }),
                fill: fill,
                stroke: stroke
            })
        ];

        return styles;
    }

    defaultStyleFunctionPlot(feature: any, resolution: number) {
        var fill = new ol.style.Fill({
            color: 'rgba(255,255,255,0)'
        });
        var stroke = new ol.style.Stroke({
            color: '#3377CC',
            width: 2
        });
        let styles: any[] = [
            new ol.style.Style({
                image: new ol.style.Circle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5
                }),
                fill: fill,
                stroke: stroke
            })
        ];

        return styles;
    }

    /**
    * Style d'une feature sélectionnée
    * @param feature
    * @param resolution
    */
    defaultSelectStyleFunction(feature: any, resolution: number) {
        var fill = new ol.style.Fill({
            color: 'rgba(255,255,255,0)'
        });
        var stroke = new ol.style.Stroke({
            color: '#3399CC',
            width: 3
        });
        let styles: any[] = [
            new ol.style.Style({
                stroke: new ol.style.Stroke({
                    color: '#FFFFFF',
                    width: 5
                })
            }),
            new ol.style.Style({
                image: new ol.style.Circle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5
                }),
                fill: fill,
                stroke: stroke
            })
        ];

        return styles;
    }

    /**
    * Nettoyage des legendes et des contours parcelles sur la carte
    * @param feature
    * @param resolution
    */
    CleanLegendMap() {
        if (this._legendService != null)
        this._legendService.clean();
        this.cleaning();
    }

    private cleaning() {
        // Instance de l'interaction select
        this.interactionSelect = undefined;
        // Couches
        this.ilotLayer.getSourceVector().clear();
        this.plotLayer.getSourceVector().clear();
        this.microLayer.getSourceVector().clear();
        this.legendColorAttrs = null;
    }
}
