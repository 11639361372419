import { HandleService } from "../../_services";
import { Intervention } from "./intervention";

/**Class dédiée parcelle intervention prévisionnelle farmer */
export class InterPrevi extends Intervention {

  //#region Properties
  //identifiant unique front de la prévis
  private _uniqueId: number = null;

  //type (libellé) de sa préco dont il découle //code du type de la preconisation tech (eTechInterType : Preconisation = 3,PilotSp = 4(non géré là :Modulation = 5,InterModePreco = 2,Intervention = 1)
  type: string;
  //nom formaté
  name: string;
  //le status texte de l'inter previs
  currentStatus: string;
  ////code du type de la preconisation tech (eTechInterType : Preconisation = 3,PilotSp = 4(non géré là :Modulation = 5,InterModePreco = 2,Intervention = 1)
  SrcType: eTechInterType;
  //identifiant de la préco dont il découle
  SrcIdInter: number;
  //le status code de l'inter previs (0=Initial, Create=1,Updating=2(à maj en db), Deleting=3(à supprimer), 4=Created_Broken_Link(preco plus présente),5=Created_Diff_Props(le produit ou date a changé entrer previs et preco source))
  Status: number;

  //le status code de l'enregistrement l'inter previs (0=NoAction, Created=1,Updated=2(à maj en db), Deleted=3(supprimée), 4=OverWrited(intervention selon idsource existante donc surchargée en db),5=Failed_Tech_OverWrited_no_Sector(non trouvé))
  SavingStatus: number | null;

  /**retourne l'identifiant de la prévis
   * Si c est une previs existante retourne idIntervention sinon un id_unique
   */
  public get UniquePrevisId(): number {
    if (this.IdIntervention !== null && this.IdIntervention > 0) {
      return this.IdIntervention
    } else {
      return this._uniqueId;
    }
  }
  //#endregion

  public constructor(_iPrevis: InterPrevi) {
    super(_iPrevis, false)
    this.type = _iPrevis.type;
    this.SrcType = _iPrevis.SrcType;
    this.name = _iPrevis.name;
    this.currentStatus = _iPrevis.currentStatus;
    this.SrcIdInter = _iPrevis.SrcIdInter;
    this.Status = _iPrevis.Status;
    this.SavingStatus = _iPrevis.SavingStatus;
    if (_iPrevis._uniqueId !== null && _iPrevis._uniqueId > 0) {
      this._uniqueId = _iPrevis._uniqueId;

    } else {
      this._uniqueId = this.Ident + Date.now() + Math.random();
    }

  }

  /**Indique si c'est à la base une preconisation conseil tech */
  public IsTechAdvicePreco(): boolean {
    return this.SrcType == eTechInterType.Preconisation;
  }

  /**Indique si c'est à la base une preconisation pilotage SP tech */
  public IsTechPilotSP() {
    return this.SrcType == eTechInterType.PilotSp;
  }

  /**Indique si c'est à la base une preconisation pilotage SP tech 
   * NON valorisé jusque là
  */
  public IsTechModulation(): boolean {
    return this.SrcType == eTechInterType.Modulation;
  }

  /**Indique si c'est à la base une inter mode preco orga conseil tech 
  * NON valorisé jusque là
 */
  public IsTechInterPreco(): boolean {
    return this.SrcType == eTechInterType.InterModePreco;
  }

  /**Indique si c'est à la base une  intervention conseil tech 
* NON valorisé jusque là
*/
  public IsTechInter(): boolean {
    return this.SrcType == eTechInterType.Intervention;
  }

  /**Indique que l'intervention est initialisée (inexistant en db) */
  public IsInitial(): boolean {
    return this.Status == Number(ePrevisStatus.Initial);
  }

  /**Indique que l'intervention est crée, présente en db */
  public IsCreated(): boolean {
    return this.Status == Number(ePrevisStatus.Created);
  }

  /**Indique que le statut est "à enregistrer suite modif ou creation" */
  public IsToUpdate(): boolean {
    return this.Status == Number(ePrevisStatus.Updating);
  }

  /**Indique que le statut est "plus de lien à une preco source" */
  public IsPrecoSrcDeleted(): boolean {
    return this.Status == Number(ePrevisStatus.No_Link_Preco)
  }

  /**Indique que le statut est "à supprimer" */
  public IsToDelete(): boolean {
    return this.Status == Number(ePrevisStatus.Deleting);
  }

  /**Changement du statut de la prévis pour son enregistrement (creation|maj) */
  public SetUpdatingStatus() {
    this.Status = Number(ePrevisStatus.Updating);
  }

  /**Changement du statut de la prévis pour sa suppression en db */
  public SetDeletingStatus() {
    this.Status = Number(ePrevisStatus.Deleting);
  }

  /**
   * Indique si elle a un status d'enregistrement soit celui passé soit cree modifie ou supprimé
   * @param _ieInterPrevisSavingStatus si présent contrôle légalité de ce status
   * @returns 
   */
  public IsChangeSaved(_ieInterPrevisSavingStatus: eInterPrevisSavingStatus | null) {
    return this.SavingStatus !== null && ((_ieInterPrevisSavingStatus !== null && this.SavingStatus == Number(_ieInterPrevisSavingStatus)) || (_ieInterPrevisSavingStatus == null && (this.SavingStatus == Number(eInterPrevisSavingStatus.Created) || this.SavingStatus == Number(eInterPrevisSavingStatus.Updated) || this.SavingStatus == Number(eInterPrevisSavingStatus.Deleted))));
  }


}

/**les statuts pour la previs à son chargement*/
export enum ePrevisStatus {
  //initialisé depuis une preco tech non enregistré en db
  Initial = 0,
  //inter previs existante en db
  Created = 1,
  //enregistrement -maj à réaliser en db
  Updating = 2,
  //Suppression de la prévis parcelle à réaliser en db
  Deleting = 3,

  //la préconisation source de cette prévis n'est plus présente à la parcelle
  No_Link_Preco = 4,

  //la préconisation source ne dispose plus de ce meme produit ou date
  Diff_Props_To_Preco = 5,
}

/// <summary>
/// Statut de l'enregistrement de l'intervention prévisionelle en db
/// réponse sur enregistrement
/// </summary>
export enum eInterPrevisSavingStatus {
  //aucune action en db
  NoAction = 0,
  //intervention à été crée en db
  Created = 1,
  //intervention existente en db, donc maj
  Updated = 2,
  //intervention existente en db, supprimée de la db
  Deleted = 3,
  //intervention selon idsource existante donc surchargée en db
  OverWrited = 4,

  Failed_Tech_OverWrited_no_Sector = 5
}

/**les types de préco tech pour les prévis */
export enum eTechInterType {
  Unknow = 0,
  //pas géré
  Intervention = 1,
  //pas géré pour le moment
  InterModePreco = 2,
  Preconisation = 3,
  PilotSp = 4,
  //pas géré pour le moment
  Modulation = 5,
  //intervention farmer
  FarmerInterPrevis = 9
}
