
import { Legend } from '..';
export class LegendGroup {
    key: string;
    items: LegendItem[];
    selected:boolean;
    //position de la légende, ceux sans position sont mis en 1e place
    position:number;

    constructor(_iKey: string, _iItems: any,  _iPosition:number=0, _iIsSelected:boolean = false) {
        this.key = _iKey;
        this.items = _iItems;
        this.position = _iPosition;
        this.selected = _iIsSelected
    }
}

export class LegendItem {
    name: string;
    style: Legend;
    legendName: string;
    legendCode: string=null;
    //Ordonne sur cette propriete
    sortOn:string=null;
    unit: string;
    hasSubMenu:boolean;
    items:LegendItem[];
    //propriete externe pour gestion autre propriete
    externeProp:LegendExtProperty=null;
     //Style de donnée de base
    Basicstyle: Legend=null;
    id?:number;
    //indique que la preconisation tech peut être dupliquée en farmer previs (gestion farmer previs)
    canBeFarmerDuplicated?: boolean = null;
    //indique que la previsionnelle farmer peut être modifiée (gestion farmer previs)
    canBeFarmerUpdated?: boolean = null;
    //propriété optionnelle
    options?:string=null;

    //identifiant unique formaté (utile sur la gestion farmer previs)
    uniqueFormatedId?:string = null;
}

export class LegendProperties {
    legendGroupKey: string;
    legendItemKey: string;
    legendProperies: LegendProperty[];
}

export class LegendProperty {
    propertyName: string;
    propertyValue: string;
    unit:string;
    isShownInTooltip: boolean;
    sfxValue:string;
}
/**reprend donnees pour reconstituer une legende externe */
export class LegendExtProperty {
    propertyName: string;
    //valeur maximal
    propertyValue: number;
      //valeur minimale
    propertyMinValue: number;
    unit:string;
    /**
     *
     * @param _iPropName
     * @param _iPropMaxValue Maximum de la propriete ( pour calcul de la legende rang max value)
     * @param _iPropUnit
     * @param _iPropMinValue minimal de la propriete ( pour calcul de la legende rang min value)
     */
    constructor( _iPropName:string,_iPropMaxValue:number,_iPropUnit:string, _iPropMinValue:number=null) {
        this.propertyName = _iPropName;
        this.propertyValue = _iPropMaxValue;
        this.unit = _iPropUnit;
        this.propertyMinValue = _iPropMinValue;
    }
}
