<div  [ngClass]="{showGroup: showPopupGroup}" id="mapside" [class]="MapSideCss">
    <span (click)="showPopupGroup = !showPopupGroup" class="mobile-mapside icon-menu"></span>
    <section id="aside-legend">
        <ng-container *ngFor="let legendItem of LegendAttrs; let legendIndex = index">
            <div class="mapside-group selected_{{legendItem.selected}}">
                <h4 (click)="groupSelected($event, legendIndex)">{{att.replace(legendItem.key) | NullToEmptyString}}</h4>
                <ul>
                    <li *ngFor="let item of legendItem.items; let attrIndex = index">
                        <div *ngIf="item.hasSubMenu">
                            <div>
                                {{item.legendName}}
                            </div>
                            <li *ngFor="let subitem of item.items; let subAttrIndex = index">
                                <input type="radio" class="k-radio" name="group" [checked]="isChecked(legendIndex, attrIndex, subAttrIndex)"
                                       id="map-radio-{{legendIndex}}-{{attrIndex}}-{{subAttrIndex}}"
                                       (click)="setLegendFocus(subitem,legendItem.key,legendIndex,attrIndex,subAttrIndex)" />
                                <label class="k-radio-label" for="map-radio-{{legendIndex}}-{{attrIndex}}-{{subAttrIndex}}">{{att.replace(subitem.legendName)}}</label>
                            </li>
                        </div>
                        <div *ngIf="!item.hasSubMenu">
                            <input type="radio" class="k-radio" name="group" [checked]="isChecked(legendIndex, attrIndex)" id="map-radio-{{legendIndex}}-{{attrIndex}}"
                                (click)="setLegendFocus(item,legendItem.key,legendIndex,attrIndex)" />
                            <label class="k-radio-label" for="map-radio-{{legendIndex}}-{{attrIndex}}">{{att.replace(item.legendName)}}</label>
                            <i *ngIf="item?.canBeFarmerDuplicated == true" class="icon-duplicate duplicate-button" (click)="onButtonOpenPrecoDuplicateDialog(item)" title="{{DuplicationFarmerPrecoBtnTitle}}"></i>
                            <i *ngIf="item?.canBeFarmerUpdated == true" class="icon-pencil duplicate-button" (click)="onButtonOpenFarmerPrevisUpdateDialog(item)" title="{{ButtonFarmerPrevisUpdateTitle}}"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </ng-container>
        <div class="validate-list">
            <input type="button" value="Valider" (click)="showPopupGroup = !showPopupGroup">
        </div>
    </section>
    <div class="legendColor" *ngIf="LegendClassAttrs && LegendClassAttrs.length">
        <kendo-switch id="switchMashVol" [(ngModel)]="blModeMashVolume" [hidden]="!WithModeMashVol" class="k-switch" (valueChange)="SwithModeMashVolumeClick($event)" [onLabel]="'Bouillie'"
        [offLabel]="'Produit'" class="btnswitch"></kendo-switch>
        <span class="legend-title">{{LegendTitle}}</span>
        <section *ngFor="let legendClass of LegendClassAttrs; let legendIndex = index" id="aside-legend-color">
            <div class="legend-item-color">
                <div class="color-legend" [ngStyle]="{'background-color' :legendClass.Color}"></div>
                <span *ngIf="legendClass.MinValue === legendClass.MaxValue">{{legendClass.MaxValue}}</span>
                <span *ngIf="legendClass.MinValue !== legendClass.MaxValue">{{legendClass.MinValue}} - {{legendClass.MaxValue}}</span>
            </div>
        </section>
    </div>
</div>
