
import {  HandleService } from "../../_services";
import { ChangesFarmerPlotPrevis, ePrevisChanges, grdFarmPlotPreviDatas, gridFarmerPrevisDatas,farmerPrevisOrderPreco, farmerPrevisOrderSector,InterPrevi, Fertilizer,CultivationPlot, eTechInterType} from "../index";

/**class des orders farmer Previs présent en db ou initié  */
export class farmerPrevisOrdersPrecosDatas {

    //#region properties
    //code du type de la preconisation tech (eTechInterType : Preconisation = 3,PilotSp = 4(non géré là :Modulation = 5,InterModePreco = 2,Intervention = 1)
    SrcInterType: number;
    //le produit de la préco tech
    SrcProduct: Fertilizer;
    //date de la préco tech
    SrcDate: string;
    //les parcelles concernées par cette preco
    //avec les farmer previs constitués ou recupérés
    Orders: farmerPrevisOrderPreco[];
     /**campagne de l'order */
    Campaign: number;

    //date minim authorisé pour les prévis selon campagne de l'order
    private _sMinDate: string = null;
    //date maxi authorisé pour les prévis selon campagne de l'order
    private _sMaxDate: string = null;

    //date minim authorisé pour les prévis selon campagne de l'order
    private _minDate: Date = null;
    //date maxi authorisé pour les prévis selon campagne de l'order
    private _maxDate: Date = null;

    /**date minim authorisée pour les prévis selon campagne de l'order */
    public get MinimalRangDate(): Date {
        return this._minDate
    }

    /**date maxi authorisée pour les prévis selon campagne de l'order */
    public get MaximalRangDate(): Date {
        return this._maxDate
    }

    /**string date maxi authorisée pour les prévis selon campagne de l'order sans gmt */
    public get MinimalConfigDate(): string {
        return this._sMinDate ?? ""
    }
    /**string date maxi authorisée pour les prévis selon campagne de l'order sans gmt */
    public get MaximalConfigDate(): string {
        return this._sMaxDate ?? ""
    }
    //#endregion

    /**
     * Initialisation
     * @param _iFarmPrevis données prévis
     * @param _iblBackSrc true chargement depuis le back , false on dispose deja de l'objet on le clone
     */
    constructor(_iFarmPrevis: any, _iblBackSrc: boolean) {
        this.Orders = [];
        this.Campaign = 0;
        if (_iFarmPrevis) {
            this.Campaign = _iblBackSrc ? 0 : (_iFarmPrevis.Campaign ?? 0);
            this.SrcDate = _iFarmPrevis.SrcDate;
            this.SrcInterType = _iFarmPrevis.SrcInterType;
            this.SrcProduct = _iFarmPrevis.SrcProduct;
            _iFarmPrevis.Orders.forEach(fOrd => {
                this.Orders.push(new farmerPrevisOrderPreco(fOrd));
                if (this.Campaign == 0) {
                    //récupère du 1e order , ils sont tous à la mm campagne
                    this.Campaign = fOrd.Campaign;
                }
            })
        }
        if (_iblBackSrc || (this._maxDate == null || this._minDate == null)) {
            this.initCampaignRangDate();
        }
    }

    /**
     * Initialise les dates min et max authorisées
     * selon la camapgne
     */
    private initCampaignRangDate() {
        if (this.Campaign !== 0) {
            this._sMaxDate = "31/12/" + (this.Campaign);
            this._sMinDate = "01/01/" + (this.Campaign - 1);
            this._minDate = HandleService.stringToDate(this._sMinDate);
            this._maxDate = HandleService.stringToDate(this._sMaxDate);
        }
    }

    /**
    * Indique si il dispose d'orders
    * @param _iExistPrevis true on contrôle aussi la présence de prévis parcellaire
    * @returns 
    */
    public Exists(_iExistPrevis: boolean) {
        return this.Orders?.length > 0 && (!_iExistPrevis || this.Orders.findIndex(a => a.Exists(true)) != -1);
    }


    /**
     * on filtre les prévis présentes selon les datas grid 
     * @param gridFarmerPrevisDatas 
     * @param _isSourePreco si ca provient d'une duplicaion de préco
     */
    public FilterByGridRows(gridFarmerPrevisDatas: grdFarmPlotPreviDatas[], _isSourePreco: boolean) {

        if (gridFarmerPrevisDatas?.length && this.Exists(true)) {
            let OrdersIds = HandleService.DistinctValues(gridFarmerPrevisDatas.map(a => a.OrderId));

            this.Orders = this.Orders.filter(e => OrdersIds.includes(e.OrderId));
            OrdersIds.forEach(fOId => {
                let ord = this.Orders.find(e => e.OrderId == fOId);
                let grdOrdPrevis = gridFarmerPrevisDatas.filter(e => e.OrderId == fOId);
                let grdSecid = HandleService.DistinctValues(grdOrdPrevis.map(r => r.SectorId));

                ord.Sectors.forEach((fSector: farmerPrevisOrderSector) => {
                    if (fSector.Plots?.length) {
                        if (grdSecid.includes(fSector.Id)) {
                            let grdSecPrevis = grdOrdPrevis.filter(e => e.SectorId == fSector.Id);
                            let grdSecPlotCultiIds = grdSecPrevis.map(a => a.IdCultivationPlot)

                            fSector.Plots.forEach((fPlot: CultivationPlot) => {

                                if (fPlot.ExistInterPrevis()) {
                                    if (grdSecPlotCultiIds.includes(fPlot.IdCultivationPlot)) {
                                        let grdPlotPrevis = grdSecPrevis.filter(e => e.IdCultivationPlot == fPlot.IdCultivationPlot);


                                        let grdPlotPrecosIds = grdPlotPrevis.map(z => z.UniquePrevisId)
                                        fPlot.InterPrevis.forEach(z => {
                                            if (grdPlotPrecosIds.includes(z.UniquePrevisId) == false) {
                                                let ident = z.Ident;
                                                fPlot.MicroPlots.forEach(
                                                    mc => { mc.InterPrevis = mc.InterPrevis ? mc.InterPrevis.filter(f => f.Ident !== ident) : [] }
                                                )
                                                //supprimé apres
                                                z.Fertilizer = null

                                            }
                                        })


                                        fPlot.InterPrevis = fPlot.InterPrevis.filter(e => e.Fertilizer !== null);
                                    } else {
                                        fPlot.InterPrevis = [];
                                    }
                                }

                            })

                            fSector.Plots = fSector.Plots.filter(a => a.InterPrevis?.length > 0);

                        } else {
                            //aucune de ses parcelles n est selectionnée on les supprime de l ilot
                            fSector.Plots = [];
                        }
                    }

                })

                ord.Sectors = ord.Sectors.filter(s => s.Exists(true))
            }
            )


            this.Orders = this.Orders.filter(r => r.Exists(true));

        } else {

            this.Orders = [];
        }

    }

    /**Retourne l'objet cloné (inter previs plot-micro compris) */
    public Clone(): farmerPrevisOrdersPrecosDatas {
        return new farmerPrevisOrdersPrecosDatas(this, false);
    }

    /**Retourne le type d'intervention source tech */
    public GetInterType(): eTechInterType {
        return this.SrcInterType as eTechInterType;
    }
    /**
     * Mapping vers le format grid datas
     * @param _iDisableCbxText texte à afficher en tooltip si la prévis est non sélectionnable
     * @param _isTechPrecoSrc si on est sur un chargement depuis une preco tech, false depuis inter previs(les totaux des previs sont calculés)
     * @param _iIsOnPrevisCreation true on est sur une creation de previs depuis une preco tech
     * @returns gridFarmerPrevisDatas
     */
    public Map(_iDisableCbxText: string = "", _isTechPrecoSrc: boolean): gridFarmerPrevisDatas {

        let oGrdFarmsPrecos: gridFarmerPrevisDatas = new gridFarmerPrevisDatas(null, false);

        if (this.Orders?.length) {
            let rows: grdFarmPlotPreviDatas[] = [];
            this.Orders.forEach((fOrd: farmerPrevisOrderPreco) => {

                if (fOrd.Sectors?.length) {

                    fOrd.Sectors.forEach((fSector: farmerPrevisOrderSector) => {
                        if (fSector.Plots?.length) {
                            fSector.Plots.forEach((fPlot: CultivationPlot) => {

                                let withPrecos = fPlot?.Preconisations?.length > 0;
                                if (fPlot.InterPrevis?.length) {
                                    fPlot.InterPrevis.forEach((fPlotPrevis: InterPrevi) => {
                                        fPlotPrevis = new InterPrevi(fPlotPrevis);

                                        let PrecoSource = null;
                                        if (withPrecos) {
                                            if (fPlotPrevis.SrcIdInter > 0 && fPlot?.Preconisations.findIndex(r => r.IdIntervention == fPlotPrevis.SrcIdInter) > -1) {
                                                PrecoSource = fPlot?.Preconisations.find(r => r.IdIntervention == fPlotPrevis.SrcIdInter);
                                            }
                                        }

                                        //si il est initié
                                        let oPrevisRow = new grdFarmPlotPreviDatas(fOrd, fSector, fPlot, fPlotPrevis, _isTechPrecoSrc, _iDisableCbxText, PrecoSource);
                                        rows.push(oPrevisRow);
                                    })
                                }
                            })
                        }
                    });
                }
            }
            )

            oGrdFarmsPrecos = new gridFarmerPrevisDatas(rows, _isTechPrecoSrc == false);
            oGrdFarmsPrecos.OrderByPlotNames();
        }

        return oGrdFarmsPrecos;
    }

    //#region Compute Changes

    /**Changement du statut pour la suppression des previs présente */
    public ChangePrevisToDeleteStatus() {
        this.ApplyChanges(null, ePrevisChanges.APP_MASS_CHANGE_BY_PURCENT)
    }

    /**Changement du statut pour la creation des previs présente */
    public ChangePrevisToCreateStatus() {
        this.ApplyChanges(null, ePrevisChanges.CHANGE_TO_CREATE_PREVIS)
    }

    /**
     * Application de la modification sur la|les prévis pour les orders présent
     * @param _iGrdPrevis la donnée du grid ou null pour les modif globale ou app mass
     * @param _ieTypeChange type de modif
     * @returns le nombre de parcelles avec une prévis modifiées
     */
    public ApplyChanges(_oGrdPrevis: ChangesFarmerPlotPrevis | null, _ieTypeChange: ePrevisChanges): number {
        let oChanges = 0;
        let isUniqueChange = ChangesFarmerPlotPrevis.IsUniquePrevisChange(_ieTypeChange);
        if (_ieTypeChange !== ePrevisChanges.NONE) {

            this.Orders.forEach(

                d => {
                    oChanges += d.ApplyChanges(_oGrdPrevis, _ieTypeChange)

                    if (isUniqueChange && oChanges > 0) {
                        //on a traité le seul
                        return false;
                    }

                }
            )

        }

        return oChanges;
    }

    /**
     * Retourne si il dispose d'un order Conseil (PK&|N)
     * les données sont chargées par produit et type de prestat
     * @returns 
     */
    public IncludeOrderAdvice(): boolean {
        return this.Orders.findIndex(r => r.IsAdvicePrestation()) > -1;
    }

    /**
   * Retourne si il dispose d'un order Pilot SP
   * les données sont chargées par produit et type de prestat
   * @returns 
   */
    public IncludeOrderPilotSP(): boolean {
        return this.Orders.findIndex(r => r.IsPilotSPPrestation()) > -1;
    }

    /**
     * Retourne si il dispose d'une previs en preco PK
     * @returns 
     */
    public IncludePrevisPK(): boolean {
        return this.Orders.findIndex(r => r.Sectors.findIndex(t => t.Plots.findIndex(p => p.InterPrevis.findIndex(p => p.IsPKPreconisation()) != -1) != -1) != -1) != -1;
    }

    /**
    * Retourne si il dispose d'une previs en preco N
    * @returns 
    */
    public IncludePrevisN(): boolean {
        return this.Orders.findIndex(r => r.Sectors.findIndex(t => t.Plots.findIndex(p => p.InterPrevis.findIndex(p => p.IsNPreconisation()) != -1) != -1) != -1) != -1;
    }

    /**
     * Indique si on a chargé plus d'une epxloitation 
     * @returns bool
     */
    public IsMultiFarms(): boolean {
        if (this?.Orders.length > 1) {
            let distFarmIds = HandleService.DistinctValues(this.Orders.map(r => r.FarmId));
            return distFarmIds?.length > 1;
        } else {
            return false;
        }
    }

    /**
     * Retourne l'identifiant de la 1e commande concernée par le produit
     * @returns number|null
     */
    public GetFirstFarmId():number|null{
        if (this.Exists(false)){
            return this.Orders[0].FarmId
        }
        return null;
    }

    //#endregion

    /**
     * Retourne si il dispose d'une previsionnelle modifiée avec le statut maj à enregistrer (creation ou modification)
     * @returns bool
     */
    public IncludePrevisStatusToUpdate(): boolean {
        return this.Orders.findIndex(r => r.Sectors?.findIndex(s => s.Plots.findIndex(a => a.InterPrevis.findIndex(i => i.IsToUpdate()) != -1) != -1) != -1) != -1;
    }

    /**
     * Retourne si il dispose d'une previsionnelle à supprimer
     * @returns 
     */
    public IncludePrevisStatusToDelete(): boolean {
        return this.Orders.findIndex(r => r.Sectors?.findIndex(s => s.Plots.findIndex(a => a.InterPrevis.findIndex(i => i.IsToDelete()) != -1) != -1) != -1) != -1;
    }

      /**
     * retourne pour les ilots présente la liste qui ont leur dose micros toutes à 0
     * @returns 
     */
      public ExistAllDosesMicrosEmpty():string[]|null{

        let oEmptyOrdPrevis = [];
        if(this.Exists(true)){
            this.Orders.forEach(fOrd=> {
                let plotPrevisNoDoses = fOrd.ExistAllDosesMicrosEmpty();
                if(plotPrevisNoDoses?.length > 0){
                    oEmptyOrdPrevis.push(plotPrevisNoDoses)
                }
            }    
            )
        }
        return oEmptyOrdPrevis?.length > 0 ? oEmptyOrdPrevis : null;
     }

}

  

