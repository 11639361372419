<kendo-window *ngIf="opened" class="duplicateModale" (close)="close()"

  >
  <kendo-window-titlebar>
    <i class="icon-farmer"></i>
    <span class="k-window-title">{{ title }}</span>
    <button class="k-button k-bare k-button-icon" kendoWindowMaximizeAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowRestoreAction></button>
    <button class="k-button k-bare k-button-icon" kendoWindowCloseAction></button>
  </kendo-window-titlebar>
  <div class="container">
    <div class="datas-container">
      <label class="lblTopbanner">{{toBannerHelperText}}</label>

      <kendo-grid [data]="GridFarmsPrecos.Previs" height="80%" width="95%" *ngIf="GridFarmsPrecos !== null"
        scrollable="false" [resizable]="true" class="gridDataCss">
        <ng-template kendoGridNoRecordsTemplate>
          {{EmptyRecordText}}
        </ng-template>

        <kendo-grid-column field="selected" width="50">

          <!-- Case à cocher de sélection générale -->
          <ng-template kendoGridHeaderTemplate>
            <input type="checkbox" class="smagGrdCBxCss" (click)="onChangeCheckAll()" [class]="!existSelectable ? ' gblCbxDisable ' : ''"
              [(ngModel)]="grdCheckAllChecked" />
          </ng-template>

          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" class="smagGrdCBxCss" (click)="onFarmPlotPreviSelection(dataItem)"
              [title]="dataItem.cbxTooltip ?? ''" [class]="!dataItem.enabled ? 'grdCbxDisable' : ''"
              [disabled]="!dataItem.enabled" [checked]="dataItem.selected" />
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="FarmName" title="Exploitation" *ngIf="IsMultiFarm"></kendo-grid-column>
        <kendo-grid-column field="SectorPac" title="îlot" [width]="50"></kendo-grid-column>
        <kendo-grid-column field="PlotName" title="Parcelle" [width]="350">

          <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-column="column" >
            <label style="width: 80%;">{{dataItem['PlotName']}}</label>
            <i *ngIf="dataItem['enabled'] == false" class="icon-farmer grd-icon-farmer" title="{{IconPrevisFarmerTltp}}" ></i>
          </ng-template>

        </kendo-grid-column>

        <kendo-grid-column field="MicrosAreasWithUnit" title="Surface" [width]="100"></kendo-grid-column>
        <kendo-grid-column field="CultivationName" title="Culture"></kendo-grid-column>
       
       
      </kendo-grid>
      <div *ngIf="IsLoadingInProgress">
        {{LoadingPrecoInProgressTxt}}
      </div>
    </div>
    <div class="dvBottomSelection">
      <label id="lblSelectionCount">{{SelectionCounter}}/{{TotalGridRows}}</label>
    </div>
    <div class="dvBottomButtons">
      <div class="dvRightButtons">
        <button class="k-button btn bottomButton" (click)="onClose()">Annuler</button>
        <button class="k-button k-primary btn btn-primary bottomButton" [disabled]="SelectionCounter==0"
          (click)="onNextClick()">Suivant</button>

      </div>
    </div>
  </div>
</kendo-window>