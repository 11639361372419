import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages.route';
import { DashboardModule } from './dashboard/dashboard.module';
import { ComponentsModule } from './components/components.module';
import { RouterModule } from '@angular/router';

import { NbAuthModule } from '../framework/auth/auth.module';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';

@NgModule({
  imports: [
    CommonModule,
    PagesRoutingModule,
    DashboardModule,
    ComponentsModule,
    RouterModule,
    NbAuthModule,
    DatePickerModule
  ],
  declarations: [PagesComponent]
})
export class PagesModule { }
