import { Injectable } from '@angular/core';
import { Fertilizer, Intervention, RefMineralFertilizers, eTechInterType, farmerPrevisOrdersPrecosDatas } from '../_models';
import { environment } from '../../environments/environment';
import { HttpRequestService } from './http-request.service';
import { isNullOrEmptyString } from '../_helpers';
import { HandleService } from './handle.service';
import { map, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

//Class de gestion des farmer inter previsionnelles (duplique de preco)
//gestion back
export class FarmerPrevisService {

  //#region Configs
  private baseUrl = environment.BeApiOperational.url;
  //api ep retournant pour une preconisation (code produit&date) les farmer previs correspondant
  private GET_FARMER_PREVIS_ACTION = environment.BeApiOperational.getOrdersPrecoFarmerPrevis;

  //api ep pour l'enregistrerment de creation ou modification ou suppression de previsionnelle
  private SAVE_FARMER_PREVIS_ACTION = environment.BeApiOperational.saveOrdersPrecoFarmerPrevis;

  //api ep retournant la liste des fertilisants mineraux
  private GET_REF_MINERALS_FERTIS = environment.BeApiOperational.getRefMinFertilizer;
  /**Suffix ajouté aux legendes des interventions previsionnelles farmer de type Pilot SP */
  static SFX_LEGEND_PRECO_SP = " [SP]";
  //code identifiant une preco|previs sur conseil PSP
  static OPTION_CODE_LEGEND_PRECO_SP = "PSP";
  //code identifiant une preco|previs sur conseil PACK
  static OPTION_CODE_LEGEND_PRECO_PACK = "PACK";
  //code identifiant une preco|previs sur conseil PK
  static OPTION_CODE_LEGEND_PRECO_PK = "PK";
  //code identifiant une preco|previs sur conseil N
  static OPTION_CODE_LEGEND_PRECO_N = "N";
  //#endregion

  //#region Properties
  //comporte la liste des codes de teneurs que on gère pour les prévis
  private _fPrevisCompoKeysManaged:string[] = [];

  //si on est sur la dernière campagne selectionnable
  private _isLastCmp: boolean = false;
  static _separatodFormatId: string = " _ ";

  /**le % par défaut géré pour les calcul dosage de previs */
  static CONFIG_STEP_PRCT_DOSAGE_DEFAUT = 5;

  private _userConfigStepPurcent: number;

  /**Retourne le Step des pourcents soit config soit user */
  public get UserConfigStepPurcent(): number {
    return this._userConfigStepPurcent ? this._userConfigStepPurcent : FarmerPrevisService.CONFIG_STEP_PRCT_DOSAGE_DEFAUT;
  }

  /**Attribution du step des pourcents qui sera contrôlé et arrondit*/
  public set UserConfigStepPurcent(value: number) {
    if (value > -100 && value < 100 && value !== 0) {
      this._userConfigStepPurcent = Math.round(value)
    } else {
    }
  }


  constructor(private httpRequestService: HttpRequestService) {
    this._userConfigStepPurcent = FarmerPrevisService.CONFIG_STEP_PRCT_DOSAGE_DEFAUT;
    this._fPrevisCompoKeysManaged = RefMineralFertilizers.GetKeysCompositionsManaged();
  }

  /**
   * Référentiel des produits fertis mineraux
   */
  private _RefMineralFertilizers: RefMineralFertilizers = null;

  /**Retourne le référentiel fertilisants chargé */
  public get RefFertilizer(): RefMineralFertilizers | null {
    return this._RefMineralFertilizers
  }

  /**Indique si il dispose de referentiel fertilisant chargé */
  public get IncludeReferential(): boolean {
    return this._RefMineralFertilizers?.Exists == true;
  }

  /** on attribue que on est bien sur la dernière campagne (pour la gestion duplication de preco farmer) */
  public set SetIsLastCampaign(_iIsOnLastCampaign: boolean) {
    this._isLastCmp = _iIsOnLastCampaign;
  }

  /**Indique si au niveau du menu on est sur la dernière camapgne sélectionnée et pas en mode responsive (mobile)*/
  public get IsDuplicationAccess() {
    return this._isLastCmp == true && !HandleService.isMobile();
  }

  //#endregion
  //#region Get Farmer Previs
  /**
   * Retourne depuis l'API pour une preconisation et une liste d'order
   * les farmer Previs pour les orders concernés
   * @param PrecoFormatedId identifiant formaté à la preco
   * @param _iOrdersUid identifiantdes orders sélectionnés
   * @param _iIsTechPreco si ca vient d'une preconisation tech (donc on inclue les farmer previs initialisés)
   * @returns 
   */
  public GetFarmerPrevisDatas(_iPrecoFormatedId: string, _iOrdersUid: string[], _iIsTechPreco: boolean): Observable<any> {
    const payload = this.getFarmerPrecoPayload(_iPrecoFormatedId, _iOrdersUid, _iIsTechPreco);
    return this.httpRequestService.PostRequest(this.baseUrl + this.GET_FARMER_PREVIS_ACTION, payload)
  }

  /**
   * Parse l'id formaté pour en constituer les paramètrers de requête
   * @param _iPrecoFormatedId code de l'intervention
   * @param _iOrdersIds liste des identifiants orders
   * @param _iblWithInitials inclue initialisation depuis preco tech
   * @returns
   */
  private getFarmerPrecoPayload(_iPrecoFormatedId: string, _iOrdersIds: string[], _iblWithInitials: boolean = true): any | null {
    let parsedData = _iPrecoFormatedId.split(FarmerPrevisService._separatodFormatId);
    if (parsedData.length > 2) {
      return {
        "OrderIds": _iOrdersIds,
        "CodeProduct": parsedData[0]?.trim(),
        "InterDate": parsedData[1]?.trim(),
        "Type": parsedData[2]?.trim(),
        "IncludeInitials": _iblWithInitials
      };
    }
    return null;

  }
  //#endregion

  //#region Saving
 /**
  * Enregistrement des farm prévis avec un status updating ou deleting
  * et retourne le meme avec les status d'enregistrements
  * @param _iFarmerPrevis données à traiter
  * @returns farmerPrevisOrdersPrecosDatas
  */
  public SaveFarmerPrevisDatas(_iFarmerPrevis: farmerPrevisOrdersPrecosDatas): Observable<any> {
    return this.httpRequestService.PostRequest(this.baseUrl + this.SAVE_FARMER_PREVIS_ACTION, _iFarmerPrevis)
  }

  //#endregion

  //#region Referentials

  /**
   * Retourne le référentiel exploitant ou générale filtrés des produits avec teneurs
   * soit de la mémoire sinon le recharge
   * en filtrant les produits avec unités non gérées
   * que avec présence de teneurs actives
   * @param _iUniqueFarmId si présent retourne à cette exploitation
   * @returns RefMineralFertilizers
   */
  public GetFarmerReferentialFertilizer(_iUniqueFarmId?: number):Observable<RefMineralFertilizers> {
    if(this.IncludeReferential && this._RefMineralFertilizers.IsFarmerReferential(_iUniqueFarmId) == true){   
      return of(this._RefMineralFertilizers);
    }
    
    var subResReferential = new Subject<RefMineralFertilizers>();
    this.GetReferentialMinerals(_iUniqueFarmId).subscribe(
      (data:any)=>{
      
        if (data?.length) {
          //sur récupération on contrôle que l'on gère selon l'unité (des packs présent et non géré notamment)
          let minrReferential = new RefMineralFertilizers(_iUniqueFarmId, data, true);
          //sur récupération on retire les produit avec unité non géré cas de changement de fertilisant > coeff unit
          minrReferential.ClearWithUnitNotManaged();
          this._RefMineralFertilizers =minrReferential;
          
          subResReferential.next(minrReferential);   
        }
      }
    );
    return subResReferential.asObservable();
  }

  /**
   * Retourne la liste des fertilisants mineraux du referentiel BEAPI
   * 
   * @param _iUniqueFarmId si indiqué retourne en plus les perso de l'exploitation
   * @returns 
   */
  private GetReferentialMinerals(_iUniqueFarmId?: number): Observable<any> {
    const payload = {
      'id_farm': _iUniqueFarmId
    }
    return this.httpRequestService.PostRequest(this.baseUrl + this.GET_REF_MINERALS_FERTIS, payload)
  }

  
  //#endregion
  //#region static functions

  /**
  * Formatage d'un code identifiant pour une preco (conseil ou pilotSp)
  * @param _iPlotPreco preco parcellaire (conseil ou pilot SP)
  * @param _iblIsPilotSP true:Pilot SP, false: Preco conseil
  * @returns string|null null si pas de code produit ou date
  */
  public static FormatTechPrecoId(_iPlotPreco: Intervention, _iblIsPilotSP: boolean = false): string | null {
    let typePreco: eTechInterType = _iblIsPilotSP == true ? eTechInterType.PilotSp : eTechInterType.Preconisation;//correspond à l'enum back eTechInterType
    return FarmerPrevisService.FormatPlotPrecoId(_iPlotPreco, typePreco);
  }

  /**
 * Formatage d'un code identifiant pour farmer inter previs présente dans l'order
 * @param _iPlotfarmerPrevis farmer inter previs présente dans l'order
 * @returns string|null null si pas de code produit ou date
 */
  public static FormatFarmerPrevisId(_iPlotfarmerPrevis: Intervention): string | null {
    return FarmerPrevisService.FormatPlotPrecoId(_iPlotfarmerPrevis, eTechInterType.FarmerInterPrevis);
  }

  /**
 * Formatage d'un code identifiant pour une preco (conseil ou pilotSp)
 * @param _iPlotPreco preco parcellaire
 * @param _filterPlotPrecoType filter sur ce type
 * @returns string|null null si pas de code produit ou date
 */
  private static FormatPlotPrecoId(_iPlotPreco: Intervention, _ifilterPlotPrecoType: eTechInterType): string | null {
    let sep = FarmerPrevisService._separatodFormatId;
    let typeCode = (Number(_ifilterPlotPrecoType)).toString();
    let productCode = _iPlotPreco?.Fertilizer.code ?? _iPlotPreco.CodeProduit
    let formatedDate = "";
    if (_iPlotPreco.BeginDate && new Date(_iPlotPreco.BeginDate).getFullYear() > 1) {
      formatedDate = HandleService.dateToString(new Date(_iPlotPreco.BeginDate)).toString();
    }
    if (isNullOrEmptyString(productCode) || isNullOrEmptyString(formatedDate)) {
      return null;
    }
    return `${productCode} ${sep} ${formatedDate}  ${sep} ${typeCode}`
  }

  public static IsLegendUniqIdOnType(_iLegendUniqId: string, _ifilterPlotPrecoType: eTechInterType): boolean {
    let sep = FarmerPrevisService._separatodFormatId;
    let typeCode = (Number(_ifilterPlotPrecoType)).toString()
    return _iLegendUniqId !== null && _iLegendUniqId.endsWith(`${sep} ${typeCode}`)
  }

  /**
  * Indique selon la configuration si on gère la duplication des preconisations en farmer inter
  * @returns 
  */
  public static ConfigEnableDuplication(): boolean {

    return environment?.enableDuplication == true;
  }

  /**
   * Contrôle que le produit fertilisant dispose d'une composition nécessaire pour les previs
   * @param _iPrecoFertilizer produit fertilisant
   * @returns 
   */
  public  FertilizerWithComposition(_iPrecoFertilizer:Fertilizer):boolean{
   
    let oExist = false;
    if(_iPrecoFertilizer) {
      this._fPrevisCompoKeysManaged.forEach(fCompo=>{
        if(oExist == false &&_iPrecoFertilizer[fCompo] && _iPrecoFertilizer[fCompo] >0){
          oExist = true;
          return;
        }
      })
    }
    return oExist;
  }

  //#endregion
}

