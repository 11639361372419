import { Injectable, EventEmitter } from '@angular/core';
import { LegendItem } from '../_models';
import { _isNumberValue } from '@angular/cdk/coercion';
import { farmerPrevisSaveChangesResume,farmerPrevisOrdersPrecosDatas } from '../_models';

@Injectable({
  providedIn: 'root'
})
/**Service dédié à la propagation d'events */
export class EventEmitterService {

  // #region gestion farmer Previs
  //event changement sur ouverture de fenêtre de duplication de preconisation technicien
  invokeDuplicateTechPrecoDialogChange = new EventEmitter<EventActionModalLegend>();

  //event changement sur ouverture de fenêtre de modification d'intervention previsionnelle farmer
  invokeFarmerPrevisUpdtDialogChange = new EventEmitter<EventActionModalLegend>();

  //event changement sur ouverture de fenêtre de modification depuis la preco vers previs
  invokeCreationFarmerPrevisUpdtDialogChange = new EventEmitter<EventActionModalLegend>();

  //provoque le rechargement des orders
  invokeFarmerPrevisReloadingOrder = new EventEmitter<farmerPrevisSaveChangesResume>();
  constructor() { }

  /**émet event changement sur ouverture de fenêtre de duplication de preconisation technicien */
  emitDuplicateTechPrecoDialogChange(data: EventActionModalLegend) {
    this.emitCloseUpdateFarmerPrevisDialog();
    this.invokeDuplicateTechPrecoDialogChange.emit(data);
  }

  /**émet event changement sur ouverture de fenêtre de modification depuis un new de la fenetrer de duplication */
  emitCreationFarmPrevisDialogChange(data: EventActionModalLegend) {
    this.invokeFarmerPrevisUpdtDialogChange.emit(data);
  }

  /**émet event changement sur ouverture de fenêtre de modification de farmer prévis */
  emitUpdateFarmerPrevisDialogChange(data: EventActionModalLegend) {
    this.emitCloseDuplicateTechPrecoDialog();
    this.invokeFarmerPrevisUpdtDialogChange.emit(data);
  }

  /*émet l'event de fermeture de la modale de modification de previs*/
  emitCloseUpdateFarmerPrevisDialog() {
    this.invokeFarmerPrevisUpdtDialogChange.emit(new EventActionModalLegend(false, null, true));
  }

  /*émet l'event de fermeture de la modale de duplication de préco tech*/
  emitCloseDuplicateTechPrecoDialog() {
    this.invokeDuplicateTechPrecoDialogChange.emit(new EventActionModalLegend(false, null, false));
  }

  /**émet event rechargement des données orders suite modif previs dv */
  emitReloadingFarmerPrevis(resumes: farmerPrevisSaveChangesResume) {
    this.invokeFarmerPrevisReloadingOrder.emit(resumes);
  }

  //#endregion
}

/**Class de souscription une event de modale sur une legende selectionnée */
export class EventActionModalLegend {
  /**id unique de souscription à l'event*/
  public SubUniqueID: number;
  //indique si on visualise la modale
  public IsOpen: boolean = false;
  //la légende sélectionnée
  public LegendSelected: LegendItem = null;
  //si on est depuis un chargement de prévis sélectionnée
  public IsSrcPrevis: boolean = false;
  //la données farmer previs initialisée depuis une legende preco
  private _farmerInitialPrecoResult: farmerPrevisOrdersPrecosDatas = null;

  public GetFarmerInitialPreco(): farmerPrevisOrdersPrecosDatas {
    return this._farmerInitialPrecoResult;
  }
  /**
   * initialise un event sur une modale de legende (farmer previs)
   * @param _iShowModale Si on visualise la modale
   * @param _iLegend la legende selectionné
   * @param _iIsSrcPrevis si on est depuis une légende de prévis(donc modification de prévis)
   * @param _ifarmerInitialPrecoResult depuis les précos vers les prévis les données sélectionnées initialisées
   */
  constructor(_iShowModale: boolean, _iLegend: LegendItem, _iIsSrcPrevis: boolean = false, _ifarmerInitialPrecoResult: farmerPrevisOrdersPrecosDatas = null) {
    this.SubUniqueID = Date.now() + Math.random();
    this.IsOpen = _iShowModale;
    this.LegendSelected = _iLegend;
    this.IsSrcPrevis = _iIsSrcPrevis;
    this._farmerInitialPrecoResult = _ifarmerInitialPrecoResult;
  }
}
