import { HandleService } from "../../_services";
import { Fertilizer } from "../beapi/fertilizer";

/**class de produits fertilisant à l'exploitation ou global*/
export class RefMineralFertilizers {
    //les unités configurées que l'on gère
    _confUnitsManaged: string[] = ["kg", "l", "m3", "t"];
    //id farm 
    farmId: number | null = null;
    //les fertilisants filtrés selon les unités géré avec teneurs
    minerals: Array<RefMineralFertilizer>;

    /**
     * Indique si on est sur un referentiel smag (non spécifique à un exploitant)
     */
    get isSmagRef() {
        return this.farmId == null || this.farmId == 0;
    }

    /**
     * si dispsoe de fertilisants
     */
    get Exists() {
        return this.minerals?.length > 0;
    }

    /**
     * initialise depuis une liste de fertilisants recus
     * une liste exploitable filtrés sur les unités authorisées
     * @param _iFarmId 
     * @param _iRefMineralFertilizers l'ensmelbe du référentiel chargé
     */
    constructor(_iFarmId: number | null, _iRefMineralFertilizers: any, _iCheckunitAllowed: boolean = false) {
        this.farmId = _iFarmId;
        this.minerals = [];
        if (_iRefMineralFertilizers?.length) {
            _iRefMineralFertilizers.forEach((fFerti: any) => {
                let unitManaged = _iCheckunitAllowed == false ? true : (fFerti.unit ? this._confUnitsManaged.includes(fFerti.unit.trim().toLowerCase()) : false)
                this.minerals.push(new RefMineralFertilizer(fFerti, unitManaged));
            })
        }
    }

    /**
     * Indique si le reférentiel correspond de par son identifiant
     * @param _iFarmId 
     * @returns boolean
     */
    public IsFarmerReferential(_iFarmId:number|null):boolean{
          if(this.Exists)
            {
                if(_iFarmId == null || _iFarmId == 0){
                    return this.isSmagRef == true;
                } else {
                    return this.farmId == _iFarmId;
                }
            }
        return false;
    }

    /**
     * Ordonne par name les produits présent
     */
    public OrderByName() {
        if (this.Exists && this.minerals.length > 1) {
            this.minerals = this.minerals.sort(function (n1, n2) {
                return (n1.name < n2.name) ? -1 : (n1.name > n2.name) ? 1 : 0;
            })
        }
    }

    /**On retire de la liste les produits avec unités non gérées */
    public ClearWithUnitNotManaged() {
        if (this.Exists) {
            this.minerals = this.minerals.filter(t => t.isUnitExcluded == null);
        }
    }

    /**
     * Retourne le fertilisant avec ce code
     * @param _iCode code du produit
     * @returns 
     */
    public GetByFertilizerCode(_iCode: string): RefMineralFertilizer | null {
        return this.minerals.find(r => r.code == _iCode) ?? null;
    }
    /**
     * Retourne les ferti pour Pilot SP 
     * Exige Compo: N
     * @returns 
     */
    public GetFertiOnPilotSP(): RefMineralFertilizers {
        return new RefMineralFertilizers(null, r => r.IncludeCompo('N'));
    }

    /**
    * Retourne les ferti pour N
    * Exige Compo: N|So3
    * @returns 
    */
    public GetFilterFertiOnNS(): RefMineralFertilizers {
        let compoNKeys = RefMineralFertilizers.GetKeysCompositionsN();
        return new RefMineralFertilizers(null, this.minerals.filter(r => r.IncludeOneCompo(compoNKeys)));
    }

    /**
    * Retourne les ferti pour PK
    * Exige Compo: P2O5-K2O-Ca-MgO
    * @returns 
    */
    public GetFilterFertiOnPK(): RefMineralFertilizers {
        let compoPKKeys = RefMineralFertilizers.GetKeysCompositionsPK();
        return new RefMineralFertilizers(null, this.minerals.filter(r => r.IncludeOneCompo(compoPKKeys)));
    }

    /**
* Retourne les ferti pour N & PK
* Exige Compo: N|So3
* @returns 
*/
    public GetFilterFertiOnNSPK(): RefMineralFertilizers {
        let compoNKeys = RefMineralFertilizers.GetKeysCompositionsN();
        let compoPKKeys = RefMineralFertilizers.GetKeysCompositionsPK();
        return new RefMineralFertilizers(null, this.minerals.filter(r => (r.IncludeOneCompo(compoNKeys)) && (r.IncludeOneCompo(compoPKKeys))));
    }

    /**Filter la liste qu'avec ceux qui comporte une de ces compos */
    public GetFilterWithCompositions(_iCompoKey: Array<string>) {
        return new RefMineralFertilizers(null, this.minerals.filter(r => r.IncludeOneCompo(_iCompoKey)));
    }

    /**Retourne les clés de composition spécifique aux precos N */
    public static GetKeysCompositionsN(): string[] {
        return ['N', 'SO3']
    }

    /**Retourne les clés de composition spécifique aux precos PK */
    public static GetKeysCompositionsPK(): string[] {
        return ['P2O5', 'K2O', 'CaO', 'MgO']
    }

    /**Retourne les codes des compositions des fertilisants que l'on gère farmer previs */
    public static GetKeysCompositionsManaged():string[]{
        return [... RefMineralFertilizers.GetKeysCompositionsN(), ...RefMineralFertilizers.GetKeysCompositionsPK()]
    }

}

/**Class produit fertilisant référentiel */
export class RefMineralFertilizer {
    // #region Properties
    //code du produit
    code: string;
    //nom produit
    name: string;
    //unité du produit
    unit: string;
    //l'unité est non gérée (si null unité gérée)
    isUnitExcluded?: boolean;
    //Actif compositions
    ACompos: Record<string, number>
    //#endregion
    /**
     * init
     * @param _iRefMineralFertilizer 
     * @param _isUnitManaged si l'unité est gérée si null reprend la valeur passée
     */
    constructor(_iRefMineralFertilizer: RefMineralFertilizer, _isUnitManaged: boolean | null) {
        this.code = _iRefMineralFertilizer.code;
        this.name = _iRefMineralFertilizer.name;
        this.ACompos = _iRefMineralFertilizer.ACompos;
        this.unit = _iRefMineralFertilizer.unit;
        if (_isUnitManaged == null) {
            this.isUnitExcluded = _iRefMineralFertilizer.isUnitExcluded
        } else {
            this.isUnitExcluded = _isUnitManaged == false ? true : null;
        }

    }

    /**
     * Retourne si il include une teneur > 0 pour la compo
     * @param _iCompoKey la compo
     * @returns 
     */
    public IncludeCompo(_iCompoKey): boolean {
        return this.ACompos[_iCompoKey] !== undefined;
    }

    /**Retourne la teneur de compo */
    public GetComposition(_iCompoKey): number | null {
        return this.ACompos[_iCompoKey];
    }

    /**
     * Retourne si il dispose d'une de ses compositions
     * @param _iCompoKeys ensemble des composition clés à rechercher
     * @returns 
     */
    public IncludeOneCompo(_iCompoKeys: Array<string>): boolean {

        return Object.keys(this.ACompos).findIndex(z => _iCompoKeys.includes(z)) > -1;
    }

    public Clone(): RefMineralFertilizer {
        return new RefMineralFertilizer(this, null);
    }

    /**map sur un fertilizer de preco */
    public MapFertilizer(_iDose: number | null): Fertilizer {
        let oFertilizer = new Fertilizer(null);
        oFertilizer.CaO = this.GetComposition('CaO') ?? 0;
        oFertilizer.K2O = this.GetComposition('K2O') ?? 0;
        oFertilizer.MgO = this.GetComposition('MgO') ?? 0;
        oFertilizer.N = this.GetComposition('N') ?? 0;
        oFertilizer.P2O5 = this.GetComposition('P2O5') ?? 0;
        oFertilizer.SO3 = this.GetComposition('SO3') ?? 0;
        oFertilizer.Na2O = this.GetComposition('Na2O') ?? 0;
        oFertilizer.code = this.code;
        oFertilizer.Unit = this.unit;
        oFertilizer.Name = this.name;
        oFertilizer.Dose = _iDose;
        return oFertilizer;
    }

    /**format la liste des teneurs actives sous forme de string */
    public FormatCompositionText(): string {
        let oCompos = [];
        Object.keys(this.ACompos).forEach((compoKey: string) => {
            oCompos.push(compoKey + " : " + HandleService.RoundDecimalsView(this.ACompos[compoKey],-1));
        })
        let unit="";
        if(oCompos.length && this.unit){
            switch(this.unit.toLocaleLowerCase()){
                case 'kg':
                case 't':
                    unit=" (kg/100 kg)";
                    break;
                case 'l':
                case 'm3':
                    unit=" (kg/100 l)";
                    break;
            }
        }
        return oCompos.join(' - ') + unit;
    }
}

